import {
  loadData as loadDataFromApi,
  loadTripEndpoints as loadTripEndpointsFromApi,
  getFilterValues as getFilterValuesFromApi,
  loadSummaryData as loadSummaryDataFromApi,
  getScaleValues as getScaleValuesFromApi,
  loadCrossTabulationData as loadCrossTabulationDataFromApi,
  getDashboard as getDashboardFromApi,
  getFilters as getFiltersFromApi,
  getMapLayers as getMapLayersFromApi,
  updateFilters as updateFiltersFromApi,
  createNewView as createNewViewFromApi,
  loadTotalTrips as loadTotalTripsFromApi,
  getStateCountiesFromDashboard,
  getStateAndCountyData,
  getGeoIdsDataIntersectedByShape,
} from './api';
import {
  loadTotalTripsForTruck as loadTotalTripsForTruckApi,
  loadSummaryDataForTruck as loadSummaryDataForTruckApi,
  loadDataForTruck as loadDataForTruckApi,
  loadCrossTabulationDataForTruck as loadCrossTabulationDataForTruckApi,
  getStateCountiesFromDashboardForTruck,
  getStateCountyDataForTruck,
} from './api-truck';
import { loadData as loadDataFromFiles } from './file';
import { useLoadingStore } from '../store/loaders';
import hat from 'hat';
import _ from 'lodash';
import { apiFiltersToAppFilters } from '../constants';
export { queryApi as loadBlockgroupShapesFromApi } from './blockgroups';

const { setLoadingState } = useLoadingStore.getState();

const loaderWrapper = async (fn, loadingSection) => {
  if (!loadingSection) return await fn();
  let value;
  const id = hat();
  setLoadingState(loadingSection, id);
  value = await fn();
  setLoadingState(loadingSection, id);
  return value;
};

export const loadTripEndpoints = async (
  dashboard,
  direction,
  geoid,
  filters,
  limit
) => {
  return await loadTripEndpointsFromApi(
    dashboard.table,
    dashboard.userId,
    dashboard.dashboardId,
    dashboard.clientName,
    direction,
    geoid,
    filters,
    limit
  );
};

const loadDataFn = async (dashboard, direction, filters) => {
  switch (dashboard.backend) {
    case 'database':
      return await loadDataFromApi(
        dashboard.table,
        dashboard.clientName,
        dashboard.userId,
        dashboard.dashboardId,
        dashboard.name,
        direction,
        filters,
        dashboard?.zoneSystems
      );
    case 'files': {
      if (dashboard.type.toLowerCase() === 'transit') {
        const transitDir = `transit/${direction}`;
        let nextFilters = { ...filters };
        delete nextFilters['origin_geo'];
        delete nextFilters['destination_geo'];

        nextFilters = Object.fromEntries(
          Object.entries(nextFilters).map(([k, v]) => [k, v[0]])
        );

        let data = await loadDataFromFiles(transitDir, nextFilters);

        return data;
      } else {
        let data = await loadDataFromFiles(direction, filters);
        const totalTrips = Object.values(data).reduce((acc, v) => acc + v, 0);
        data = Object.entries(data).reduce((acc, [k, v]) => {
          acc[k] = {
            daily_trips: v,
            daily_pmt: Math.random(5000),
            share_of_total_trips: (v / totalTrips) * 100,
          };
          return acc;
        }, {});
        return data;
      }
    }
    default:
      console.warn(`No way to load ${dashboard.backend}`);
      break;
  }
};

export const loadData = async (
  dashboard,
  direction,
  filters,
  loadingSection
) => {
  return await loaderWrapper(
    async () => await loadDataFn(dashboard, direction, filters),
    loadingSection
  );
};

export const getScaleValuesFn = async (dashboard, direction) => {
  switch (dashboard.backend) {
    case 'database':
      return await getScaleValuesFromApi(
        dashboard.table,
        dashboard.userId,
        dashboard.name,
        direction
      );
    case 'files': {
      const mockData = {
        daily_pmt: [
          8832.470208575207, 204561.65511277737, 315355.1230748777,
          463323.5199344911, 717331.4590504207,
        ],
        daily_trips: [
          326.8773539004578, 17148.631471664434, 24095.262555790872,
          32990.52440730419, 50753.68752103317,
        ],
      };
      return mockData;
    }
    default:
      console.warn(`No way to load ${dashboard.backend}`);
      break;
  }
};

export const getScaleValues = async (dashboard, direction, loadingSection) => {
  return await loaderWrapper(
    async () => await getScaleValuesFn(dashboard, direction),
    loadingSection
  );
};

const loadSummaryDataFn = async (
  direction,
  dashboard,
  filters,
  dataKey,
  options
) => {
  switch (dashboard.backend) {
    case 'database':
      return await loadSummaryDataFromApi(
        direction,
        dashboard.dashboardId,
        dashboard.table,
        dashboard.userId,
        dashboard.clientName,
        filters,
        dataKey,
        options
      );
    case 'files': {
      const mockData = {
        distance_mi: [
          { value: '50-100 mi', count: 10722097.402725788 },
          { value: '0.5-1 mi', count: 33357799.294862106 },
          { value: '5-7.5 mi', count: 40167373.66575357 },
          { value: '0-0.5 mi', count: 35072253.392355755 },
          { value: '2.5-5 mi', count: 65169746.2443873 },
          { value: '1-2.5 mi', count: 67165024.15484473 },
          { value: '25-50 mi', count: 22378321.889399037 },
          { value: '10-15 mi', count: 35190094.13412536 },
          { value: '7.5-10 mi', count: 26214206.600630984 },
          { value: '100+ mi', count: 10770829.132823044 },
          { value: '15-25 mi', count: 33209018.067574464 },
        ],
        equity: [
          { value: 'Non-equity', count: 276103960.35734445 },
          { value: 'Minority Only', count: 40073570.06954631 },
          { value: 'Low Income and Minority', count: 33535769.677389383 },
          { value: 'Low Income Only', count: 29703463.875205044 },
        ],
        mode: [
          { value: 'Motorized', count: 362300198.2380049 },
          { value: 'Walk', count: 15402386.323793065 },
          { value: 'Bike', count: 1714179.417683174 },
        ],
        period: [
          { value: '2022Q4', count: 97531840.23054439 },
          { value: '2019Q4', count: 109909393.29101676 },
          { value: '2021Q4', count: 91910934.34755382 },
          { value: '2020Q4', count: 80064596.11036834 },
        ],
        purpose: [
          { value: 'Other - Other', count: 83915832.82668817 },
          { value: 'Home - Other', count: 223737867.6434695 },
          { value: 'Home - Regular', count: 51400476.85310349 },
          { value: 'Regular - Other', count: 20362586.656215157 },
        ],
        time_of_day: [
          { value: 'PM Peak (3PM-7PM)', count: 116632133.09013352 },
          { value: 'AM Peak (6AM-10AM)', count: 57296781.40462272 },
          { value: 'Midday (10AM-3PM)', count: 123758857.12114757 },
          { value: 'Night (7PM-6AM)', count: 81728992.36357746 },
        ],
        traveler_type: [
          { value: 'Residents', count: 362847754.4569537 },
          { value: 'Non-residents', count: 16569009.522544561 },
        ],
        wday_type: [
          { value: 'Sunday', count: 109335215.45318845 },
          { value: 'Saturday', count: 128624052.43844576 },
          { value: 'Weekday', count: 141457496.0878451 },
        ],
      };
      return mockData;
    }
    default:
      console.warn(`No way to load ${dashboard.backend}`);
      break;
  }
};

export const loadSummaryData = async (
  direction,
  dashboard,
  filters,
  dataKey,
  loadingSection,
  options = {}
) => {
  return await loaderWrapper(
    async () =>
      await loadSummaryDataFn(direction, dashboard, filters, dataKey, options),
    loadingSection
  );
};

const getCrossTabulationFn = async (
  dataKey,
  dashboard,
  geoIds,
  dimensions,
  filters
) => {
  switch (dashboard.backend) {
    case 'database':
      return await loadCrossTabulationDataFromApi(
        dataKey,
        dashboard.table,
        dashboard.clientName,
        dashboard.userId,
        dashboard.name,
        geoIds,
        dimensions,
        filters
      );
    case 'files':
    default:
      console.warn(`No way to load ${dashboard.backend}`);
      break;
  }
};

export const getCrossTabulation = async (
  dataKey,
  dashboard,
  geoIds,
  dimensions,
  filters,
  loadingSection
) => {
  return await loaderWrapper(
    async () =>
      await getCrossTabulationFn(
        dataKey,
        dashboard,
        geoIds,
        dimensions,
        filters
      ),
    loadingSection
  );
};

// ------------------------------------------------------------

// Get dashboard
const getDashboardFn = async dashboardId => {
  const dashboard = await getDashboardFromApi(dashboardId);
  return dashboard;
};

export const getDashboard = async (dashboardId, loadingSection) => {
  return await loaderWrapper(
    async () => await getDashboardFn(dashboardId),
    loadingSection
  );
};

// Get State and Counties
export const getStateCountiesDashboard = async (dashboardId, accessToken) => {
  const dashboard = await getStateCountiesFromDashboard(
    dashboardId,
    accessToken
  );
  return dashboard;
};

export const getStateAndCountyDetailsForTruck = async (
  dashboard,
  accessToken
) => {
  const details = await getStateCountyDataForTruck(dashboard, accessToken);
  return details;
};

// Get filters
const getFiltersFn = async (viewId, accessToken, clientId) => {
  const filters = await getFiltersFromApi(viewId, accessToken, clientId);
  return filters;
};

export const getFilters = async (viewId, accessToken, clientId) => {
  return await loaderWrapper(
    async () => await getFiltersFn(viewId, accessToken, clientId),
    null
  );
};

const getMapLayersFn = async (dashboardId, accessToken, clientId) => {
  const mapLayers = await getMapLayersFromApi(
    dashboardId,
    accessToken,
    clientId
  );
  return mapLayers;
};

export const getMapLayers = async (dashboardId, accessToken, clientId) => {
  return await loaderWrapper(
    async () => await getMapLayersFn(dashboardId, accessToken, clientId),
    null
  );
};

// get state and county information

const getStateAndCountyDetailsFn = async (dashboardId, accessToken) => {
  const stateCountyData = await getStateAndCountyData(dashboardId, accessToken);
  return stateCountyData;
};

export const getStateAndCountyDetails = async (dashboardId, accessToken) => {
  const stateCountyData = await getStateAndCountyData(dashboardId, accessToken);
  return stateCountyData;
};

export const getGeoSelectionIds = async (
  shape,
  zoneSystemIds,
  selection,
  zoneType,
  geoId
) => {
  const geoIdsData = await getGeoIdsDataIntersectedByShape(
    shape,
    zoneSystemIds,
    selection,
    zoneType,
    geoId
  );
  return geoIdsData;
};

// Update filters
const updateFiltersFn = async (viewId, data, accessToken) => {
  const filters = await updateFiltersFromApi(viewId, data, accessToken);
  return filters;
};

export const updateFilters = async (
  viewId,
  data,
  accessToken,
  loadingSection
) => {
  return await loaderWrapper(
    async () => await updateFiltersFn(viewId, data, accessToken),
    loadingSection
  );
};

// Create new view
const createNewViewFn = async (data, dashboardDetails, accessToken) => {
  const filters = await createNewViewFromApi(
    data,
    dashboardDetails,
    accessToken
  );
  return filters;
};

export const createNewView = async (
  data,
  dashboardDetails,
  accessToken,
  loadingSection
) => {
  return await loaderWrapper(
    async () => await createNewViewFn(data, dashboardDetails, accessToken),
    loadingSection
  );
};

const loadTotalTripsFn = async (
  dashboard,
  dashboardName,
  filters,
  originGeoIds,
  destinationGeoIds,
  dataKey
) => {
  return await loadTotalTripsFromApi(
    dashboard.table,
    dashboard.userId,
    dashboard.dashboardId,
    dashboard.clientName,
    dashboardName,
    filters,
    originGeoIds,
    destinationGeoIds,
    dataKey
  );
};

export const loadTotalTrips = async (
  dashboard,
  dashboardName,
  filters,
  originGeoIds,
  destinationGeoIds,
  dataKey,
  loadingSection
) => {
  return await loaderWrapper(
    async () =>
      await loadTotalTripsFn(
        dashboard,
        dashboardName,
        filters,
        originGeoIds,
        destinationGeoIds,
        dataKey
      ),
    loadingSection
  );
};

const loadTransitTripsFn = async (dashboard, filters) => {
  switch (dashboard?.backend) {
    case 'database':
    case 'files':
    default: {
      const transitDir = `transit/trips`;
      let nextFilters = { ...filters };
      delete nextFilters['origin_geo'];
      delete nextFilters['destination_geo'];

      nextFilters = Object.fromEntries(
        Object.entries(nextFilters).map(([k, v]) => [k, v[0]])
      );

      let data = await loadDataFromFiles(transitDir, nextFilters);

      return data;
    }
  }
};

export const loadTransitTrips = async (dashboard, filters, loadingSection) => {
  return await loaderWrapper(
    async () => await loadTransitTripsFn(dashboard, filters),
    loadingSection
  );
};

// Truck Dashboard

const loadTotalTripsForTruckFn = async (
  dashboard,
  filters,
  originGeoIds,
  destinationGeoIds
) => {
  return await loadTotalTripsForTruckApi(
    dashboard,
    filters,
    originGeoIds,
    destinationGeoIds
  );
};

export const loadTotalTripsForTruck = async (
  dashboard,
  dashboardName,
  filters,
  originGeoIds,
  destinationGeoIds,
  dataKey,
  loadingSection
) => {
  return await loaderWrapper(
    async () =>
      await loadTotalTripsForTruckFn(
        dashboard,
        filters,
        originGeoIds,
        destinationGeoIds
      ),
    loadingSection
  );
};

const loadSummaryDataForTruckFn = async (
  direction,
  dashboard,
  filters,
  dataKey,
  options
) => {
  return await loadSummaryDataForTruckApi(
    dashboard,
    direction,
    filters,
    dataKey,
    options
  );
};

export const loadSummaryDataForTruck = async (
  direction,
  dashboard,
  filters,
  dataKey,
  loadingSection,
  options = {}
) => {
  return await loaderWrapper(
    async () =>
      await loadSummaryDataForTruckFn(
        direction,
        dashboard,
        filters,
        dataKey,
        options
      ),
    loadingSection
  );
};

const loadDataForTruckFn = async (dashboard, direction, filters) => {
  return await loadDataForTruckApi(dashboard, direction, filters);
};

export const loadDataForTruck = async (
  dashboard,
  direction,
  filters,
  loadingSection
) => {
  return await loaderWrapper(
    async () => await loadDataForTruckFn(dashboard, direction, filters),
    loadingSection
  );
};

const getCrossTabulationForTruckFn = async (
  dataKey,
  dashboard,
  geoIds,
  dimensions,
  filters
) => {
  return await loadCrossTabulationDataForTruckApi(
    dashboard,
    dataKey,
    geoIds,
    dimensions,
    filters
  );
};

export const getCrossTabulationForTruck = async (
  dataKey,
  dashboard,
  geoIds,
  dimensions,
  filters,
  loadingSection
) => {
  return await loaderWrapper(
    async () =>
      await getCrossTabulationForTruckFn(
        dataKey,
        dashboard,
        geoIds,
        dimensions,
        filters
      ),
    loadingSection
  );
};
