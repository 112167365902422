import bbox from '@turf/bbox';

const tempDashboardTransform = (
  clientName,
  userId,
  dashboardName,
  apiDashboard
) => {
  const { dashboard, studyareaBlockgroups } = apiDashboard;
  const { shape } = dashboard?.studyArea;
  const mapBounds = bbox(shape);
  const mapCenter = [
    (mapBounds[0] + mapBounds[2]) / 2,
    (mapBounds[1] + mapBounds[3]) / 2,
  ];
  const nextDashboard = {
    name: dashboardName,
    dashboardId: dashboard?.id,
    userId,
    viewName: dashboard?.studyArea?.name,
    type: dashboard?.product?.name,
    table: `hive_metastore.${clientName}.flow`,
    studyPeriods: dashboard?.studyPeriods,
    clientName,
    backend: 'database',
    mapBounds,
    mapCenter,
    studyareaBlockgroups,
    filterOptions: dashboard?.filterOptions ?? {},
    zoneSystems: dashboard?.zoneSystems ?? [],
  };

  return nextDashboard;
};

export { tempDashboardTransform };
