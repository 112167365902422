import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import plur from 'plur';
import * as d3 from 'd3';
import throttle from 'lodash.throttle';
import classNames from 'classnames';
import './Legend.scss';
import { evaluateExpression } from '../evaluate-expression';
import {
  CHOROPLETH_BASIS_OPTIONS,
  dataKeys,
  toImageDataUrl,
} from '../constants';
import Loader from './Loader';
import { useReportStore } from '../store/reportStore';
import MetricRangeFilter from './MetricRangeFilter';
import { ReactComponent as CaretDown } from '../images/caret_down.svg';
import { ReactComponent as CaretUp } from '../images/caret_up.svg';
import { useTranslation } from 'react-i18next';

const numberFormat = d3.format(',.3r');
const shortDecimalFormat = d3.format('.2s');
const shortFloatFormat = d3.format('.2r');

function Legend({
  data,
  dataKey,
  direction,
  setHighlightedExtent,
  isLoading,
  mapStore,
  zoneOptions,
  zoneType,
}) {
  const legendState = mapStore(state => state.legend);
  const { t } = useTranslation();
  const xAxisRef = useRef();
  const [expand, setExpand] = useState(false);
  const [highlightedBar, setHighlightedBar] = useState(null);
  const [mouseOverSvg, setMouseOverSvg] = useState(false);

  const setOriginLegendUrl = useReportStore(state => state.setOriginLegendUrl);
  const setDestinationLegendUrl = useReportStore(
    state => state.setDestinationLegendUrl
  );

  const getExpandIcon = isOpen => {
    return !isOpen ? <CaretDown /> : <CaretUp />;
  };

  const getColor = useCallback(
    num => {
      if (!legendState.inputOutputs) return 'white';
      if (
        legendState.inputOutputs.length === 1 &&
        typeof legendState.inputOutputs[0] === 'string'
      ) {
        return legendState.inputOutputs[0];
      }
      let expression =
        legendState.scaleType === 'step'
          ? ['step']
          : ['interpolate', ['linear']];
      expression = expression.concat([['get', 'value']]);
      expression = expression.concat(legendState.inputOutputs);
      const args = {
        layerType: 'fill',
        propertyType: 'paint',
        propertyId: 'fill-color',
        properties: { value: num },
        value: expression,
      };
      const color = evaluateExpression(args);
      return color;
    },
    [legendState]
  );

  const margin = { top: 10, right: 20, bottom: 20, left: 10 },
    width = 360 - margin.left - margin.right,
    height = 75 - margin.top - margin.bottom;

  const dataValues = useMemo(() => (data ? Object.values(data) : []), [data]);

  const x = useMemo(() => {
    let domain = [];
    let min = d3.min(dataValues);
    let max = d3.quantile(dataValues, 0.99);

    // TODO come back and handle appropriately
    // This is just so something shows in instances
    // with a single feature
    if (min === max) {
      min = min - 1;
      max = max + 1;
    }

    domain = [min ? min : 0, max ? max : 0];

    return d3.scaleLinear().domain(domain).range([0, width]);
  }, [dataValues, width]);

  const tickBins = useMemo(() => {
    return legendState.inputOutputs?.filter(v => !isNaN(v)) ?? [];
  }, [legendState]);

  const histogram = useMemo(() => {
    const nBin = tickBins.length * 10;
    return d3.histogram().domain(x.domain()).thresholds(x.ticks(nBin));
  }, [x, tickBins]);

  const bins = useMemo(() => {
    return histogram(dataValues || []);
  }, [histogram, dataValues]);

  const y = useMemo(() => {
    return d3
      .scaleLinear()
      .range([height, 0])
      .domain([0, d3.max(bins, d => d.length)]);
  }, [bins, height]);

  const rects = useMemo(() => {
    return bins.map((bin, key) => {
      const widthValue = x(bin.x1) - x(bin.x0) - 1;
      return {
        key,
        x: x(bin.x0),
        transform: `translate(0, ${y(bin.length)})`,
        width: !widthValue || widthValue < 0 ? 0 : widthValue,
        height: height - y(bin.length),
        fill: getColor(d3.mean(bin.filter(d => !!d)) ?? 0),
      };
    });
  }, [bins, getColor, height, x, y]);

  useEffect(() => {
    let tickValues = [0, ...tickBins, x.domain()[1] ? x.domain()[1] : 0];
    if (mouseOverSvg) {
      tickValues = [...tickValues.slice(0, 1), ...tickValues.slice(-1)];
    }

    tickValues = tickValues.filter((d, i) => {
      if (i === 0 || i === tickValues.length - 1) return true;
      return tickValues.every((other, j) => {
        if (j === i) return true;
        return Math.abs(x(d) - x(other)) > 30;
      });
    });

    const appendedPercent = dataKey.includes('share_of') ? '%' : '';

    const axis = d3.select(xAxisRef.current);
    axis.selectAll('*').remove();
    axis.call(
      d3
        .axisBottom(x)
        .tickValues(tickValues)
        .tickFormat((d, i) => {
          if (d === 0) return `${0}${appendedPercent}`;
          if (i === tickValues.length - 1)
            return `${numberFormat(d)}+${appendedPercent}`;
          return d > 1
            ? `${shortDecimalFormat(d)}${appendedPercent}`
            : `${shortFloatFormat(d)}${appendedPercent}`;
        })
    );
  }, [x, mouseOverSvg, tickBins, dataKey, isLoading]);

  const highlightedBarExtent = useMemo(() => {
    const bin = bins.find((bin, i) => i === highlightedBar);
    if (!bin) return null;
    return [bin.x0, bin.x1].map(numberFormat);
  }, [bins, highlightedBar]);

  const updateHighlightedExtent = useMemo(() => {
    return throttle(setHighlightedExtent, 500);
  }, [setHighlightedExtent]);

  useEffect(() => {
    const bin = bins.find((bin, i) => i === highlightedBar);
    const extent = bin ? [bin.x0, bin.x1] : null;
    updateHighlightedExtent(extent);
  }, [bins, highlightedBar, updateHighlightedExtent]);

  const highlightedBarCount = useMemo(() => {
    return bins.find((bin, i) => i === highlightedBar)?.length;
  }, [bins, highlightedBar]);

  const highlightedBarLabelX = useMemo(() => {
    const rect = rects[highlightedBar];
    if (!rect) return null;
    return rect.x + rect.width / 2;
  }, [highlightedBar, rects]);

  const highlightedBarLabelAnchor = useMemo(() => {
    return highlightedBarLabelX > width / 2 ? 'end' : 'start';
  }, [highlightedBarLabelX, width]);

  const handleMouseEnterRect = rect => {
    setHighlightedBar(rect.key);
  };

  const handleMouseLeaveRect = rect => {
    setHighlightedBar(null);
  };

  const zoneTypeLabel = useMemo(() => {
    return zoneOptions.find(({ value }) => value === zoneType)?.label ?? '';
  }, [zoneOptions, zoneType]);

  const checkElement = document.getElementsByClassName('legendBars');

  const getLegendImg = async () => {
    const legendElement = document.getElementById(`legend-${direction}-graph`);
    if (legendElement) {
      const img = toImageDataUrl(legendElement, 2, 2);
      direction === 'origin' && setOriginLegendUrl(img);
      direction === 'destination' && setDestinationLegendUrl(img);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(
      () => checkElement?.length && getLegendImg(),
      2000
    );
    return () => clearTimeout(timeout);
  }, [checkElement?.length, direction]);

  return (
    <>
      <div id="legend" className="Legend drop-shadow">
        {isLoading ? (
          <Loader />
        ) : (
          <div
            className={`${classNames({
              collapsed: expand,
            })}`}
          >
            <div className="Legend-footer">
              <div>
                {
                  CHOROPLETH_BASIS_OPTIONS.find(
                    ({ value }) => value === dataKey
                  ).label
                }{' '}
                by {direction} {zoneTypeLabel}
              </div>
              <div
                onClick={() => setExpand(!expand)}
                className="Legend-expand-icon"
              >
                <span className="Legend-expand-label">
                  {expand ? t('charts.expand') : t('charts.collapse')}
                </span>
                {getExpandIcon(expand)}
              </div>
            </div>
            <div id={`legend-${direction}-graph`}>
              <svg
                width={width + margin.left + margin.right}
                height={height + margin.top + margin.bottom}
                onMouseEnter={() => setMouseOverSvg(true)}
                onMouseLeave={() => setMouseOverSvg(false)}
              >
                <g
                  className="event-bars"
                  transform={`translate(${margin.left}, ${margin.top})`}
                >
                  {rects.map(rect => (
                    <rect
                      className="legendBars"
                      key={rect.key}
                      x={rect.x}
                      fill="transparent"
                      height={height}
                      width={rect.width}
                      onMouseEnter={() => handleMouseEnterRect(rect)}
                      onMouseLeave={() => handleMouseLeaveRect(rect)}
                    />
                  ))}
                </g>
                <g
                  className="visible-bars"
                  transform={`translate(${margin.left}, ${margin.top})`}
                >
                  {rects.map(rect => (
                    <rect
                      key={rect.key}
                      className={`${classNames({
                        highlighted: rect.key === highlightedBar,
                      })} legendBars`}
                      x={rect.x}
                      fill={rect.fill}
                      height={rect.height}
                      transform={rect.transform}
                      width={rect.width}
                    />
                  ))}
                </g>
                <g
                  ref={xAxisRef}
                  className="x-axis"
                  transform={`translate(${margin.left}, ${
                    margin.top + height
                  })`}
                />
                {highlightedBarCount ? (
                  <g
                    className="highlighted-bar-count"
                    transform={`translate(${
                      margin.left + highlightedBarLabelX
                    }, ${margin.top})`}
                  >
                    <text textAnchor={highlightedBarLabelAnchor}>
                      {highlightedBarCount}{' '}
                      {plur(zoneTypeLabel, highlightedBarCount)}
                    </text>
                  </g>
                ) : null}
                {highlightedBarExtent ? (
                  <g
                    className="highlighted-bar-extent"
                    transform={`translate(${
                      margin.left + highlightedBarLabelX
                    }, ${margin.top + height})`}
                  >
                    <text dy="1.5em" textAnchor={highlightedBarLabelAnchor}>
                      {highlightedBarExtent.join(' - ')}
                    </text>
                  </g>
                ) : null}
              </svg>
            </div>
            <>
              {(dataKey === 'daily_trips' ||
                dataKey === 'daily_pmt' ||
                dataKey === 'vmt') && (
                <MetricRangeFilter mapStore={mapStore} dataKey={dataKey} />
              )}
            </>
          </div>
        )}
      </div>
    </>
  );
}

export default Legend;
