import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Histogram from './visualizations/Histogram';
import './VisualizationsContainer.scss';
import Loader from './Loader';
import { ReactComponent as CaretDown } from '../images/caret_down.svg';
import { ReactComponent as CaretUp } from '../images/caret_up.svg';
import { ReactComponent as ChartSegmentation } from '../images/chart-segmentation.svg';
import { FILTERS_LABELS, toImageDataUrl } from '../constants';
import { FILTERS_LABELS_TRUCK } from '../constants';
import MenuContainer from './MenuContainer';
import Popover from './Popover';
import ChartsDisplayMenu from './menus/ChartsDisplayMenu';
import TabSet from './TabSet';
import { useGraphTabsStore } from '../store/graphs-tab';
import { useReportStore } from '../store/reportStore';

function VisualizationsContainer({
  summaryData,
  highlightedSummaryData,
  isLoading,
  setSegmentation,
  dataSegmentation,
  filterValues,
  dataKey,
  isTruckDashboard,
  dashboardName,
}) {
  const { t } = useTranslation();

  const passengerChartOrder = [
    { value: 'mode', label: t('filterLabels.travelMode') },
    { value: 'purpose', label: t('filterLabels.purpose') },
    { value: 'time_of_day', label: t('filterLabels.timeOfDay') },
    { value: 'equity', label: t('filterLabels.equityCommunities') },
    { value: 'distance_mi', label: t('filterLabels.tripLength') },
    { value: 'traveler_type', label: t('filterLabels.residencyStatus') },
    { value: 'trip_end_type', label: t('filterLabels.tripEndType') },
    // { value: 'wday_type', label: 'DAY OF THE WEEK' },
  ];

  const truckChartOrder = [
    { value: 'vehicle_class', label: 'VEHICLE CLASS' },
    { value: 'time_of_day', label: 'TIME OF DAY' },
    { value: 'distance_mi', label: 'TRIP LENGTH' },
    { value: 'vocation', label: 'VOCATION' },
    { value: 'industry', label: 'INDUSTRY' },
    { value: 'trip_end_type', label: 'TRIP END TYPE' },
  ];

  if (dashboardName === 'Maine') {
    truckChartOrder.splice(-1, 0, {
      value: 'stop_duration',
      label: 'STOP DURATION',
    });
  }

  const [open, setOpen] = useState(true);
  const [openMenu, setOpenMenu] = useState(null);
  const [hoverLegend, setHoverLegend] = useState(null);
  const [chartOrder, setChartOrder] = useState(passengerChartOrder);

  useEffect(() => {
    if (isTruckDashboard) {
      setChartOrder(truckChartOrder);
    }
  }, [isTruckDashboard]);

  const displayDataType = useGraphTabsStore(state => state.tab);
  const setDisplayDataType = useGraphTabsStore(state => state.setTab);

  const setTripImageUrl = useReportStore(state => state.setTripImageUrl);

  const [hasHighlighted, setHasHighlighted] = useState(
    !!highlightedSummaryData
  );

  const displayData = useMemo(() => {
    const data =
      displayDataType === 'study_area' ? summaryData : highlightedSummaryData;
    return data;
  }, [displayDataType, summaryData, highlightedSummaryData]);

  const ellipsisRef = useRef(null);

  const getExpandIcon = isOpen => {
    return !isOpen ? <CaretDown /> : <CaretUp />;
  };

  const legend = useMemo(() => {
    if (!displayData) return;
    const colors = ['#8DC63F', '#28B67C', '#19BEF0', '#148BCC', '#6478BA'];
    let values = [
      ...new Set(
        Object.values(displayData)
          .flat()
          .map(d => d.segmentation)
          .filter(Boolean)
      ),
    ];
    // Sort values for legend for consistency
    if (filterValues?.[dataSegmentation]) {
      values = filterValues?.[dataSegmentation].filter(v => values.includes(v));
    }

    return values.reduce((acc, v, i) => {
      acc[v] = colors[i % colors.length];
      return acc;
    }, {});
  }, [displayData, filterValues, dataSegmentation]);

  const renderChartSegmentationButton = () => {
    return (
      <div
        className="chart-segmentation-button-container"
        ref={ellipsisRef}
        onClick={() => setOpenMenu(v => (v === 'ellipsis' ? null : 'ellipsis'))}
      >
        <ChartSegmentation className="chart-segmentation-button" />
      </div>
    );
  };

  useEffect(() => {
    if (!highlightedSummaryData && displayDataType === 'selections') {
      setDisplayDataType('study_area');
    }
  }, [highlightedSummaryData, displayDataType, setDisplayDataType]);

  useEffect(() => {
    if (!highlightedSummaryData) {
      setHasHighlighted(false);
    } else {
      setHasHighlighted(true);
    }
  }, [highlightedSummaryData]);

  useEffect(() => {
    // This only fires after selecting an area. After which user can select regional again if they choose
    if (hasHighlighted) {
      setDisplayDataType('selections');
    }
  }, [hasHighlighted, setDisplayDataType]);

  const chartElement = document.getElementById(
    `Histogram-${chartOrder?.[0]?.value}-${displayDataType}`
  );

  const getEllipsisName = str =>
    str?.length > 10 ? str?.slice(0, 9) + '...' : str;

  const getOptionsList = async () => {
    const tripUrl = [];
    for (const chart of chartOrder) {
      const el = document.getElementById(
        `Histogram-${chart?.value}-${displayDataType}`
      );
      if (!el) continue;

      el.classList.add('Histogram-screenshot-padding');
      const label = el.getElementsByClassName('Histogram-label');
      if (label) {
        for (const title of label) {
          title.innerHTML = getEllipsisName(title.innerText);
        }
      }
      const img = toImageDataUrl(el, 5.25, 3);
      tripUrl.push(img);
      el.classList.remove('Histogram-screenshot-padding');
    }
    setTripImageUrl(tripUrl);
  };

  useEffect(() => {
    setTimeout(() => chartElement && getOptionsList(), 2000);
  }, [chartElement]);

  return (
    <div className="VisualizationsContainer">
      <div className="VisualizationsContainer-header-container">
        <div className="VisualizationsContainer-header-container-section">
          <div className="menu-header-label VisualizationsContainer-header-label">
            {t('charts.charts')}
          </div>

          <div
            className="VisualizationsContainer-collapse-button-container"
            onClick={() => setOpen(!open)}
          >
            <div className="VisualizationsContainer-collapse-button-label">
              {open ? t('charts.collapse') : t('charts.expand')}
            </div>
            <div className="VisualizationsContainer-collapse-button">
              {getExpandIcon(open)}
            </div>
          </div>
        </div>
        <div className="VisualizationsContainer-header-container-section">
          <TabSet
            options={[
              {
                label: t('charts.selected'),
                value: 'selections',
                disabled: !highlightedSummaryData,
              },
              { label: t('charts.regional'), value: 'study_area' },
            ]}
            onToggle={setDisplayDataType}
            selected={displayDataType}
            oppositeEl={renderChartSegmentationButton()}
          />
        </div>
      </div>

      {open ? (
        !isLoading && displayData ? (
          <>
            {legend && !!Object.keys(legend).length ? (
              <div className="VisualizationsContainer-legend">
                <div className="VisualizationsContainer-legend-segmentation-header menu-primary-label">
                  {FILTERS_LABELS.find(v => v.value === dataSegmentation)
                    ?.label ?? dataSegmentation}
                </div>
                <div className="VisualizationsContainer-legend-container">
                  {Object.entries(legend).map(([k, v]) => (
                    <div
                      key={k}
                      onMouseEnter={() => setHoverLegend(k)}
                      onMouseLeave={() => setHoverLegend(null)}
                      className="VisualizationsContainer-legend-label-container"
                      style={{
                        opacity: !hoverLegend || hoverLegend === k ? 1 : 0.3,
                      }}
                    >
                      <div
                        className="VisualizationsContainer-legend-swatch"
                        style={{ backgroundColor: v }}
                      />
                      <div className="VisualizationsContainer-legend-label">
                        {k}
                      </div>
                    </div>
                  ))}
                </div>{' '}
              </div>
            ) : null}
            {chartOrder.map(item =>
              !FILTERS_LABELS_TRUCK.find(l => l.value === item?.value)
                ?.removeHistogram && filterValues ? (
                <div
                  key={item?.value}
                  className="VisualizationsContainer-histogram-container"
                >
                  {displayData[item?.value] && (
                    <Histogram
                      property={item?.value}
                      data={displayData[item?.value]}
                      legend={legend}
                      hoveredLegend={hoverLegend}
                      filterValues={filterValues}
                      dataSegmentation={dataSegmentation}
                      displayDataType={displayDataType}
                      isTruckDashboard={isTruckDashboard}
                    />
                  )}
                </div>
              ) : null
            )}
          </>
        ) : (
          <div className="VisualizationsContainer-loader-container">
            <Loader />
          </div>
        )
      ) : null}

      <Popover parentRef={ellipsisRef.current} isOpen={openMenu === 'ellipsis'}>
        <MenuContainer closeMenu={() => setOpenMenu(null)}>
          <ChartsDisplayMenu
            data={displayData}
            setSegmentation={setSegmentation}
            dataSegmentation={dataSegmentation}
            filterValues={filterValues}
            isTruckDashboard
          />
        </MenuContainer>
      </Popover>
    </div>
  );
}

export default VisualizationsContainer;
