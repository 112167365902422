import {
  getQuantileValues,
  getEqualIntervalValues,
  getJenksValues,
} from './classification_methods';
import { ReactComponent as Map } from './images/map.svg';
import { ReactComponent as Filter } from './images/filters.svg';
import { ReactComponent as Data } from './images/data.svg';
import _ from 'lodash';
import moment from 'moment';
import html2canvas from 'html2canvas';
import * as d3 from 'd3';
import i18next from 'i18next';

export const FILTER_PROPERTY_ORDER = [
  'wday_type',
  'period',
  'traveler_type',
  'equity',
];

export const TRANSIT_FILTER_PROPERTY_ORDER = [
  'day_of_week',
  'time_of_day',
  'equity',
  'purpose',
  'itinerary_type',
  'travel_time_ratio_bin',
];

export const parseFilterValues = humanText => {
  return humanText.replaceAll(' ', '_').toLowerCase();
};

export const TIMEOUT_TIME = 200;

export const MAPBOX_STYLE_URL =
  'mapbox://styles/locus-cs/cllpanxzu00dd01ph0crsb3s9';
export const MAPBOX_STYLE_URLS = {
  light: 'mapbox://styles/locus-cs/cln91twkf076501ma00tn6rkj/draft',
  dark: 'mapbox://styles/locus-cs/cln9avjg4076n01qi4eyubw94/draft',
};
export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoibG9jdXMtY3MiLCJhIjoiY2xscDlrbmY3MDQzdTNmbzVsa2QwOXF1eSJ9.C0aPmSKbycVfCXQ3h6KcBg';
export const MAPBOX_TILESETS = {
  transit_geomarkets: {
    url: 'mapbox://locus-cs.dv061ub1',
    layerName: 'geomarkets-9u565o',
  },
  Blockgroup: {
    url: 'mapbox://locus-cs.2ho2njuz',
    layerName: 'us-blockgroups',
  },
  Censustract: {
    layerName: 'us-censustracts',
    url: 'mapbox://locus-cs.0d9jb5l5',
  },
  County: {
    layerName: 'us-counties',
    url: 'mapbox://locus-cs.7421skw4',
  },
};

export const ZONE_SYSTEM_TYPES = {
  censusTract: 'Censustract',
  blockGroup: 'Blockgroup',
  county: 'County',
};

export const WDAY_TYPE = {
  sunday: 3,
  saturday: 2,
  weekday: 1,
};

export const ZONE_SYSTEM_TEXT = {
  Censustract: 'census tract',
  Blockgroup: 'block group',
  County: 'county',
};

export const pluralize = (count, text, suffix = 's') => {
  if (text !== 'county') {
    return `${text}${count !== 1 ? suffix : ''}`;
  } else {
    if (count !== 1) {
      return 'counties';
    } else {
      return 'county';
    }
  }
};

export const DATA_API_URL = `${process.env.REACT_APP_API_BASE_URL}/query`;

export const BLOCKGROUPS_API_URL = `${process.env.REACT_APP_API_BASE_URL}/blockgroups`;

export const DASHBOARD_API_URL = `${process.env.REACT_APP_API_BASE_URL}/dashboards`;

export const VIEWS_API_URL = `${process.env.REACT_APP_API_BASE_URL}/views`;

export const MAP_LAYERS_API_URL = `${process.env.REACT_APP_API_BASE_URL}/dashboards`;

export const CENSUSTRACTS_API_URL = `${process.env.REACT_APP_API_BASE_URL}/censustracts`;

export const COUNTIES_API_URL = `${process.env.REACT_APP_API_BASE_URL}/counties`;

export const TRUCK_GEOGRAPHIC_DETAILS_API_URL = `${process.env.REACT_APP_API_BASE_URL}/query/truck/geographicdetails`;

export const API_KEY =
  'aKJxmEFg4cy93vDEUbtAxN1sJ6NsIN5AT9Ep9Sy9QBF1VE9KtF4lqUaj6k5wVUEU';

export const pxToMm = px => px * 0.264583;

export const CLASSIFICATION_METHODS = {
  quantile: {
    label: i18next.t('palette.quantile'),
    value: 'quantile',
    fn: getQuantileValues,
  },
  equalInterval: {
    label: i18next.t('palette.equalInterval'),
    value: 'equalInterval',
    fn: getEqualIntervalValues,
  },
  jenks: {
    label: i18next.t('palette.jenks'),
    value: 'jenks',
    fn: getJenksValues,
  },
};

export const CUSTOM_DATA_DOWNLOAD_OPTIONS = [
  {
    label: i18next.t('trips.dailyTrips'),
    value: 'daily_trips',
    dashboardType: 'person',
  },
  {
    label: i18next.t('trips.personMilesTraveled'),
    value: 'daily_pmt',
    dashboardType: 'person',
  },
  {
    label: i18next.t('trips.dailyTrips'),
    value: 'trips',
    dashboardType: 'truck',
  },
  {
    label: i18next.t('trips.vehicleMilesTraveled'),
    value: 'vmt',
    dashboardType: 'truck',
  },
];

export const CHOROPLETH_BASIS_OPTIONS = [
  // person
  {
    label: i18next.t('trips.dailyTrips'),
    value: 'daily_trips',
    dashboardType: 'person',
  },
  {
    label: i18next.t('trips.personMilesTraveled'),
    value: 'daily_pmt',
    dashboardType: 'person',
  },
  {
    label: i18next.t('trips.shareTotalTrips'),
    value: 'share_of_total_trips',
    labelValueFn: v => `${v}%`,
    dashboardType: 'person',
  },
  {
    label: i18next.t('trips.bikeModeShare'),
    value: 'share_of_bike_trips',
    labelValueFn: v => `${v}%`,
    dashboardType: 'person',
  },
  {
    label: i18next.t('trips.walkModeShare'),
    value: 'share_of_walk_trips',
    labelValueFn: v => `${v}%`,
    dashboardType: 'person',
  },
  {
    label: i18next.t('trips.activeTransportModeShare'),
    value: 'share_of_active_transportation_trips',
    labelValueFn: v => `${v}%`,
    dashboardType: 'person',
  },
  {
    label: i18next.t('trips.tripDensity'),
    value: 'trip_density',
    dashboardType: 'person',
  },
  // Transit
  // TODO TRANSIT confirm that these metrics reflect the database
  // They reflect what is in our sample static files
  {
    label: i18next.t('trips.totalTrips'),
    value: 'total_trips',
    dashboardType: 'transit',
  },
  {
    label: i18next.t('trips.totalTransitTrips'),
    value: 'total_transit_trips',
    dashboardType: 'transit',
  },
  {
    label: i18next.t('trips.totalDistance'),
    value: 'total_distance',
    dashboardType: 'transit',
  },
  {
    label: i18next.t('trips.totalTransfers'),
    value: 'total_transfers',
    dashboardType: 'transit',
  },
  {
    label: i18next.t('trips.totalTravelTimeRatio'),
    value: 'total_travel_time_ratio',
    dashboardType: 'transit',
  },
  {
    label: 'Daily trips',
    value: 'daily_trips',
    dashboardType: 'truck',
  },
  {
    label: 'Vehicle miles traveled',
    value: 'vmt',
    dashboardType: 'truck',
  },
  {
    label: 'Share of total trips',
    value: 'share_of_total_trips',
    labelValueFn: v => `${v}%`,
    dashboardType: 'truck',
  },
  {
    label: 'Share of light truck vehicle trips',
    value: 'share_of_light_truck_trips',
    labelValueFn: v => `${v}%`,
    dashboardType: 'truck',
  },
  {
    label: 'Share of medium truck vehicle trips',
    value: 'share_of_medium_truck_trips',
    labelValueFn: v => `${v}%`,
    dashboardType: 'truck',
  },
  {
    label: 'Share of heavy truck vehicle trips',
    value: 'share_of_heavy_truck_trips',
    labelValueFn: v => `${v}%`,
    dashboardType: 'truck',
  },
  {
    label: 'Trip density (trips per square mile)',
    value: 'trip_density',
    dashboardType: 'truck',
  },
];

export const MENUS = [
  { icon: Map, value: 'mapOptions', label: i18next.t('mapMenu.mapLayers') },
  { icon: Filter, value: 'filterOptions', label: i18next.t('mapMenu.filters') },
  {
    icon: Data,
    value: 'dataOptions',
    label: i18next.t('mapMenu.mapCustomizations'),
  },
];

export const FILTERS_LABELS = [
  {
    value: 'period',
    label: i18next.t('filterLabels.period'),
    control: 'buttons',
    removeHistogram: true,
  },
  {
    value: 'traveler_type',
    label: i18next.t('filterLabels.residencyStatus'),
    control: 'buttons',
  },
  {
    value: 'equity',
    label: i18next.t('filterLabels.equityCommunities'),
    control: 'buttons',
  },
  {
    value: 'wday_type',
    label: i18next.t('filterLabels.dayOfWeek'),
    control: 'buttons',
  },
  {
    value: 'purpose',
    label: i18next.t('filterLabels.purpose'),
    control: 'buttons',
  },
  {
    value: 'time_of_day',
    label: i18next.t('filterLabels.timeOfDay'),
    control: 'buttons',
  },
  {
    value: 'distance_mi',
    label: i18next.t('filterLabels.tripLength'),
    control: 'buttons',
  },
  {
    value: 'mode',
    label: i18next.t('filterLabels.travelMode'),
    control: 'buttons',
  },
  {
    value: 'trip_end_type',
    label: i18next.t('filterLabels.tripEndType'),
    control: 'buttons',
  },
  {
    value: 'stop_duration',
    label: i18next.t('filterLabels.postTripStopDuration'),
    control: 'buttons',
  },
];

export const FILTER_OPTIONS_TRUCK = {
  vehicleClass: [
    {
      vehicle_class: 'Light Duty Trucks',
      label: 1,
    },
    {
      vehicle_class: 'Medium Duty Trucks',
      label: 2,
    },
    {
      vehicle_class: 'Heavy Duty Trucks',
      label: 3,
    },
  ],
  dataType: [
    {
      data_type: 'Sample',
      label: 1,
    },
    {
      data_type: 'Expanded',
      label: 2,
    },
    {
      data_type: 'Validated',
      label: 3,
    },
  ],
  tripLength: [
    {
      distance_mi: '<1mi',
      label: 1,
    },
    {
      distance_mi: '1-2mi',
      label: 2,
    },
    {
      distance_mi: '2-5mi',
      label: 3,
    },
    {
      distance_mi: '5-10mi',
      label: 4,
    },
    {
      distance_mi: '10-25mi',
      label: 5,
    },
    {
      distance_mi: '25-50mi',
      label: 6,
    },
    {
      distance_mi: '50-100mi',
      label: 7,
    },
    {
      distance_mi: '100-200mi',
      label: 8,
    },
    {
      distance_mi: '200-300mi',
      label: 9,
    },
    {
      distance_mi: '>300mi',
      label: 10,
    },
  ],
  stopDuration: [
    {
      stop_duration: '<5min',
      label: 1,
    },
    {
      stop_duration: '5-10min',
      label: 2,
    },
    {
      stop_duration: '10-15min',
      label: 3,
    },
    {
      stop_duration: '15-30min',
      label: 4,
    },
    {
      stop_duration: '30-45min',
      label: 5,
    },
    {
      stop_duration: '45-60min',
      label: 6,
    },
    {
      stop_duration: '1-1.5hours',
      label: 7,
    },
    {
      stop_duration: '1.5-2hours',
      label: 8,
    },
    {
      stop_duration: '2-4hours',
      label: 9,
    },
    {
      stop_duration: '4-6hours',
      label: 10,
    },
    {
      stop_duration: '6-8hours',
      label: 11,
    },
    {
      stop_duration: '8-9hours',
      label: 12,
    },
    {
      stop_duration: '9-12hours',
      label: 13,
    },
    {
      stop_duration: '12-15hours',
      label: 14,
    },
    {
      stop_duration: '>15hours',
      label: 15,
    },
  ],
  timeOfDays: [
    {
      time_of_day: 'AM Peak (6:00 am - 10:00 am)',
      label: 1,
    },
    {
      time_of_day: 'Mid Day (10:00 am - 3:00 pm)',
      label: 2,
    },
    {
      time_of_day: 'PM Peak (3:00 pm - 7:00 pm)',
      label: 3,
    },
    {
      time_of_day: 'Night (7:00 pm - 6:00 am)',
      label: 4,
    },
  ],
  vocation: [
    {
      vocation: 'Door to Door',
      label: 1,
    },
    {
      vocation: 'Local',
      label: 2,
    },
    {
      vocation: 'Hub and Spoke',
      label: 3,
    },
    {
      vocation: 'Long Haul',
      label: 4,
    },
    {
      vocation: 'Regional',
      label: 5,
    },
    {
      vocation: 'Unknown',
      label: 6,
    },
  ],
  industry: [
    {
      industry:
        'Administrative and Support and Waste Management and Remediation Services',
      label: 1,
    },
    {
      industry: 'Public Administration',
      label: 2,
    },
    {
      industry: 'Real Estate and Rental and Leasing',
      label: 3,
    },
    {
      industry: 'Information',
      label: 4,
    },
    {
      industry: 'Accommodation and Food Services',
      label: 5,
    },
    {
      industry: 'Construction',
      label: 6,
    },
    {
      industry: 'Finance and Insurance',
      label: 7,
    },
    {
      industry: 'Utilities',
      label: 8,
    },
    {
      industry: 'Management of Companies and Enterprises',
      label: 9,
    },
    {
      industry: 'Professional, Scientific, and Technical Services',
      label: 10,
    },
    {
      industry: 'Arts, Entertainment, and Recreation',
      label: 11,
    },
    {
      industry: 'Transportation and Warehousing',
      label: 12,
    },
    {
      industry: 'Wholesale Trade',
      label: 13,
    },
    {
      industry: 'Agriculture, Forestry, Fishing and Hunting',
      label: 14,
    },
    {
      industry: 'Manufacturing',
      label: 15,
    },
    {
      industry: 'Mining, Quarrying, and Oil and Gas Extraction',
      label: 16,
    },
    {
      industry: 'Educational Services',
      label: 17,
    },
    {
      industry: 'Health Care and Social Assistance',
      label: 18,
    },
    {
      industry: 'Retail Trade',
      label: 19,
    },
    {
      industry: 'Other Services (except Public Administration)',
      label: 20,
    },
    {
      industry: 'Unknown',
      label: 21,
    },
  ],
  tripEndType: [
    {
      trip_end_type: 'Internal',
      label: 0,
    },
    {
      trip_end_type: 'Internal-External',
      label: 1,
    },
    {
      trip_end_type: 'External-Internal',
      label: 2,
    },
  ],
};

export const FILTER_VALUES_TRUCK = {
  vocation: [
    'Door to Door',
    'Local',
    'Hub and Spoke',
    'Long Haul',
    'Regional',
    'Unknown',
  ],
  distance_mi: [
    '<1mi',
    '1-2mi',
    '2-5mi',
    '5-10mi',
    '10-25mi',
    '25-50mi',
    '50-100mi',
    '100-200mi',
    '200-300mi',
    '>300mi',
  ],
  time_of_day: [
    'AM Peak (6:00 am - 10:00 am)',
    'Mid Day (10:00 am - 3:00 pm)',
    'PM Peak (3:00 pm - 7:00 pm)',
    'Night (7:00 pm - 6:00 am)',
  ],
  vehicle_class: [
    'Light Duty Trucks',
    'Medium Duty Trucks',
    'Heavy Duty Trucks',
  ],
  data_type: ['Sample', 'Expanded', 'Validated'],
  industry: [
    'Administrative and Support and Waste Management and Remediation Services',
    'Public Administration',
    'Real Estate and Rental and Leasing',
    'Information',
    'Accommodation and Food Services',
    'Construction',
    'Finance and Insurance',
    'Utilities',
    'Management of Companies and Enterprises',
    'Professional, Scientific, and Technical Services',
    'Arts, Entertainment, and Recreation',
    'Transportation and Warehousing',
    'Wholesale Trade',
    'Agriculture, Forestry, Fishing and Hunting',
    'Manufacturing',
    'Mining, Quarrying, and Oil and Gas Extraction',
    'Educational Services',
    'Health Care and Social Assistance',
    'Retail Trade',
    'Other Services (except Public Administration)',
    'Unknown',
  ],
  stop_duration: [
    '<5min',
    '5-10min',
    '10-15min',
    '15-30min',
    '30-45min',
    '45-60min',
    '1-1.5hours',
    '1.5-2hours',
    '2-4hours',
    '4-6hours',
    '6-8hours',
    '8-9hours',
    '9-12hours',
    '12-15hours',
    '>15hours',
  ],
  trip_end_type: ['Internal', 'Internal-External', 'External-Internal'],
};

export const FILTERS_LABELS_TRUCK = [
  {
    value: 'industry',
    label: 'INDUSTRY',
    control: 'buttons',
  },
  {
    value: 'vocation',
    label: 'VOCATION',
    control: 'buttons',
  },
  {
    value: 'distance_mi',
    label: 'TRIP LENGTH',
    control: 'buttons',
  },
  {
    value: 'time_of_day',
    label: 'TIME OF DAY',
    control: 'buttons',
  },
  {
    value: 'data_type',
    label: 'DATA TYPE',
    control: 'buttons',
  },
  {
    value: 'equity',
    label: 'EQUITY',
    control: 'buttons',
  },
  { value: 'vehicle_class', label: 'VEHICLE CLASS', control: 'buttons' },
  {
    value: 'stop_duration',
    label: 'POST TRIP STOP DURATION',
    control: 'buttons',
  },
  { value: 'trip_end_type', label: 'TRIP END TYPE', control: 'buttons' },
];

export const ZONE_TYPE_OPTIONS = [
  // Transit
  {
    label: i18next.t('palette.transitGeomarkets'),
    value: 'transit_geomarkets',
    dashboardType: 'transit',
  },
  // person
  {
    label: i18next.t('palette.censusTract'),
    value: 'Censustract',
    dashboardType: 'person',
  },
  {
    label: i18next.t('palette.blockGroup'),
    value: 'Blockgroup',
    dashboardType: 'person',
  },
  {
    label: i18next.t('palette.county'),
    value: 'County',
    dashboardType: 'person',
  },
  { label: 'Census tract', value: 'Censustract', dashboardType: 'truck' },
  { label: 'County', value: 'County', dashboardType: 'truck' },
];

export const getHumanReadableNumber = num => {
  if (isNaN(num)) return;
  if (num < 5) {
    return num ? num?.toFixed(2) : num;
  }
  const rounded = `${Math.round(Number(num))}`;
  let split = rounded.split('');
  split = split.reverse().reduce((acc, d, i) => {
    if (i % 3 === 0) {
      acc.push([d]);
    } else {
      acc[acc.length - 1].push(d);
    }
    return acc;
  }, []);
  split = split.reverse().map(v => v.reverse().join(''));
  let str;
  if (split.length === 5) {
    str = `${split[0]}.${split[1].slice(0, 2)} trillion`;
  } else if (split.length === 4) {
    str = `${split[0]}.${split[1].slice(0, 2)} billion`;
  } else if (split.length === 3) {
    str = `${split[0]}.${split[1].slice(0, 2)} million`;
  } else {
    str = split.join(',');
  }
  return str;
};

export const getCurrentDate = () => {
  let date = new Date(Date.now());
  date = date.toLocaleDateString().split('/');
  return `${date[2]}${date[0]}${date[1]}`;
};

// This takes the data format from the api and converts to that we use in the app
export const apiFiltersToAppFilters = viewFilters => {
  let nextFilters = viewFilters;
  let property;
  nextFilters = Object.entries(nextFilters)
    .filter(([k, v]) => !!v)
    .reduce((acc, [k, v]) => {
      const values = v
        .sort((a, b) => (a?.label ?? 0) - (b?.label ?? 0))
        .map(v => {
          const key = Object.keys(v).filter(v => v !== 'label')[0];
          property = key;
          return v[key];
        });

      if (acc[property]) {
        acc[property] = acc[property].concat(values);
      } else {
        acc[property] = values;
      }

      return acc;
    }, {});
  if (nextFilters.name) {
    delete nextFilters.name;
  }
  return nextFilters;
};

// This takes the data format that we use in the app and converts to what is expected by the api
export const appFiltersToApiFilters = (filters, filterOptions) => {
  let next = Object.entries(filterOptions)
    .map(([k, vals]) => {
      if (!vals.length) return false;
      const key = Object.keys(vals[0]).filter(v => v !== 'label')[0];
      let nextVals = vals;
      if (filters[key]) {
        nextVals = nextVals.filter(v => filters[key].includes(v[key]));
        return [k, nextVals];
      } else {
        return false;
      }
    })
    .filter(Boolean);

  next = Object.fromEntries(next);

  return next;
};

// This outputs the appropriate format for querying (integers and altered strings with no spaces)
export const appFiltersToQueryFilters = (filters, options) => {
  options = Object.values(options).reduce((acc, vals) => {
    if (!vals.length) return acc;
    const key = Object.keys(vals[0]).filter(l => l !== 'label')[0];
    if (!acc[key]) {
      acc[key] = {};
    }
    for (const val of vals) {
      acc[key][val[key]] = val?.label ?? val[key];
    }
    return acc;
  }, {});

  let next = Object.fromEntries(
    Object.entries(filters).map(([k, vals]) => {
      const nextVal = vals.map(v => {
        if (options?.[k]?.[v] || options?.[k]?.[v] === 0) {
          return options[k][v];
        } else return v;
      });
      return [k, nextVal];
    })
  );

  // If using a string rather than integer (label)
  // Don't have spaces
  next = Object.fromEntries(
    Object.entries(next).map(([k, v]) => {
      const nextV = v.map(val =>
        typeof val === 'string' && k !== 'zoneSystem' && k !== 'vehicle_class'
          ? val.replaceAll(' ', '')
          : val
      );
      return [k, nextV];
    })
  );

  return next;
};

// Converts summary data from API (uses integers) to summary data
// that can be used by the app
export const apiSummaryDataToAppSummaryData = (
  summaryData,
  segmentationProp,
  options
) => {
  options = Object.values(options)
    .flat()
    .reduce((acc, o) => {
      const key = Object.keys(o).filter(k => k !== 'label')[0];
      if (!acc[key]) {
        acc[key] = {};
      }
      acc[key][o?.label ?? o[key]] = o[key];
      return acc;
    }, {});

  const nextSummaryData = Object.fromEntries(
    Object.entries(summaryData).map(([k, arr]) => {
      const nextArr = arr.map(v => {
        const { value, segmentation } = v;
        const nextValue = options?.[k]?.[value];
        let next = { ...v, value: nextValue };
        if (!!segmentationProp && segmentation !== undefined) {
          next.segmentation = options[segmentationProp][segmentation];
        }
        return next;
      });
      return [k, nextArr];
    })
  );

  return nextSummaryData;
};

export const formatDate = date =>
  date ? moment(date).format('MMM DD, YYYY') : '';

export const formatDateWithTime = date =>
  date ? moment(date).format('DD_MM_YY_HH_MM_SS') : '';

const ignoreElements = el => {
  if (el.className && typeof el.className === 'string') {
    return el.className.includes('Histogram-download-button');
  }
  return false;
};

export const toImageDataUrl = async (element, resolutionScale, scale) => {
  const height = resolutionScale > 5 ? resolutionScale - 2 : resolutionScale;
  const canvasWidth = element.offsetWidth * resolutionScale;
  const canvasHeight = element.offsetHeight * height;
  const canvasGraph = document.createElement('canvas');
  canvasGraph.width = canvasWidth;
  canvasGraph.height = canvasHeight;
  canvasGraph.style.width = `${element.offsetWidth}px`; // Set the visible size for scaling
  canvasGraph.style.height = `${element.offsetHeight}px`;
  const context = canvasGraph.getContext('2d');
  context.scale(1, 1);
  const canvas = await html2canvas(
    element,
    {
      canvas: canvasGraph,
      scale: scale,
    },
    { ignoreElements }
  );
  const img = canvas?.toDataURL('image/png');
  return img;
};

export const minZoom = 4;

export const capitalizeWord = str =>
  str?.length > 0
    ? str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)
    : str;

const typeVsDays = {
  [WDAY_TYPE.weekday]: 5,
  [WDAY_TYPE.saturday]: 1,
  [WDAY_TYPE.sunday]: 1,
};

const numDays = wdays =>
  _.reduce(wdays, (memo, wday) => memo + typeVsDays[wday], 0);

export const calculateAverageByDaysForTotalData = (
  wdayTypeData,
  resultData,
  key
) => {
  return d3.sum(resultData.map(avg => avg?.[key])) / numDays(wdayTypeData);
};

export const calculateAverageByDays = (wdayTypeData, resultData) => {
  return d3.sum(resultData) / numDays(wdayTypeData);
};

export const calculateAverageByDaysForFilterData = (
  wdayTypeData,
  resultData
) => {
  if (wdayTypeData?.length > 1) {
    return calculateAverageByDays(wdayTypeData, resultData);
  } else if (wdayTypeData.length === 1) {
    return d3.sum(resultData);
  }
};

const originalAttributeNameVsShapefileAttributeName = {
  daily_trips: 'dlyTrips',
  daily_pmt: 'dlyPMT',
  share_of_total_trips: 'ttlShare',
  share_of_bike_trips: 'bikeShare',
  share_of_walk_trips: 'walkShare',
  share_of_active_transportation_trips: 'actTrnspt',
  trip_density: 'density',
};

export const updateSpatialKeys = data => {
  return Object.keys(data).reduce((updatedData, key) => {
    updatedData[key] = Object.keys(data[key]).reduce((updatedObj, oldKey) => {
      const newKey =
        originalAttributeNameVsShapefileAttributeName[oldKey] || oldKey;
      updatedObj[newKey] = data[key][oldKey];
      return updatedObj;
    }, {});
    return updatedData;
  }, {});
};

export const mergeObjects = (obj1, obj2) => {
  const keys = _.union(_.keys(obj1), _.keys(obj2));
  const result = {};
  keys.forEach(key => {
    result[key] = _.union(obj1?.[key] || [], obj2?.[key] || []);
  });
  return result;
};

export const roundCoordinates = (coordinates, precision) => {
  return coordinates.map(coordinate => [
    parseFloat(coordinate[0].toFixed(precision)),
    parseFloat(coordinate[1].toFixed(precision)),
  ]);
};

export const isNotCustomZone = zoneType => {
  const zoneValues = _.values(ZONE_SYSTEM_TYPES);
  return _.includes(zoneValues, zoneType);
};

export const updateFilterValues = (initialFilters, updatedFilters) => {
  const filteredValues = {};
  Object.keys(initialFilters).forEach(key => {
    if (
      initialFilters[key]?.length !== updatedFilters[key]?.length ||
      key === 'wday_type' ||
      key === 'mode'
    ) {
      filteredValues[key] = initialFilters[key];
    }
  });

  return filteredValues;
};

export const dataKeys = {
  daily_trips: i18next.t('trips.dailyTrips'),
  daily_pmt: i18next.t('trips.personMilesTraveled'),
  share_of_total_trips: i18next.t('trips.shareTotalTrips'),
  share_of_bike_trips: i18next.t('trips.bikeModeShare'),
  share_of_walk_trips: i18next.t('trips.walkModeShare'),
  share_of_active_transportation_trips: i18next.t(
    'trips.activeTransportModeShare'
  ),
  trip_density: i18next.t('trips.tripDensity'),
};

export const truckDashboards = ['California', 'Maine'];
