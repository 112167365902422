import React, { useState } from 'react';
import PasswordConfirmationModal from './PasswordConfirmationModal';
import App from '../App';

const PasswordConfirmation = ({ dashboardName, viewName }) => {
  const [openModal, setOpenModal] = useState(true);
  const [showDashboard, setShowDashboard] = useState(false);

  return (
    <>
      {!showDashboard ? (
        <PasswordConfirmationModal
          dashboardName={dashboardName}
          setShowDashboard={() => {
            setShowDashboard(true);
            setOpenModal(false);
          }}
          // closeModal={setOpenModal(!openModal)}
          open={openModal}
        />
      ) : (
        <App
          clientName={'camsys'}
          dashboardId={'cece86dc-587d-4b85-be7b-3b5e212e94ea'}
          dashboardName={dashboardName}
          viewName={viewName}
          userId={'b8932ce3-276a-4d31-943e-fdc2599a09ea'}
          viewId={'87070c64-8e9c-4cc5-afdf-a823ec284f8e'}
          ableToSaveNewView={true} // Enable or disable save as button based on this prop
          ableToUpdateView={true} // Enable or disable Save button based on this prop
          shareView={() => false} // Trigger this function on click on shareView button
          // Token pulled from local storage on https://locus-dev.zessta.com/home for generic user mkhan@camsys.com with password Locus@123
          // I'm mostly sure this is a temporary token, so not sure why this is how Zessta suggested I get a token
          accessToken={
            'Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJIdi1fSHFBV0hfaXhlNnJLbTNXVTBaV2hsNTVXOWdyRTFyXzVWUndDTHhzIn0.eyJleHAiOjE3MDM5NTUxNTcsImlhdCI6MTcwMzk0MDc1NywiYXV0aF90aW1lIjoxNzAzOTQwNzU2LCJqdGkiOiJjMjlhN2UyYy0zMTQzLTRiMDYtOWI5ZS00ZjVkYTM2MjhmNjciLCJpc3MiOiJodHRwczovL2xvY3VzLWF1dGguemVzc3RhLmNvbS9yZWFsbXMvTG9jdXNfcmVhbG0iLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiZWYxNWNkOGQtOGVhZC00ZDUzLWFhN2ItNWM0YjNiZWJiZmVmIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoibG9jdXMtYXV0aCIsIm5vbmNlIjoiZTlhMzMxYjctMGYwNy00NWQzLTkxNDEtYTM4MDczNDI2Y2Q1Iiwic2Vzc2lvbl9zdGF0ZSI6Ijk4YjYxNDllLWEwNDctNDQ2OS1iMmY2LTdkMGEyNWI1NzRmNiIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiaHR0cHM6Ly9sb2N1cy1kZXYuemVzc3RhLmNvbSJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsiZGVmYXVsdC1yb2xlcy1sb2N1c19yZWFsbSIsIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwic2lkIjoiOThiNjE0OWUtYTA0Ny00NDY5LWIyZjYtN2QwYTI1YjU3NGY2IiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiSmFzd2FudGggTWF0YW0iLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJqYXN3YW50aC5tYXRhbUB6ZXNzdGEuY29tIiwiZ2l2ZW5fbmFtZSI6Ikphc3dhbnRoIiwiZmFtaWx5X25hbWUiOiJNYXRhbSIsImVtYWlsIjoiamFzd2FudGgubWF0YW1AemVzc3RhLmNvbSJ9.EOs079TKR8OhEtbR4XkUTaK-jxew8e4gQmhxurc6IpfpPGmdT6FmbqNw-po2n8W_2kvSEl2tLRg7Sg-TvF2vFK13fqMeLHbXa9mpNn_oWVTKBEkEJ69-8FnTyl0bDgL8vArsuOyf3B3t805ldvN04sLeXw6aIdpo2-G8iIPe2wCRxHTix2rOMi-0gVqWFnYjV_YD3VkifF_UVcJaGyfJ33_QRkTgqKAXInc0fYz6fMnJYq54OyGoSgoXtQeJ55_35CSCyLRKAZfN3aUKNZc-Eq8ugYRgtPLnCq0mrB9xS-n_NE5K3n8rJDzksnhmHgkj2rPIoyHjVYTZzSidnzqg7g'
          }
          odFlowsFeatureFlag={false}
          isTruckDashboard={true}
        />
      )}
    </>
  );
};

export default PasswordConfirmation;
