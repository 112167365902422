import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonSet from '../ButtonSet';
import './ChartsDisplayMenu.scss';
import { FILTERS_LABELS, FILTERS_LABELS_TRUCK } from '../../constants';

function ChartsDisplayMenu({
  data,
  filterValues,
  setSegmentation,
  dataSegmentation,
  isTruckDashboard,
}) {
  const { t } = useTranslation();
  const updateData = initialData => {
    let updatedValues = { ...initialData };
    delete updatedValues.data_type;
    return updatedValues;
  };

  const segmentOptions = useMemo(
    () =>
      Object.keys(updateData(data))
        .map(property => {
          if (isTruckDashboard) {
            const labelObj = FILTERS_LABELS_TRUCK.find(
              v => v.value === property
            );
            if (
              labelObj?.value !== 'industry' &&
              labelObj?.value !== 'vocation' &&
              labelObj?.value !== 'distance_mi' &&
              labelObj?.value !== 'stop_duration' &&
              labelObj?.value !== 'equity'
            ) {
              if (!labelObj) return { label: property, value: property };
              return { value: property, label: labelObj.label };
            }
          } else {
            const labelObj = FILTERS_LABELS.find(v => v.value === property);
            if (labelObj?.value !== 'wday_type') {
              const numValues = filterValues?.[property]?.length ?? 0;
              if (labelObj.removeHistogram || numValues > 5) return;
              if (!labelObj) return { label: property, value: property };
              return { value: property, label: labelObj.label };
            }
          }
        })
        .filter(Boolean)
        .concat([{ label: t('general.none'), value: null }]),
    [data]
  );

  return (
    <div className="ChartsDisplayMenu">
      <div className="menu-primary-label">{t('charts.segmentData')}</div>

      <div className="ChartsDisplayMenu-section">
        <ButtonSet
          selected={dataSegmentation}
          options={segmentOptions}
          onToggle={setSegmentation}
        />
      </div>
    </div>
  );
}

export default ChartsDisplayMenu;
