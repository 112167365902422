import React from 'react';
import { useTranslation } from 'react-i18next';
import VisualizationsContainer from './VisualizationsContainer';
import './Sidebar.scss';
import Loader from './Loader';
import { useLoadingStore } from '../store/loaders';
import { getHumanReadableNumber } from '../constants';
import classnames from 'classnames';

function Sidebar({
  totalTrips,
  totalPmt,
  summaryData,
  highlightedSummaryData,
  setSegmentation,
  dataSegmentation,
  filterValues,
  uiHidden,
  dataKey,
  isTruckDashboard,
  dashboardName,
}) {
  const { t } = useTranslation();
  const sidebarIsLoading = useLoadingStore(
    state =>
      state.isLoading.histograms.length ||
      state.isLoading.highlightedHistograms.length
  );

  const totalsIsLoading = useLoadingStore(
    state => state.isLoading.totals.length
  );

  return (
    <div className={classnames('Sidebar', { hidden: uiHidden })}>
      {totalTrips !== null || totalPmt !== null ? (
        <div className="Sidebar-stats-container drop-shadow">
          {!totalsIsLoading ? (
            <>
              {totalTrips !== null ? (
                <div className="Sidebar-total-trips">
                  <div className="Sidebar-total-trips-value">
                    {getHumanReadableNumber(totalTrips)}
                  </div>
                  <div className="Sidebar-total-trips-label">
                    Total Vehicle Trips
                  </div>
                </div>
              ) : null}
              {totalPmt !== null ? (
                <div className="Sidebar-total-trips">
                  <div className="Sidebar-total-trips-value">
                    {getHumanReadableNumber(totalPmt)}
                  </div>
                  <div className="Sidebar-total-trips-label">
                    {isTruckDashboard
                      ? t('sidebar.totalVMT')
                      : t('sidebar.totalPMT')}
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <Loader />
          )}
        </div>
      ) : null}
      <div className="Sidebar-visualizations-container drop-shadow">
        <div className="Sidebar-overflow-container ">
          <VisualizationsContainer
            isLoading={sidebarIsLoading}
            summaryData={summaryData}
            highlightedSummaryData={highlightedSummaryData}
            setSegmentation={setSegmentation}
            dataSegmentation={dataSegmentation}
            filterValues={filterValues}
            isTruckDashboard={isTruckDashboard}
            dashboardName={dashboardName}
          />
        </div>{' '}
      </div>
    </div>
  );
}

export default Sidebar;
