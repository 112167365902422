import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import './PasswordConfirmationModal.scss';
import { Modal } from 'react-responsive-modal';

const PasswordConfirmationModal = ({
  open,
  dashboardName,
  setShowDashboard,
}) => {
  const password =
    dashboardName === 'California' ? 'LocusCalifornia@123!' : 'LocusMaine@123!';
  const userName = 'truck-admin';
  const [username, setUsername] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleChange = event => {
    const value = event.target.value;
    setInputValue(value);
    setPasswordError('');
  };

  const handleInputChange = event => {
    const value = event.target.value;
    setUsername(value);
    setUsernameError('');
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (username === userName && inputValue === password) {
      setShowDashboard(true);
    }
    if (inputValue !== password) {
      setPasswordError('Please enter a valid password');
    }
    if (username !== userName) {
      setUsernameError('Please enter a valid username');
    }
  };
  return (
    <Modal
      open={open}
      center
      showCloseIcon={false}
      classNames={{
        modal: 'customModal',
      }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <div className="PasswordContainer">
        <form onSubmit={handleSubmit}>
          <div>
            <div className="PasswordContainer-header">
              Please enter username
            </div>
            <input
              className="PasswordContainer-input"
              type="text"
              id="input"
              value={username}
              onChange={handleInputChange}
            />
            {usernameError && (
              <div className="PasswordContainer-error" style={{ color: 'red' }}>
                {usernameError}
              </div>
            )}
            <div className="PasswordContainer-header">
              Please enter password
            </div>
            <input
              className="PasswordContainer-input"
              type="password"
              id="input"
              value={inputValue}
              onChange={handleChange}
            />
          </div>
          {passwordError && (
            <div className="PasswordContainer-error" style={{ color: 'red' }}>
              {passwordError}
            </div>
          )}
          <button type="submit" className="PasswordContainer-button">
            Submit
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default PasswordConfirmationModal;
