import './Map.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as turf from 'turf';
import booleanIntersects from '@turf/boolean-intersects';
import throttle from 'lodash.throttle';
import Color from 'color';
import {
  TerraDraw,
  TerraDrawPolygonMode,
  TerraDrawSelectMode,
  TerraDrawRectangleMode,
  TerraDrawMapboxGLAdapter,
  TerraDrawFreehandMode,
  TerraDrawRenderMode,
  TerraDrawPointMode,
} from 'terra-draw';
import { useTranslation } from 'react-i18next';
import {
  TIMEOUT_TIME,
  CHOROPLETH_BASIS_OPTIONS,
  ZONE_SYSTEM_TYPES,
  capitalizeWord,
  roundCoordinates,
  mergeObjects,
  isNotCustomZone,
} from '../constants';
import CustomLayers from './CustomLayers';
import EndpointsLayer from './EndpointsLayer';
import { useCustomLayersStore } from '../store/customLayers';
import { getPaletteBins } from '../color_palettes';
import { getHumanReadableNumber } from '../constants';
import { useTransitItineraryStore } from '../store/transit-itinerary';
import { useReportStore } from '../store/reportStore';
import { useStateCountyStore } from '../store/state-county';
import { getGeoSelectionIds } from '../connectors';

let renderer;
// Mapbox and MapLibre share a Map component since they are so similar and utilize the same methods
const importRenderer = async (mapRenderer, accessToken) => {
  if (mapRenderer === 'maplibre-gl') {
    await import('maplibre-gl/dist/maplibre-gl.css');
    renderer = await import('maplibre-gl');
    renderer = renderer.default;
  } else {
    await import('mapbox-gl/dist/mapbox-gl.css');
    renderer = await import('mapbox-gl');
    renderer = renderer.default;
    renderer.accessToken = accessToken;
  }
};

const getDrawModes = () => {
  return [
    new TerraDrawPolygonMode({
      snapping: true,
      allowSelfIntersections: false,
    }),
    new TerraDrawRectangleMode(),
    new TerraDrawFreehandMode(),
    new TerraDrawSelectMode(),
    new TerraDrawPointMode(),
    new TerraDrawRenderMode({
      modeName: 'arbitary',
      styles: {
        polygonFillColor: '#4357AD',
        polygonOutlineColor: '#48A9A6',
        polygonOutlineWidth: 2,
        pointWidth: 2,
        pointColor: '#4357AD',
        pointOutlineColor: '#48A9A6',
      },
    }),
  ];
};

function Map({
  mapId,
  url,
  renderLib,
  accessToken,
  data,
  choroplethPalette,
  choroplethType,
  scaleInputs,
  highlightedGeoids,
  onHighlightedGeoidsChange,
  highlightedLegendExtent,
  dataKey,
  mapStore,
  mapDrawStore,
  initialCenter,
  initialZoom,
  zoneType,
  //
  direction,
  promotedId,
  dataSource,
  dataSourceLayer,
  odFlowsFeatureFlag,
  boundingShape,
  activeDashboardInstance,
  isTruckDashboard,
}) {
  const { t } = useTranslation();
  const setHoveredItinerary = useTransitItineraryStore(
    state => state.setHovered
  );
  const hoveredItinerary = useTransitItineraryStore(state => state.hovered);

  const styleUrl = useRef(url);
  const popupLabel = useRef(
    CHOROPLETH_BASIS_OPTIONS.find(v => v.value === dataKey)
  );
  const popup = useRef(null);
  const map = useRef(null);
  const hoveredFeatureId = useRef(null);
  const stateCountyData = useStateCountyStore(state => state.stateCountyData);
  const [mapDrawFeatures, setMapDrawFeatures] = useState([]);
  const [mapLoaded, setMapLoaded] = useState(false);
  const augmentedFeatureIds = useRef(new Set());
  const blockGroupData = useRef(data);
  const palette = useRef(choroplethPalette);
  const scaleType = useRef(choroplethType);
  const inputs = useRef(scaleInputs);
  const activeCustomLayers = useCustomLayersStore(state => state.active);
  const setMapDraw = mapDrawStore(state => state.setMapDraw);
  const mapDrawMode = mapDrawStore(state => state.activeMode);
  const [selectedShapeId, setSelectedShapeId] = useState('');
  const updatedLayerShapeRef = useRef(null);
  const updatedSelectedGeoIdsRef = useRef(null);
  const setSelectedGeoIds = mapStore(state => state.setSelectedGeoIds);
  const selectedGeoIds = mapStore(state => state.selectedGeoIds);

  const setMapState = mapStore(state => state.setMapState);
  const setSelectedGeoid = mapStore(state => state.setSelectedGeoid);
  const setMapSelectionFeatures = mapStore(
    state => state.setMapSelectionFeatures
  );
  const selectedGeoid = mapStore(state => state.selectedGeoid);
  const setPdfReport = useReportStore(state => state.setPdfReport);
  const pdfReport = useReportStore(state => state.pdfReport);

  const mapState = mapStore(state => ({
    center: state.center,
    zoom: state.zoom,
    bearing: state.bearing,
    pitch: state.pitch,
    isUpdateByScroll: state.isUpdateByScroll,
    isMapUpdated: state.isMapUpdated,
  }));

  const setMapInstance = mapStore(state => state.setMapInstance);
  const setLayerShape = mapStore(state => state.setLayerShape);
  const layerShape = mapStore(state => state.layerShape);

  const setMapSource = useReportStore(state => state.setMapSource);

  // This is used to have the inputs and color outputs in state for the legend
  const setLegend = mapStore(state => state.setLegend);

  useEffect(() => {
    updatedLayerShapeRef.current = layerShape;
  }, [layerShape]);

  useEffect(() => {
    updatedSelectedGeoIdsRef.current = selectedGeoIds;
  }, [selectedGeoIds]);

  useEffect(() => {
    setSelectedShapeId('');
  }, [zoneType]);

  useEffect(() => {
    if (!mapDrawMode || mapDrawMode === 'select') return;
    if (selectedGeoid) {
      setSelectedGeoid(null);
    }
  }, [mapDrawMode, setSelectedGeoid, selectedGeoid]);

  // This is to make sure style is loaded before running dependent functions
  // Less than ideal but .on('styledata'...) isn't totally accurate
  const waitForStyleLoad = useCallback(fn => {
    if (!map.current || !map.current.isStyleLoaded()) {
      setTimeout(() => waitForStyleLoad(fn), TIMEOUT_TIME);
    } else {
      fn();
    }
  }, []);

  const waitForSourceLoad = useCallback((fn, id) => {
    if (
      !map.current ||
      // This feels redundant, but this is a quirk of Mapbox. isSourceLoaded is the relevant
      // flag, but returns an error. getSource protects us from the error.
      !map.current.getSource(id) ||
      !map.current.isSourceLoaded(id)
    ) {
      setTimeout(() => waitForSourceLoad(fn, id), TIMEOUT_TIME);
    } else {
      fn();
    }
  }, []);

  const setBlockGroupFeatureState = useCallback(() => {
    if (!map.current) {
      return;
    }

    const queriedFeatures = dataSourceLayer
      ? map.current.querySourceFeatures(zoneType, {
          sourceLayer: dataSourceLayer,
        })
      : map.current.querySourceFeatures(zoneType);

    const features = [
      ...queriedFeatures.filter(f => !augmentedFeatureIds.current.has(f.id)),
    ];

    if (features.length > 0) {
      for (const f of features) {
        augmentedFeatureIds.current.add(f.id);
        const dailyTrips = Number(blockGroupData.current?.[f.id] ?? 0);
        map.current.setFeatureState(
          {
            source: zoneType,
            ...(dataSourceLayer && { sourceLayer: dataSourceLayer }),
            id: f.id,
          },
          {
            // f.id here is same as GEOID in the tiles since we promoted that above
            dailyTrips,
          }
        );
        // Add to the feature directly so we can store this in spatial data state
        f.properties.dailyTrips = dailyTrips;
      }
    }
  }, [zoneType, dataSourceLayer]);

  // Reset feature state on data change
  useEffect(() => {
    augmentedFeatureIds.current = new Set();
    blockGroupData.current = data;
    waitForSourceLoad(setBlockGroupFeatureState, zoneType);
  }, [data, setBlockGroupFeatureState, waitForSourceLoad, zoneType]);

  const buildInterpolateExpression = useCallback(() => {
    const inputOutputs = getPaletteBins(
      inputs.current.length,
      palette.current
    ).reduce((acc, color, i) => {
      acc.push(inputs.current[i]);
      acc.push(color);
      return acc;
    }, []);

    setLegend({ scaleType: 'interpolate', inputOutputs });

    const value = [
      'case',
      [
        'any',
        ['==', ['feature-state', 'dailyTrips'], null],
        ['==', ['feature-state', 'dailyTrips'], 0],
      ],
      'hsla(0,0%,0%,0)',
      [
        'interpolate',
        ['linear'],
        ['feature-state', 'dailyTrips'],
        ...inputOutputs,
      ],
    ];

    return value;
  }, [setLegend]);

  // TODO update to pick colors from group of 5
  const buildStepExpression = useCallback(() => {
    let inputOutputs = getPaletteBins(
      inputs.current.length,
      palette.current
    ).reduce((acc, color, i) => {
      if (i !== 0) {
        acc.push(inputs.current[i]);
      }
      acc.push(color);
      return acc;
    }, []);

    if (inputs.current[0] !== 0) {
      const firstInput =
        inputs.current[0] < 0.001 ? inputs.current[0] / 2 : 0.001;

      inputOutputs = ['hsla(0, 0%, 100%, 0)', firstInput, ...inputOutputs];
    }

    setLegend({ scaleType: 'step', inputOutputs });

    const value = [
      'case',
      [
        'any',
        ['==', ['feature-state', 'dailyTrips'], null],
        ['==', ['feature-state', 'dailyTrips'], 0],
      ],
      'hsla(0,0%,0%,0)',
      ['step', ['feature-state', 'dailyTrips'], ...inputOutputs],
    ];

    return value;
  }, [setLegend]);

  const resetBlockGroupLayer = useCallback(() => {
    if (!map.current || !map.current.isStyleLoaded()) return;
    const expression =
      scaleType.current === 'interpolate'
        ? buildInterpolateExpression()
        : buildStepExpression();
    map.current.setPaintProperty(zoneType, 'fill-color', expression);

    // Add a layer for hover state
    let hoverExpression = getHoverLayerExpression(expression);
    map.current.setPaintProperty(
      `${zoneType}-hover`,
      'line-color',
      hoverExpression
    );
  }, [buildInterpolateExpression, buildStepExpression, zoneType]);

  useEffect(() => {
    palette.current = choroplethPalette;
    scaleType.current = choroplethType;
    inputs.current = scaleInputs;
    if (map.current) {
      waitForStyleLoad(resetBlockGroupLayer);
    }
  }, [
    choroplethPalette,
    choroplethType,
    resetBlockGroupLayer,
    scaleInputs,
    waitForStyleLoad,
  ]);

  useEffect(() => {
    if (!map.current) return;
    let expression = 0.8;
    if (highlightedLegendExtent) {
      const [min, max] = highlightedLegendExtent;
      expression = [
        'case',
        [
          'all',
          ['!=', ['feature-state', 'dailyTrips'], null],
          ['>=', ['feature-state', 'dailyTrips'], min],
          ['<', ['feature-state', 'dailyTrips'], max],
        ],
        0.8,
        0.1,
      ];
    }
    map.current.setPaintProperty(zoneType, 'fill-opacity', expression);
  }, [highlightedLegendExtent, zoneType]);

  const getFeaturesInDrawnFeatures = useCallback(
    mapDrawFeatures => {
      if (!map.current) return [];

      let featureHash = {};
      mapDrawFeatures?.forEach(feature => {
        // Do a rough first past on the drawn bbox
        const bbox = turf.bbox(feature);
        let sourceFeatures = map.current.queryRenderedFeatures(
          [
            map.current.project([bbox[0], bbox[1]]),
            map.current.project([bbox[2], bbox[3]]),
          ],
          { layers: [zoneType] }
        );

        // Actually intersect features
        sourceFeatures = sourceFeatures.filter(sourceFeature => {
          return booleanIntersects(feature, sourceFeature);
        });

        featureHash = {
          ...featureHash,
          ...Object.fromEntries(sourceFeatures.map(f => [f.id, f])),
        };
      });

      return Object.values(featureHash);
    },
    [zoneType]
  );

  const highlightBlockGroups = useCallback(
    geoids => {
      if (!map.current) return;

      let styleExpression = 1;

      if (geoids?.length) {
        styleExpression = [
          'case',
          ['in', ['get', promotedId], ['literal', geoids]],
          1,
          0.25,
        ];
      }

      map.current.setPaintProperty(zoneType, 'fill-opacity', styleExpression);

      setMapSource(map.current);

      pdfReport.mapDestination !== direction &&
        setPdfReport({
          ...pdfReport,
          mapDestination: direction,
        });
    },
    [promotedId, zoneType]
  );

  const updateMapDrawFeatures = useCallback(() => {
    const features = getFeaturesInDrawnFeatures(mapDrawFeatures);
    setMapSelectionFeatures(features);
  }, [mapDrawFeatures, getFeaturesInDrawnFeatures]);

  useEffect(() => {
    if (map.current) {
      waitForStyleLoad(updateMapDrawFeatures);
    }
  }, [updateMapDrawFeatures, mapDrawFeatures, waitForStyleLoad]);

  useEffect(() => {
    const currentSelectedFeature = mapDrawFeatures?.filter(
      item => item.id === selectedShapeId
    );

    if (mapDrawFeatures?.length === 0) {
      const features = getFeaturesInDrawnFeatures(mapDrawFeatures);
      onHighlightedGeoidsChange(features.map(({ id }) => id));
    }
    if (currentSelectedFeature?.length) {
      const features = getFeaturesInDrawnFeatures(currentSelectedFeature);
      const selectedZoneTypeGeoids = selectedGeoIds?.[zoneType]
        ? selectedGeoIds?.[zoneType]
        : [];
      const highlightedGeoIds = [
        ...selectedZoneTypeGeoids,
        ...features.map(({ id }) => id),
      ];
      onHighlightedGeoidsChange(highlightedGeoIds);
    }
  }, [
    mapDrawFeatures,
    onHighlightedGeoidsChange,
    getFeaturesInDrawnFeatures,
    selectedShapeId,
  ]);

  useEffect(() => {
    if (!(mapLoaded && map.current && map.current.getLayer(zoneType))) return;
    highlightBlockGroups(highlightedGeoids);
  }, [highlightedGeoids, mapLoaded, zoneType, highlightBlockGroups]);

  const getHoverLayerExpression = exp => {
    let updatedExpression = exp;
    updatedExpression[1].push(['!=', ['feature-state', 'hover'], true]);
    const recurseExp = arr => {
      if (!Array.isArray(arr)) {
        let potentialColor = arr;
        // Color treats numbers as acceptable colors
        if (typeof arr !== 'string') return arr;
        // This is a little hacky, but Color should reject non-color strings
        try {
          potentialColor = Color(potentialColor);
          potentialColor = potentialColor.darken(0.5);
          return potentialColor.hsl().string();
        } catch (e) {
          return arr;
        }
      }
      return arr.map(item => recurseExp(item));
    };

    updatedExpression = recurseExp(updatedExpression);

    return updatedExpression;
  };

  const addBlockGroupsLayer = useCallback(() => {
    if (!map.current) return;
    if (!map.current.getSource(zoneType)) {
      map.current.addSource(zoneType, dataSource);
    }
    if (!map.current.getLayer(zoneType)) {
      map.current.addLayer(
        {
          id: zoneType,
          type: 'fill',
          metadata: {},
          source: zoneType,
          ...(dataSourceLayer && { 'source-layer': dataSourceLayer }),
          layout: {},
          paint: {
            'fill-color': inputs.current
              ? buildStepExpression()
              : 'hsla(0,0%,0%,0)',
            'fill-opacity': 0.8,
            'fill-antialias': false,
          },
        },
        // Put it before the first road line layer.
        'aeroway-line'
      );

      // Add a layer for hover state
      let updatedExpression = getHoverLayerExpression(buildStepExpression());
      map.current.addLayer({
        id: `${zoneType}-hover`,
        type: 'line',
        metadata: {},
        source: zoneType,
        ...(dataSourceLayer && { 'source-layer': dataSourceLayer }),
        layout: {},
        paint: {
          'line-color': inputs.current ? updatedExpression : 'hsla(0,0%,0%,0)',
          'line-width': [
            'interpolate',
            ['exponential', 1.5],
            ['zoom'],
            5,
            0.75,
            18,
            32,
          ],
        },
      });
    }
  }, [buildStepExpression, zoneType, dataSource, dataSourceLayer]);

  const getTooltipData = (geoIdTextType, key, labelText, geoInfo) => {
    let data;
    let countyName;
    let stateName;
    if (isTruckDashboard) {
      if (key === 'countyGeoId') {
        data = stateCountyData?.[direction]?.find(
          item => item?.county?.geoId === geoInfo.id
        );
      } else {
        data = stateCountyData?.[direction]?.find(
          item => item?.geoId === geoInfo.id
        );
      }
      countyName = data?.county?.name;
      stateName = data?.county?.state?.name;
    } else {
      if (key === 'countyGeoId') {
        data = stateCountyData?.find(
          item => item?.county?.[key] === geoInfo.id
        );
      } else {
        data = stateCountyData?.find(item => item?.[key] === geoInfo.id);
      }
      countyName = data?.county?.countyName;
      stateName = data?.county?.state?.stateName;
    }
    const geoId = geoInfo.id;
    return `<div>
      <div><strong>${geoIdTextType}: #${geoId}</strong></div>
      <div><strong>County: ${countyName}</strong></div>
      <div><strong>State: ${stateName}</strong></div>
      ${labelText}
    </div>`;
  };

  const setPopupContent = useCallback((lnglat, html) => {
    popup.current.setLngLat(lnglat).setHTML(html).addTo(map.current);
  }, []);

  const removePopup = useCallback(() => {
    map.current.getCanvas().style.cursor = '';
    popup.current.remove();
  }, []);

  let hoveredEndpointId = useRef(null);
  let hoveredSourceId = useRef(null);
  let hoveredOriginDestinationValue = useRef(null);

  const setPopup = useCallback(
    (e, label) => {
      if (e.features[0].state.dailyTrips === 0) return;

      map.current.getCanvas().style.cursor = 'pointer';

      if (hoveredEndpointId.current !== null) {
        const displayValue = getHumanReadableNumber(
          hoveredOriginDestinationValue.current
        );
        const source = hoveredSourceId.current;
        const dest = hoveredEndpointId.current;
        const oppositeDirection =
          direction === 'origin' ? 'destination' : 'origin';
        const sourceStateCountyDetails = stateCountyData.find(
          item => item?.county?.countyGeoId === source
        );
        const destStateCountyDetails = stateCountyData.find(
          item => item?.county?.countyGeoId === dest
        );
        let htmlContents = [];
        if (zoneType === ZONE_SYSTEM_TYPES.county) {
          htmlContents = [
            `<div><b>${capitalizeWord(
              direction
            )} ${zoneType}: ${source}</b></div>`,
            `<div>${sourceStateCountyDetails?.county?.countyName}, ${sourceStateCountyDetails?.county?.state?.stateName}</div>`,
            `<div><b>${capitalizeWord(
              oppositeDirection
            )} ${zoneType}: ${dest}</b></div>`,
            `<div>${destStateCountyDetails?.county?.countyName}, ${destStateCountyDetails?.county?.state?.stateName}</div>`,
          ];
        } else {
          htmlContents = [
            `<div><b>${capitalizeWord(
              direction
            )} ${zoneType}: ${source}</b></div>`,
            `<div><b>${capitalizeWord(
              oppositeDirection
            )} ${zoneType}: ${dest}</b></div>`,
          ];
        }

        if (direction !== 'origin') {
          htmlContents = htmlContents.reverse();
        }

        const html = `<div>
          ${htmlContents.join('')}
          <div>${displayValue} ${t('palette.totalDailyTrips')}</div>
          </div>`;

        setPopupContent(e.lngLat, html);
      } else {
        if (!direction) return;
        const displayValue = popupLabel.current?.labelValueFn
          ? popupLabel.current?.labelValueFn(
              getHumanReadableNumber(e.features[0].state.dailyTrips)
            )
          : getHumanReadableNumber(e.features[0].state.dailyTrips);
        const labelText = `${displayValue} ${label}
        at ${capitalizeWord(direction)}`;
        const geoInfo = e.features[0];
        let html;
        if (geoInfo.layer.id === ZONE_SYSTEM_TYPES.censusTract) {
          html = getTooltipData(
            t('palette.censusTract'),
            'censusTractGeoId',
            labelText,
            geoInfo
          );
        } else if (geoInfo.layer.id === ZONE_SYSTEM_TYPES.blockGroup) {
          html = getTooltipData(
            t('palette.blockGroup'),
            'blockGroupGeoId',
            labelText,
            geoInfo
          );
        } else if (geoInfo.layer.id === ZONE_SYSTEM_TYPES.county) {
          html = getTooltipData('County', 'countyGeoId', labelText, geoInfo);
        } else {
          html = `<div>
            <div><strong>Zone #${e.features[0]?.properties?.['zoneName']}</strong>
            ${labelText}
            </div>`;
        }

        if (displayValue) {
          setPopupContent(e.lngLat, html);
        }
      }
    },
    [
      setPopupContent,
      direction,
      hoveredEndpointId,
      hoveredOriginDestinationValue,
      hoveredSourceId,
      promotedId,
      zoneType,
    ]
  );

  const setEndpointFeatureState = useCallback((id, hover) => {
    map.current.setFeatureState(
      { source: 'endpoint_lines', id: id },
      { hover: hover }
    );
    map.current.setFeatureState(
      { source: 'endpoint_dots', id: id },
      { hover: hover }
    );
  });
  const endpointMouseEnter = useCallback(e => {
    if (e.features.length > 0) {
      if (hoveredEndpointId.current !== null) {
        setEndpointFeatureState(hoveredEndpointId.current, false);
      }
      hoveredEndpointId.current = e.features[0].id;
      hoveredSourceId.current = e.features[0].properties.source_id;
      hoveredOriginDestinationValue.current =
        e.features[0].properties.daily_trips;
      setEndpointFeatureState(hoveredEndpointId.current, true);
    }
  });
  const endpointMouseLeave = useCallback(e => {
    if (hoveredEndpointId.current !== null) {
      setEndpointFeatureState(hoveredEndpointId.current, false);
    }
    hoveredEndpointId.current = null;
    hoveredSourceId.current = null;
    hoveredOriginDestinationValue.current = null;
  });
  useEffect(() => {
    if (!popup.current || !map.current) return;
    popupLabel.current = CHOROPLETH_BASIS_OPTIONS.find(
      v => v.value === dataKey
    );
    // Remove old popup
    map.current.off('mousemove', zoneType, e =>
      setPopup(e, popupLabel.current?.label)
    );
    map.current.off('mouseleave', zoneType, removePopup);
    // Set new popup
    map.current.on('mousemove', zoneType, e =>
      setPopup(e, popupLabel.current?.label)
    );
    map.current.on('mouseleave', zoneType, removePopup);
  }, [dataKey, direction, setPopup, removePopup, zoneType]);

  // Update style with extra layers on base style change
  useEffect(() => {
    if (!map.current || styleUrl.current === url) return;
    styleUrl.current = url;
    setMapLoaded(false);
    map.current.setStyle(styleUrl.current);
    augmentedFeatureIds.current = new Set();

    map.current.once('styledata', async () => {
      await waitForStyleLoad(addBlockGroupsLayer);
      await waitForSourceLoad(setBlockGroupFeatureState, zoneType);
    });
  }, [
    url,
    waitForStyleLoad,
    addBlockGroupsLayer,
    setBlockGroupFeatureState,
    waitForSourceLoad,
    zoneType,
  ]);

  const onSelectGeoIdOriginDestination = useCallback(
    e => {
      if (
        highlightedGeoids.length ||
        (mapDrawMode && mapDrawMode !== 'select') ||
        !odFlowsFeatureFlag
      )
        return;

      let testFeatures = map.current.queryRenderedFeatures(e.point);
      testFeatures = testFeatures.filter(f => f?.layer?.id === zoneType);
      if (!testFeatures.length) {
        setSelectedGeoid(null);
        return;
      }

      const nextGeoId = testFeatures?.[0]?.properties?.[promotedId];
      const dailyTrips = testFeatures?.[0]?.state?.dailyTrips ?? 0;

      if (selectedGeoid === nextGeoId || dailyTrips === 0) {
        setSelectedGeoid(null);
      } else {
        setSelectedGeoid(nextGeoId);
      }
    },
    [
      selectedGeoid,
      setSelectedGeoid,
      promotedId,
      zoneType,
      highlightedGeoids,
      mapDrawMode,
      odFlowsFeatureFlag,
    ]
  );

  // This is ONLY for transit hovering behavior between list of trips, map, and scatterplot
  const prevTransitHoverId = useRef(null);
  // Hover transit
  useEffect(() => {
    if (
      !map.current ||
      !!hoveredFeatureId.current ||
      Object.values(hoveredItinerary).every(v => !v)
    )
      return;

    if (prevTransitHoverId.current !== null) {
      map.current.setFeatureState(
        {
          source: zoneType,
          ...(dataSourceLayer && { sourceLayer: dataSourceLayer }),
          id: prevTransitHoverId.current,
        },
        { hover: false }
      );
    }

    const { origin_geomarket, destination_geomarket } = hoveredItinerary;

    const hoverTransitFeatureId =
      direction === 'origin' ? origin_geomarket : destination_geomarket;

    prevTransitHoverId.current = hoverTransitFeatureId;
    map.current.setFeatureState(
      {
        source: zoneType,
        ...(dataSourceLayer && { sourceLayer: dataSourceLayer }),
        id: hoverTransitFeatureId,
      },
      { hover: true }
    );
  }, [
    hoveredItinerary,
    direction,
    zoneType,
    dataSourceLayer,
    prevTransitHoverId,
  ]);

  // Unhover transit
  useEffect(() => {
    if (
      !map.current ||
      !!hoveredFeatureId.current ||
      Object.values(hoveredItinerary).every(v => !!v)
    )
      return;

    if (prevTransitHoverId.current !== null) {
      map.current.setFeatureState(
        {
          source: zoneType,
          ...(dataSourceLayer && { sourceLayer: dataSourceLayer }),
          id: prevTransitHoverId.current,
        },
        { hover: false }
      );
    }
    prevTransitHoverId.current = null;
  }, [hoveredItinerary, zoneType, dataSourceLayer]);

  const hoverFeature = featureId => {
    if (!map.current || !featureId) return;
    if (hoveredFeatureId.current !== null) {
      map.current.setFeatureState(
        {
          source: zoneType,
          ...(dataSourceLayer && { sourceLayer: dataSourceLayer }),
          id: hoveredFeatureId.current,
        },
        { hover: false }
      );
    }

    hoveredFeatureId.current = featureId;
    map.current.setFeatureState(
      {
        source: zoneType,
        ...(dataSourceLayer && { sourceLayer: dataSourceLayer }),
        id: featureId,
      },
      { hover: true }
    );
  };

  const unhoverFeature = () => {
    if (!map.current) return;

    if (hoveredFeatureId.current !== null) {
      map.current.setFeatureState(
        {
          source: zoneType,
          ...(dataSourceLayer && { sourceLayer: dataSourceLayer }),
          id: hoveredFeatureId.current,
        },
        { hover: false }
      );
    }
    hoveredFeatureId.current = null;
  };

  // Hacky solution I don't love, need an exact reference to remove and reinstate listener
  const onClickMapFn = useRef({
    fn: onSelectGeoIdOriginDestination,
  });

  useEffect(() => {
    if (!map.current) return;
    map.current.off('click', onClickMapFn.current?.fn);
    onClickMapFn.current = { fn: onSelectGeoIdOriginDestination };
    map.current.on('click', onClickMapFn.current?.fn);
  }, [onSelectGeoIdOriginDestination]);

  const getAllZoneSystemIds = () =>
    activeDashboardInstance?.zoneSystems?.map(item => item.id);

  const getPointSelectionGeoId = useCallback(
    pointSelectionFeature => {
      if (!map.current) return [];

      const bbox = turf.bbox(pointSelectionFeature);
      let sourceFeatures = map.current.queryRenderedFeatures(
        [
          map.current.project([bbox[0], bbox[1]]),
          map.current.project([bbox[2], bbox[3]]),
        ],
        { layers: [zoneType] }
      );
      sourceFeatures = sourceFeatures.filter(sourceFeature => {
        return booleanIntersects(pointSelectionFeature, sourceFeature);
      });

      const selectedGeoId = sourceFeatures[0]?.id;
      return selectedGeoId;
    },
    [zoneType, dataSourceLayer]
  );

  const getPointSelectionGeoIdShape = useCallback(
    async selectedGeoId => {
      const zoneSystemIds = getAllZoneSystemIds();
      let result = {};
      let zoneId = null;
      if (!isNotCustomZone(zoneType)) {
        activeDashboardInstance?.zoneSystems?.forEach(item => {
          item.name === zoneType && (zoneId = item.id);
        });
      }
      result = await getGeoSelectionIds(
        null,
        zoneSystemIds,
        'point',
        zoneId || zoneType,
        selectedGeoId
      );
      const selectedShape = result?.[zoneType][0]?.shape;

      const geoIds = { ...result };
      delete geoIds?.[zoneType];
      geoIds[zoneType] = [selectedGeoId];
      const updatedGeoIds = mergeObjects(
        updatedSelectedGeoIdsRef.current,
        geoIds
      );
      setSelectedGeoIds(updatedGeoIds);
      const selectedShapeGeoJson = {
        type: 'Feature',
        properties: {
          mode: 'polygon',
          selection: 'point',
        },
        geometry: {
          type: 'Polygon',
          coordinates: isNotCustomZone(zoneType)
            ? [roundCoordinates(selectedShape?.coordinates[0][0], 8)]
            : [roundCoordinates(selectedShape?.coordinates[0], 8)],
        },
      };

      return selectedShapeGeoJson;
    },
    [zoneType, selectedGeoIds, setSelectedGeoIds]
  );

  const onMount = useCallback(async () => {
    await importRenderer(renderLib, accessToken);
    const mapRenderer = renderer;
    if (map.current) return;

    map.current = new mapRenderer.Map({
      container: mapId,
      style: styleUrl.current,
      preserveDrawingBuffer: true,
      center: mapState?.center ?? initialCenter,
      zoom: mapState?.zoom ?? initialZoom ?? 6.5,
      minZoom: 6,
      bounds:
        boundingShape && !mapState.isMapUpdated
          ? [
              [boundingShape?.minX, boundingShape?.minY],
              [boundingShape?.maxX, boundingShape?.maxY],
            ]
          : '',
      fitBoundsOptions: { padding: 35 },
    });

    const scale = new mapRenderer.ScaleControl({
      maxWidth: 80,
      unit: 'imperial',
    });

    map.current.addControl(scale);

    setMapInstance(map.current);

    map.current.once('render', () => {
      const container = document.getElementById(mapId);
      if (container) {
        const resizeObserver = new ResizeObserver(() => {
          if (!map.current) return;
          map.current.resize({ resize: true });
        });
        resizeObserver.observe(container);
      }
    });

    map.current.on('styledata', () => {
      waitForStyleLoad(addBlockGroupsLayer);
      waitForStyleLoad(() => setMapLoaded(true));
    });

    map.current.on('click', onClickMapFn.current?.fn);

    map.current.on('style.load', async () => {
      const mapDrawInstance = new TerraDraw({
        adapter: new TerraDrawMapboxGLAdapter({
          map: map.current,
          coordinatePrecision: 9,
        }),
        modes: getDrawModes(),
      });

      mapDrawInstance.on(
        'change',
        throttle(
          () => {
            const snapshot = mapDrawInstance.getSnapshot();
            // TODO maybe there's a better event listener for this, but just need clear
            if (!snapshot.length) {
              setMapDrawFeatures(mapDrawInstance.getSnapshot());
            }
          },
          TIMEOUT_TIME,
          { trailing: false }
        )
      );

      mapDrawInstance.on('finish', async eventId => {
        let layerData = { ...updatedLayerShapeRef.current };
        const mapDrawDetails = mapDrawInstance.getSnapshot();
        if (!layerData?.default) {
          layerData.default = [];
        }

        const selectedNonPointMapDrawDetails = mapDrawDetails.find(
          item => item.id === eventId && item?.properties?.mode !== 'point'
        );
        selectedNonPointMapDrawDetails &&
          layerData.default.push(selectedNonPointMapDrawDetails);
        selectedNonPointMapDrawDetails?.id &&
          setSelectedShapeId(selectedNonPointMapDrawDetails?.id);
        setMapDrawFeatures(mapDrawDetails);
        const selectedPointMapDrawDetails = mapDrawDetails.find(
          item => item.id === eventId && item?.properties?.mode === 'point'
        );
        layerData.isPointSelected = false;
        if (selectedPointMapDrawDetails) {
          const selectedPointGeoId = getPointSelectionGeoId(
            selectedPointMapDrawDetails
          );
          setSelectedShapeId(selectedPointMapDrawDetails?.id);
          const selectedShape = await getPointSelectionGeoIdShape(
            selectedPointGeoId
          );
          layerData.isPointSelected = true;
          layerData.default.push(selectedShape);
        }
        setLayerShape(layerData);
      });

      mapDrawInstance.start();
      if (layerShape?.default) {
        mapDrawInstance?.addFeatures(layerShape?.default);
        setMapDrawFeatures(layerShape?.default);
      }

      setMapDraw(mapDrawInstance);
    });

    map.current.on(
      'move',
      throttle(setBlockGroupFeatureState, TIMEOUT_TIME, { trailing: false })
    );

    map.current.on('moveend', () => {
      setMapState({
        center: map.current.getCenter().toArray(),
        zoom: map.current.getZoom(),
        bearing: map.current.getBearing(),
        pitch: map.current.getPitch(),
        isUpdateByScroll: true,
        isMapUpdated: true,
      });
    });

    popup.current = new mapRenderer.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    // Set popup on hover
    map.current.on('mousemove', zoneType, e =>
      setPopup(e, popupLabel.current?.label)
    );
    map.current.on('mouseleave', zoneType, removePopup);

    // Set hovered feature
    map.current.on('mousemove', zoneType, e => {
      const featureId = e?.features?.[0]?.id;
      hoverFeature(featureId);
      const transitDirection =
        direction === 'origin' ? 'origin_geomarket' : 'destination_geomarket';
      const transitOppositeDirection =
        direction === 'origin' ? 'destination_geomarket' : 'origin_geomarket';
      setHoveredItinerary({
        [transitDirection]: featureId,
        [transitOppositeDirection]: null,
      });
    });
    map.current.on('mouseleave', zoneType, () => {
      unhoverFeature();
      setHoveredItinerary({
        origin_geomarket: null,
        destination_geomarket: null,
      });
    });

    map.current.on('mouseenter', 'endpoint_lines', endpointMouseEnter);
    map.current.on('mouseenter', 'endpoint_dots', endpointMouseEnter);

    map.current.on('mouseleave', 'endpoint_lines', endpointMouseLeave);
    map.current.on('mouseleave', 'endpoint_dots', endpointMouseLeave);
  }, [
    mapId,
    accessToken,
    addBlockGroupsLayer,
    setMapDraw,
    setMapState,
    setPopup,
    removePopup,
    renderLib,
    waitForStyleLoad,
    initialCenter,
    initialZoom,
    zoneType,
    setBlockGroupFeatureState,
    setMapInstance,
    setHoveredItinerary,
    direction,
  ]);

  useEffect(() => {
    if (!map.current) return;

    if (!mapState.isUpdateByScroll) {
      const numbersEqual = (a, b, precision) => {
        if (
          a === undefined ||
          b === undefined ||
          a === null ||
          b === null ||
          isNaN(a) ||
          isNaN(b)
        )
          return true;
        return +a.toFixed(precision) === +b.toFixed(precision);
      };

      if (
        mapState.zoom !== undefined &&
        !numbersEqual(mapState.zoom, map.current.getZoom(), 1)
      ) {
        map.current.setZoom(mapState.zoom);
      }

      if (
        mapState.bearing !== undefined &&
        !numbersEqual(mapState.bearing, map.current.getBearing(), 1)
      ) {
        map.current.rotateTo(mapState.bearing);
      }

      if (
        mapState?.pitch !== undefined &&
        !numbersEqual(mapState?.pitch, map.current.getPitch(), 1)
      ) {
        map.current.easeTo({
          pitch: mapState?.pitch,
          bearing: mapState.bearing,
        });
      }

      const currentCenter = map.current.getCenter().toArray();
      if (
        mapState.center &&
        mapState.center.length === 2 &&
        (!numbersEqual(mapState.center[0], currentCenter[0], 2) ||
          !numbersEqual(mapState.center[1], currentCenter[1], 2))
      ) {
        map.current.setCenter(mapState.center);
      }
    }
  }, [mapState]);

  const onUnmount = useCallback(() => {
    if (map.current) {
      map.current.remove();
      map.current = null;
    }
  }, [setMapState]);

  useEffect(() => {
    // On mount
    onMount();

    // On unmount
    return onUnmount;
  }, [onMount, onUnmount]);

  return (
    <div className="Map">
      <div className="Map-container">
        <div id={mapId} />
        <CustomLayers
          layers={activeCustomLayers}
          map={map.current}
          before="road-label-simple"
          setPopupContent={setPopupContent}
          clearPopup={removePopup}
          zoneType={zoneType}
        />
        <EndpointsLayer
          map={map.current}
          before="road-label-simple"
          mapStore={mapStore}
          zoneType={zoneType}
        />
      </div>
    </div>
  );
}

export default Map;
