import { create } from 'zustand';
import qs from 'qs';

const getInitialMapState = () => {
  const params = qs.parse(window.location.hash.slice(1));
  let nextState = {
    center: null,
    zoom: null,
    bearing: null,
    pitch: null,
    isUpdateByScroll: false,
    isMapUpdated: false,
  };
  if (params.destination) {
    if (
      params.destination.lat &&
      params.destination.lng &&
      params.destination.zoom
    ) {
      nextState.center = [+params.destination?.lng, +params.destination?.lat];
      nextState.zoom = +params.destination?.zoom;
      nextState.bearing = +params.destination?.bearing;
    }
  }
  return nextState;
};

export const useMapStore = create(set => ({
  ...getInitialMapState(),
  legend: {
    scaleType: null,
    inputOutputs: null,
  },
  spatialData: {
    type: 'FeatureCollection',
    features: [],
  },
  mapInstance: null,
  selectedGeoid: null,
  tripEndpointsData: {},
  layerShape: null,
  selectedGeoIds: null,
  setLayerShape: layerShape => set({ layerShape }),
  mapSelectionFeatures: null,
  maxValue: null,
  minValue: null,
  filteredDashboardData: null,
  filterRange: null,
  setMinValue: minValue => set({ minValue }),
  setMaxValue: maxValue => set({ maxValue }),
  setFilteredDashboardData: filteredDashboardData =>
    set({ filteredDashboardData }),
  setFilterRange: filterRange => set({ filterRange }),
  setMapSelectionFeatures: mapSelectionFeatures =>
    set({ mapSelectionFeatures }),
  setSelectedGeoIds: selectedGeoIds => set({ selectedGeoIds }),
  setLegend: ({ scaleType, inputOutputs }) =>
    set({ legend: { scaleType, inputOutputs } }),
  setMapState: ({
    center,
    zoom,
    bearing,
    pitch,
    isUpdateByScroll,
    isMapUpdated,
  }) => set({ center, zoom, bearing, pitch, isUpdateByScroll, isMapUpdated }),
  setMapInstance: map => set({ mapInstance: map }),
  setSelectedGeoid: selectedGeoid => set({ selectedGeoid }),
  setTripEndpointsData: tripEndpointsData => set({ tripEndpointsData }),
}));
