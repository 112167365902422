import React, { useMemo, useState, useCallback, useEffect } from 'react';
import Color from 'color';
import * as d3 from 'd3';
import _ from 'lodash';
import classnames from 'classnames';
import { useLoadingStore } from '../store/loaders';
import Loader from './Loader';
import AscendingDescendingIcon from './AscendingDescendingIcon';
import { ReactComponent as VerticalEllipsis } from '../images/vertical_ellipsis.svg';
import { useTransitItineraryStore } from '../store/transit-itinerary';
import { evaluateExpression } from '../evaluate-expression';
import './TripsDisplay.scss';

function TripsDisplay({ data }) {
  const setHoveredItinerary = useTransitItineraryStore(
    state => state.setHovered
  );
  const hoveredItinerary = useTransitItineraryStore(state => state.hovered);
  const [locallyHovered, setLocallyHovered] = useState(null);

  const [tripsData, setTripsData] = useState([]);

  const isLoading = useLoadingStore(
    state => !!state.isLoading.tripsPanel.length
  );

  const [columns, setColumns] = useState([
    {
      label: 'OD pair name',
      key: 'itineraryLabel',
      active: null,
    },
    { label: 'Total trips', key: 'total_trips', active: null, sort: 'numeric' },
    {
      label: 'Transit trips',
      key: 'total_transit_trips',
      active: null,
    },
    {
      label: 'Transit share',
      key: 'transitShare',
      active: null,
      isPercent: true,
    },
    {
      label: 'Competitiveness',
      key: 'travel_time_ratio',
      active: null,
    },
  ]);

  const updateColumns = useCallback(
    (columnKey, value) => {
      const nextColumns = columns.map(c => {
        if (c.key !== columnKey) {
          return { ...c, active: null };
        }
        return { ...c, active: value };
      });
      setColumns(nextColumns);
    },
    [columns]
  );

  const sortData = useCallback(
    (key, sortDirection) => {
      if (!data) return [];

      let reverse = false;

      if (sortDirection) {
        reverse = sortDirection === 'descending';
      }

      let nextData = data.map(item => {
        const {
          origin_geomarket,
          destination_geomarket,
          total_trips,
          total_transit_trips,
          total_travel_time_ratio,
        } = item;
        return {
          origin_geomarket,
          destination_geomarket,
          itineraryLabel: `${origin_geomarket} >> ${destination_geomarket}`,
          transitShare: Number(total_transit_trips / total_trips),
          total_trips: Math.round(Number(total_trips)),
          total_transit_trips: Math.round(Number(total_transit_trips)),
          travel_time_ratio: Number(
            (Number(total_travel_time_ratio) / Number(total_trips)).toFixed(1)
          ),
        };
      });

      let sortKeys = ['itineraryLabel'];

      if (key) {
        sortKeys = [key, ...sortKeys];
      }

      nextData = _.sortBy(nextData, sortKeys);

      if (reverse) {
        nextData = nextData.reverse();
      }
      return nextData;
    },
    [data]
  );

  const scaler = useMemo(() => {
    if (!tripsData || !tripsData.length) return null;
    return Object.entries(tripsData[0]).reduce((acc, [k, v]) => {
      if (isNaN(Number(v))) return acc;

      const scale = d3
        .scaleLinear()
        .domain([
          d3.min(tripsData.map(d => d[k])),
          d3.max(tripsData.map(d => d[k])),
        ])
        .range([0, 1]);

      const fn = num => {
        const expression = [
          'interpolate',
          ['linear'],
          ['get', 'color-scale'],
          0,
          '#D4E6F3',
          1,
          '#255277',
        ];
        const value = scale(num);

        const args = {
          layerType: 'fill',
          propertyType: 'paint',
          propertyId: 'fill-color',
          properties: { 'color-scale': value },
          value: expression,
        };

        const backgroundColor = evaluateExpression(args);

        const fontColor = Color(backgroundColor).isDark() ? 'white' : '#3f4d55';

        return { backgroundColor: backgroundColor, color: fontColor };
      };

      acc[k] = fn;
      return acc;
    }, {});
  }, [tripsData]);

  useEffect(() => {
    const activeColumn = columns.find(c => !!c.active) ?? {};
    const { key, active: sortDirection } = activeColumn;
    const nextData = sortData(key, sortDirection);
    setTripsData(nextData);
  }, [columns, sortData]);

  const classify = useCallback(id => {
    if (!id || typeof id !== 'string') return id;
    return id.replaceAll(' ', '').toLowerCase();
  }, []);

  useEffect(() => {
    if (locallyHovered) return;
    const { origin_geomarket, destination_geomarket } = hoveredItinerary;
    let el;
    if (origin_geomarket && destination_geomarket) {
      el = document.getElementById(
        `list-item-${classify(origin_geomarket)}-${classify(
          destination_geomarket
        )}`
      );
    } else if (!destination_geomarket) {
      el = document.getElementsByClassName(
        `origin-${classify(origin_geomarket)}`
      )[0];
    } else if (!origin_geomarket) {
      el = document.getElementsByClassName(
        `destination-${classify(destination_geomarket)}`
      )[0];
    }

    if (el) {
      el.scrollIntoView({ block: 'center' });
    }
  }, [hoveredItinerary, locallyHovered, classify]);

  const isHovered = useCallback(
    item => {
      if (Object.values(hoveredItinerary).every(v => !v)) return false;

      if (Object.values(hoveredItinerary).every(v => !!v)) {
        return (
          item?.origin_geomarket === hoveredItinerary?.origin_geomarket &&
          item?.destination_geomarket ===
            hoveredItinerary?.destination_geomarket
        );
      }

      return (
        item?.origin_geomarket === hoveredItinerary?.origin_geomarket ||
        item?.destination_geomarket === hoveredItinerary?.destination_geomarket
      );
    },
    [hoveredItinerary]
  );

  // Similar to getHumanReadableNumber but without words
  // for millions, billions, etc
  const commify = num => {
    if (isNaN(num)) return num;
    if (num < 100) {
      return num;
    }
    const rounded = `${Math.round(Number(num))}`;
    let split = rounded.split('');
    split = split.reverse().reduce((acc, d, i) => {
      if (i % 3 === 0) {
        acc.push([d]);
      } else {
        acc[acc.length - 1].push(d);
      }
      return acc;
    }, []);
    split = split.reverse().map(v => v.reverse().join(''));
    let str;

    str = split.join(',');

    return str;
  };

  return (
    <div className="TripsDisplay">
      {isLoading || !data ? (
        <Loader />
      ) : (
        <>
          <div className="TripsDisplay-header">
            All OD (Origin - Destination) Pairs
            <VerticalEllipsis />
          </div>

          <div
            className="TripsDisplay-list"
            onMouseEnter={() => {
              setLocallyHovered(true);
            }}
            onMouseLeave={() => {
              setLocallyHovered(false);
              setHoveredItinerary({
                origin_geomarket: null,
                destination_geomarket: null,
              });
            }}
          >
            {columns.map((column, i) => (
              <div key={i} className="TripsDisplay-list-column">
                <div className="TripsDisplay-list-column-header">
                  {column.label}
                  <AscendingDescendingIcon
                    active={column?.active}
                    setActive={v => updateColumns(column.key, v)}
                  />
                </div>
                <div className="TripsDisplay-list-container">
                  {tripsData.map((item, i) => (
                    <div
                      key={i}
                      style={
                        scaler?.[column.key]
                          ? {
                              ...scaler[column.key](item[column.key]),
                            }
                          : {}
                      }
                      id={`list-item-${classify(
                        item?.origin_geomarket
                      )}-${classify(item?.destination_geomarket)}`}
                      className={classnames(
                        `TripsDisplay-list-item origin-${classify(
                          item?.origin_geomarket
                        )} destination-${classify(
                          item?.destination_geomarket
                        )}`,
                        {
                          hovered: isHovered(item),
                          unhovered:
                            Object.values(hoveredItinerary).some(v => !!v) &&
                            !isHovered(item),
                        }
                      )}
                      onMouseEnter={() => {
                        setHoveredItinerary({
                          origin_geomarket: item?.origin_geomarket,
                          destination_geomarket: item?.destination_geomarket,
                        });
                      }}
                      title={item[column.key]}
                    >
                      {column?.isPercent
                        ? `${(item[column.key] * 100).toFixed(2)}%`
                        : commify(item[column.key])}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default TripsDisplay;
