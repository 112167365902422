import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './Header.scss';
import { ReactComponent as LocusLogo } from '../images/Truck_white.svg';
import { ReactComponent as Hamburger } from '../images/hamburger.svg';
import { ReactComponent as DownloadHeader } from '../images/download-header.svg';
import { ReactComponent as Minimize } from '../images/minimize.svg';
import { ReactComponent as Maximize } from '../images/maximize.svg';
import { ReactComponent as Share } from '../images/share.svg';
import { ReactComponent as Reset } from '../images/reset.svg';

import Popover from './Popover';
import MenuContainer from './MenuContainer';
import DownloadMenu from './menus/DownloadMenu';
import SaveNewView from './SaveNewView';

function Header({
  activeDashboardInstance,
  viewName,
  projectName,
  dimensions,
  originData,
  destinationData,
  filterValues,
  summaryData,
  highlightedSummaryData,
  dataSegmentation,
  ableToSaveNewView,
  ableToUpdateView,
  updateView,
  saveNewView,
  shareView,
  isSaveNewView,
  handleCancel,
  onSaveAsButtonClick,
  setUiHidden,
  uiHidden,
  clientLogo,
  dashboardType,
  dataFilters,
  zoneType,
  direction,
  highlightedGeoidsOrigin,
  highlightedGeoidsDestination,
  enablePDFReport,
  dashboardName,
  isTruckDashboard,
}) {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(null);
  const downloadButtonRef = useRef(null);

  return (
    <div className="Header">
      <div className="Header-left-side-container">
        <LocusLogo />
        <div className="Header-names-container">
          <div className="Header-project-name" title="Project Name">
            {projectName}
          </div>
          <div className="Header-view-name" title="View Name">
            {viewName}
          </div>
        </div>
      </div>
      {isSaveNewView && (
        <SaveNewView
          closeCustomModal={handleCancel}
          saveNewView={value => saveNewView(value)}
        />
      )}
      <div className="Header-button-container">
        {/* {app ? (
          <Pdf
            targetRef={downloadRef}
            filename={`${viewName}.pdf`}
            options={{
              orientation: 'landscape',
              unit: 'mm',
              format: [pxToMm(app.clientHeight), pxToMm(app.clientWidth)],
            }}
          >
            {({ toPdf }) => (
              <div className="Header-button" onClick={toPdf}>
                Download
              </div>
            )}
          </Pdf>
        ) : null} */}
        <div
          ref={downloadButtonRef}
          className="Header-icon-button"
          title={t('header.download')}
          onClick={() =>
            setOpenMenu(v => (v !== 'download' ? 'download' : null))
          }
        >
          <DownloadHeader />
        </div>
        {/* {ableToUpdateView && (
          <div
            className="Header-icon-button"
            onClick={shareView}
            title={t('header.share')}
          >
            <Share />
          </div>
        )} */}
        <div
          className="Header-icon-button"
          title={uiHidden ? t('header.exitMapMode') : t('header.mapMode')}
          onClick={() => setUiHidden(!uiHidden)}
        >
          {uiHidden ? <Minimize /> : <Maximize />}
        </div>
        {/* {ableToUpdateView ? (
          <div className="Header-button" onClick={updateView}>
            {t('general.save')}
          </div>
        ) : null}
        {ableToSaveNewView ? (
          <div className="Header-button" onClick={onSaveAsButtonClick}>
            {t('general.saveAs')}
          </div>
        ) : null} */}
      </div>

      <Popover
        parentRef={downloadButtonRef.current}
        isOpen={openMenu === 'download'}
      >
        <MenuContainer
          closeMenu={() => setOpenMenu(null)}
          dark={true}
          downloadMenu={true}
        >
          <DownloadMenu
            originData={originData}
            destinationData={destinationData}
            dimensions={dimensions}
            activeDashboardInstance={activeDashboardInstance}
            filterValues={filterValues}
            summaryData={summaryData}
            dataSegmentation={dataSegmentation}
            highlightedSummaryData={highlightedSummaryData}
            clientLogo={clientLogo}
            dashboardType={dashboardType}
            dataFilters={dataFilters}
            zoneType={zoneType}
            direction={direction}
            highlightedGeoidsOrigin={highlightedGeoidsOrigin}
            highlightedGeoidsDestination={highlightedGeoidsDestination}
            enablePDFReport={enablePDFReport}
            dashboardName={dashboardName}
            isTruckDashboard={isTruckDashboard}
            viewName={viewName}
          />
        </MenuContainer>
      </Popover>
    </div>
  );
}

export default Header;
