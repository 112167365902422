import React from 'react';
import './GroupButtonSet.scss';

export const GroupButtonSet = ({ options, selected, onChange }) => {
  return (
    options && (
      <div className="GroupContainer">
        <div className="GroupSet">
          {options?.map((item, ind) => (
            <p
              key={ind}
              className={item === selected && 'GroupSetSelected'}
              onClick={() => onChange(item)}
            >
              {item}
            </p>
          ))}
        </div>
      </div>
    )
  );
};
