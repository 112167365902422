import { create } from 'zustand';

export const useTransitItineraryStore = create(set => ({
  hovered: {
    origin_geomarket: null,
    destination_geomarket: null,
  },
  setHovered: value =>
    set(state => {
      return { hovered: value };
    }),
}));
