import React, { useCallback, useEffect, useRef } from 'react';
import _ from 'lodash';
import { featureCollection, lineString, point } from '@turf/helpers';
import { MAPBOX_TILESETS, TIMEOUT_TIME } from '../constants';

const innerColor = '#fdff6b'; // yellow
const innerColorHighlight = '#ffbc6b'; // orange
const outerColor = '#333333';
const transitionMinzoom = 6;
const transitionMaxzoom = 14;
const transitionMinsize = 5;
const transitionMaxsize = 40;

const getIds = tripEndpointsData => {
  return tripEndpointsData?.endpoints?.map(d => d.geoid.toString());
};

const getLines = tripEndpointsData => {
  if (tripEndpointsData?.selected?.point?.coordinates === undefined) {
    console.log('selected point coordinates not found');
    return featureCollection([]);
  }
  let trips_array = tripEndpointsData.endpoints?.map(d => +d.daily_trips) || [
    0,
  ];
  let max_trips = Math.max(...trips_array);
  return featureCollection(
    tripEndpointsData.endpoints
      ?.filter(d => !!d.point)
      ?.filter(d => !!d.point.coordinates)
      ?.sort(d => d.daily_trips) // so smallest trip is drawn on top
      ?.map(d =>
        lineString(
          [tripEndpointsData.selected.point.coordinates, d.point.coordinates] ??
            [],
          {
            id: d.geoid,
            source_id: tripEndpointsData.selected.geoid,
            daily_pmt: d.daily_pmt,
            daily_trips: d.daily_trips,
            pct_trips: d.daily_trips / max_trips,
          }
        )
      )
  );
};

const getPoints = tripEndpointsData => {
  let trips_array = tripEndpointsData.endpoints?.map(d => +d.daily_trips) || [
    0,
  ];
  let max_trips = Math.max(...trips_array);
  return featureCollection(
    tripEndpointsData.endpoints
      ?.filter(d => !!d.point)
      ?.filter(d => !!d.point.coordinates)
      ?.sort(d => d.daily_trips) // so smallest trip is drawn on top
      ?.map(d =>
        point(d.point.coordinates ?? [], {
          id: d.geoid,
          source_id: tripEndpointsData.selected.geoid,
          daily_pmt: d.daily_pmt,
          daily_trips: d.daily_trips,
          pct_trips: d.daily_trips / max_trips,
        })
      )
  );
};

function EndpointsLayer({ before, map, mapStore, zoneType }) {
  const tripEndpointsData = mapStore(state => state.tripEndpointsData);
  const layerData = useRef({});
  const currentZoneType = useRef(zoneType);

  const addLayer = useCallback(
    data => {
      if (!map || !map.getSource(zoneType)) return;

      const sourceInfo = MAPBOX_TILESETS?.[zoneType];

      if (map.getSource('endpoint_lines')) {
        map.getSource('endpoint_lines').setData(data.lines);
      } else {
        map.addSource('endpoint_lines', {
          type: 'geojson',
          data: data.lines,
          promoteId: 'id',
        });
      }

      if (map.getSource('endpoint_dots')) {
        map.getSource('endpoint_dots').setData(data.points);
      } else {
        map.addSource('endpoint_dots', {
          type: 'geojson',
          data: data.points,
          promoteId: 'id',
        });
      }

      if (!map.getLayer('endpoint_polygons_outline')) {
        map.addLayer(
          {
            id: 'endpoint_polygons_outline',
            source: zoneType,
            ...(sourceInfo?.layerName && {
              'source-layer': sourceInfo?.layerName,
            }),
            filter: ['in', 'GEOID'], // empty list: exclude everything at first
            type: 'line',
            layout: {
              'line-cap': 'round',
            },
            paint: {
              'line-width': 4,
              'line-opacity': 0.5,
              'line-color': outerColor,
            },
          },
          before
        );
      }
      if (!map.getLayer('endpoint_polygons')) {
        map.addLayer(
          {
            id: 'endpoint_polygons',
            source: zoneType,
            ...(sourceInfo?.layerName && {
              'source-layer': sourceInfo?.layerName,
            }),
            filter: ['in', 'GEOID'], // empty list: exclude everything at first
            type: 'line',
            layout: {
              'line-cap': 'round',
            },
            paint: {
              'line-width': 2,
              'line-color': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                innerColorHighlight,
                innerColor,
              ],
            },
          },
          before
        );
      }
      if (
        data.ids &&
        map.getLayer('endpoint_polygons') &&
        map.getLayer('endpoint_polygons_outline')
      ) {
        map.setFilter('endpoint_polygons', ['in', 'GEOID', ...data.ids]);
        map.setFilter('endpoint_polygons_outline', [
          'in',
          'GEOID',
          ...data.ids,
        ]);
      }
      if (!map.getLayer('endpoint_dots_outline')) {
        map.addLayer(
          {
            id: 'endpoint_dots_outline',
            source: 'endpoint_dots',
            type: 'circle',
            paint: {
              'circle-radius': [
                'interpolate',
                ['linear'],
                ['zoom'],
                transitionMinzoom,
                [
                  'interpolate',
                  ['linear'],
                  ['get', 'pct_trips'],
                  0,
                  2.5,
                  1,
                  transitionMinsize + 1.5,
                ],
                transitionMaxzoom,
                [
                  'interpolate',
                  ['linear'],
                  ['get', 'pct_trips'],
                  0,
                  5.5,
                  1,
                  transitionMaxsize + 1.5,
                ],
              ],
              'circle-color': outerColor,
            },
          },
          before
        );
      }
      if (!map.getLayer('endpoint_lines_outline')) {
        map.addLayer(
          {
            id: 'endpoint_lines_outline',
            source: 'endpoint_lines',
            type: 'line',
            layout: {
              'line-cap': 'round',
            },
            paint: {
              'line-gap-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                transitionMinzoom,
                [
                  'interpolate',
                  ['linear'],
                  ['get', 'pct_trips'],
                  0,
                  0,
                  1,
                  transitionMinsize,
                ],
                transitionMaxzoom,
                [
                  'interpolate',
                  ['linear'],
                  ['get', 'pct_trips'],
                  0,
                  0,
                  1,
                  transitionMaxsize,
                ],
              ],
              'line-width': 1.5,
              'line-color': outerColor,
            },
          },
          before
        );
      }
      if (!map.getLayer('endpoint_lines')) {
        map.addLayer(
          {
            id: 'endpoint_lines',
            source: 'endpoint_lines',
            type: 'line',
            layout: {
              'line-cap': 'round',
            },
            paint: {
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                transitionMinzoom,
                [
                  'interpolate',
                  ['linear'],
                  ['get', 'pct_trips'],
                  0,
                  0,
                  1,
                  transitionMinsize,
                ],
                transitionMaxzoom,
                [
                  'interpolate',
                  ['linear'],
                  ['get', 'pct_trips'],
                  0,
                  0,
                  1,
                  transitionMaxsize,
                ],
              ],
              'line-color': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                innerColorHighlight,
                innerColor,
              ],
            },
          },
          before
        );
      }
      if (!map.getLayer('endpoint_dots')) {
        map.addLayer(
          {
            id: 'endpoint_dots',
            source: 'endpoint_dots',
            type: 'circle',
            paint: {
              'circle-radius': [
                'interpolate',
                ['linear'],
                ['zoom'],
                transitionMinzoom,
                [
                  'interpolate',
                  ['linear'],
                  ['get', 'pct_trips'],
                  0,
                  1,
                  1,
                  transitionMinsize,
                ],
                transitionMaxzoom,
                [
                  'interpolate',
                  ['linear'],
                  ['get', 'pct_trips'],
                  0,
                  4,
                  1,
                  transitionMaxsize,
                ],
              ],
              'circle-color': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                innerColorHighlight,
                innerColor,
              ],
            },
          },
          before
        );
      }
    },
    [before, map, zoneType]
  );

  const removeEndpointLayers = useCallback(() => {
    if (!map || !map.isStyleLoaded()) {
      setTimeout(removeEndpointLayers, TIMEOUT_TIME);
      return;
    }
    const endpointLayers = map
      .getStyle()
      .layers.filter(l => l.id.includes('endpoint_'))
      .map(l => l.id);

    for (const layer of endpointLayers) {
      map.removeLayer(layer);
    }
  }, [map]);

  useEffect(() => {
    if (_.isEmpty(tripEndpointsData) || currentZoneType.current !== zoneType) {
      currentZoneType.current = zoneType;
      removeEndpointLayers();
      return;
    }

    if (!map) return;
    layerData.current.lines = getLines(tripEndpointsData);
    layerData.current.points = getPoints(tripEndpointsData);
    layerData.current.ids = getIds(tripEndpointsData);

    if (map && map.isStyleLoaded()) {
      addLayer(layerData.current);
    }
  }, [tripEndpointsData, map, removeEndpointLayers, addLayer, zoneType]);

  useEffect(() => {
    if (!map || _.isEmpty(layerData.current)) return;
    map.once('style.load', () => addLayer(layerData.current));
  }, [map, addLayer]);

  return null;
}

export default EndpointsLayer;
