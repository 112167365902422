const dashboards = {
  'e73a817c-2288-4f1d-99fc-675cb5d47cc7': {
    name: 'Ohio Counties',
    viewName: 'Travel to Franklin County',
    type: 'Passenger Flows',
    table: 'hive_metastore.passengerohio.flow',
    backend: 'database',
    mapBounds: [-85.259399, 38.151837, -80.101318, 42.147114],
    mapCenter: [-82.694092, 39.989747],
    mapZoom: 6.5,
    customLayers: [
      {
        id: '7da321d6-1ac3-4ab3-a138-8c7000d153d8',
        name: 'COTA Route Lines',
        key: 'routeLines',
        tooltip: 'label',
        type: 'line',
        paint: {
          'line-color': '#e6c347',
          'line-opacity': 0.9,
          'line-width': 3.5,
        },
      },
      {
        id: 'a07c1c6e-3bb4-4d5c-a814-cc1aa064c5b7',
        name: 'COTA Route Stops',
        key: 'routeStops',
        tooltip: 'label',
        type: 'circle',
        paint: {
          'circle-color': '#e6c347',
          'circle-opacity': 0.8,
          'circle-radius': 2.5,
          'circle-stroke-color': '#a98d28',
          'circle-stroke-width': 1,
        },
      },
      {
        id: '4d7a520b-fe17-4aca-84d1-fd879cf2a0b1',
        name: 'COTA Plus Zones',
        key: 'areas',
        tooltip: 'label',
        type: 'fill',
        paint: {
          'fill-color': '#e6c347',
          'fill-opacity': 0,
          'fill-outline-color': 'transparent',
          'line-color': '#e6c347',
          'line-opacity': 0.9,
          'line-width': 3.5,
        },
      },
    ],
    customZones: [
      {
        name: 'Custom zone A',
        blockGroups: [
          '391279663005',
          '391279659001',
          '391279658001',
          '390739653001',
          '390739653004',
          '390739649001',
          '390739649002',
          '390739649003',
          '390450312003',
          '391279663002',
          '391279663001',
          '390450311004',
          '390450311002',
          '391279659002',
          '391279659003',
          '391279659004',
          '391279658002',
          '391279658003',
          '391279658004',
          '390450311003',
          '390450312002',
          '390450310003',
          '390450312001',
          '390450312004',
          '390450323004',
          '390450322001',
          '390450323003',
          '390450313001',
          '390450313002',
          '390450314002',
          '390450314004',
          '390450321001',
          '390450321002',
          '390450321003',
          '390450314003',
          '390450320004',
          '390450320001',
          '390450317005',
          '390450317001',
          '390450314001',
          '390450315001',
          '390450311001',
          '390450302002',
          '390450301002',
          '390450310001',
          '390450310002',
          '390450302001',
          '390450301003',
          '390450303003',
          '390450325004',
          '390450326004',
          '390450326002',
          '391290211005',
          '391290211004',
          '391290203102',
          '391290204002',
          '391290202001',
          '391290202002',
          '391290204001',
          '391290201001',
          '391290203103',
          '391290203104',
          '391290203101',
          '391290203202',
          '391290203201',
          '390450325003',
          '390450325002',
          '390450325001',
          '390450317002',
          '390450316003',
          '390450315002',
          '390450316002',
          '390450316001',
          '390450309005',
          '390450309004',
          '390450326003',
          '390450326001',
          '390450308002',
          '390450309003',
          '390450309002',
          '390450303001',
          '390450304003',
          '390450304001',
          '390450308001',
          '390450307001',
          '390450306003',
          '390450331001',
          '390450330001',
          '390450306001',
          '390450331002',
          '390450331003',
          '390450331004',
          '390450308003',
          '391290211003',
          '391290211002',
          '391290211001',
          '391290212004',
          '391290212003',
          '391290212001',
          '390450307002',
          '390490094501',
          '390490094971',
          '390490094502',
          '390490094503',
          '390490102001',
          '390490102002',
          '390490102004',
          '390490102003',
          '390490094102',
          '390490094953',
          '390490094201',
          '390490094203',
          '390490094204',
          '390490094402',
          '390490103001',
          '390490094401',
          '390490094403',
          '390490094951',
          '390490094952',
          '390490094302',
          '390490094303',
          '390490094304',
          '390490095901',
          '390490095202',
          '390490095203',
          '390490088121',
          '390490095201',
          '390490088133',
          '390490088132',
          '390319612002',
          '390319613003',
          '390319614003',
          '391199110002',
          '391199110003',
          '391199111001',
          '391199111002',
          '391199112001',
          '391199111005',
          '391199111004',
          '391199112002',
          '391199112003',
          '390897589006',
          '390897589003',
          '390897544002',
          '390319612003',
          '390319613004',
          '390319610004',
          '390319610002',
          '390319610003',
          '390319611001',
          '390319611003',
          '390897544004',
          '390830074002',
          '390319611002',
          '390830067003',
          '390759766002',
          '390759766001',
          '390759767003',
          '390759765003',
          '390759767002',
          '390759764002',
          '391690018003',
          '390759764003',
          '390759765001',
          '391690019003',
          '391690019002',
          '391690019001',
          '390759766003',
          '390830067004',
          '390759765002',
          '390059711003',
          '390059711002',
          '390059709004',
          '390059711001',
          '390897586001',
          '390897589004',
          '391279658005',
          '390897586003',
          '390897586002',
          '390897586004',
          '390897528001',
          '390897528003',
          '390897528002',
          '390897531004',
          '390897589001',
          '390897525002',
          '390897589002',
          '390897522001',
          '390897544001',
          '390897525004',
          '390897525003',
          '390897525001',
          '390897522004',
          '390897522003',
          '390897590004',
          '390897590003',
          '390897510001',
          '390897519002',
          '390897513002',
          '390897519003',
          '390897516002',
          '390897507003',
          '390897507004',
          '390897536001',
          '390897513003',
          '390897516001',
          '390897541011',
          '390897519001',
          '390897516003',
          '390897541013',
          '390897541012',
          '390897547002',
          '390897541021',
          '390897547001',
          '390450301001',
          '390897583001',
          '390897583003',
          '390450301004',
          '390897583002',
          '390897591003',
          '390897591002',
          '390897531002',
          '390897531003',
          '390897571001',
          '390897591001',
          '390897571002',
          '390897533003',
          '390897533004',
          '390897539002',
          '390897533005',
          '390897533001',
          '390897539003',
          '390897541014',
          '390897577003',
          '390897577004',
          '390897577001',
          '390897547004',
          '390897591004',
          '390897577005',
          '390897577002',
          '390897556002',
          '390897544003',
          '390897547003',
          '390897547005',
          '390830074003',
          '390830067002',
          '390830067001',
          '390830068011',
          '390830074001',
          '390830073001',
          '390830075001',
          '390830068012',
          '390830073002',
          '390830073003',
          '390897550002',
          '390897550004',
          '390830077001',
          '390830076001',
          '390830076002',
          '390830075003',
          '390830073004',
          '390830075002',
          '390830075004',
          '390830072004',
          '390830072002',
          '390830068023',
          '390830072001',
          '390830076003',
          '390830076004',
          '390830071003',
          '390830071002',
          '390830071001',
          '390830070002',
          '390830070003',
          '390830070001',
          '390897574002',
          '390450304002',
          '390897574001',
          '390897568001',
          '390897568003',
          '390897568002',
          '390897565002',
          '390897565001',
          '390450329001',
          '390897559003',
          '390450329004',
          '390450329002',
          '390450329003',
          '390450328003',
          '390450328001',
          '390450328002',
          '390450327021',
          '390897562011',
          '390897562021',
          '390490093851',
          '390897562023',
          '390897562022',
          '390490093902',
          '390897559002',
          '390490073952',
          '390897559001',
          '390490073962',
          '390490073943',
          '390897556001',
          '390897553002',
          '390897553001',
          '390897556004',
          '390897556003',
          '390490072101',
          '390490072032',
          '390490072031',
          '390897553004',
          '390897553003',
          '390450327011',
          '390450327022',
          '390490093862',
          '390490093741',
          '390490093742',
          '390490093861',
          '390490093712',
          '390490093842',
          '390490093812',
          '390490093821',
          '390490093814',
          '390490093811',
          '390490093622',
          '390490093813',
          '390490093621',
          '390490093625',
          '390490093731',
          '390490093732',
          '390490093723',
          '390490093714',
          '390490094101',
          '390490093734',
          '390490093261',
          '390490093722',
          '390490093123',
          '390490093222',
          '390490093713',
          '390490093113',
          '390490093502',
          '390490093611',
          '390490093613',
          '390490093111',
          '390490093401',
          '390490093221',
          '390490093402',
          '390490093501',
          '390490092402',
          '390490093901',
          '390490073954',
          '390490073956',
          '390490073951',
          '390490073961',
          '390490073941',
          '390490092101',
          '390490074272',
          '390490074271',
          '390490092103',
          '390490073942',
          '390490073934',
          '390490074273',
          '390490074261',
          '390490073932',
          '390490074262',
          '390490074922',
          '390490093252',
          '390490094305',
          '390490093374',
          '390490093253',
          '390490093232',
          '390490093341',
          '390490094301',
          '390490093371',
          '390490093372',
          '390490093342',
          '390490093321',
          '390490027801',
          '390490092503',
          '390490092304',
          '390490093311',
          '390490092501',
          '390490092303',
          '390490092301',
          '390490092201',
          '390490027702',
          '390490027502',
          '390490027602',
          '390490027601',
          '390490027302',
          '390490027301',
          '390490027401',
          '390490027403',
          '390490093362',
          '390490093324',
          '390490087302',
          '390490088122',
          '390490087201',
          '390490087301',
          '390490087103',
          '390490087204',
          '390490087104',
          '390490089002',
          '390490089001',
          '390490089004',
          '390490091002',
          '390490091005',
          '390490090003',
          '390490055001',
          '390490054202',
          '390490037002',
          '390490055005',
          '390490053002',
          '390490037003',
          '390490025202',
          '390490028004',
          '390490028002',
          '390490092102',
          '390499800001',
          '390490027101',
          '390490026003',
          '390490074252',
          '390490074251',
          '390490074246',
          '390490074242',
          '390490074921',
          '390490074243',
          '390490074942',
          '390490074241',
          '390490075511',
          '390490091001',
          '390490026002',
          '390490025102',
          '390490025101',
          '390490075122',
          '390490025103',
          '390490075123',
          '390490075112',
          '390490075512',
          '390490075201',
          '390490075331',
          '390490075202',
          '390490009201',
          '390490008201',
          '390490075341',
          '390490008204',
          '390490077102',
          '390490073931',
          '390490072103',
          '390490072104',
          '390490072102',
          '390490072051',
          '390490072053',
          '390490073933',
          '390490072091',
          '390490072092',
          '390490074923',
          '390490071323',
          '390490071324',
          '390490071322',
          '390490072072',
          '390490072073',
          '390490072071',
          '390490100005',
          '390490100001',
          '390490072011',
          '390490072012',
          '390410117601',
          '390490100002',
          '390410117501',
          '390490074941',
          '390490072022',
          '390490101001',
          '390490071992',
          '390490071993',
          '390490071991',
          '390490100003',
          '390490100004',
          '390490071981',
          '390490071943',
          '390490071202',
          '390490071203',
          '390490071942',
          '390490075332',
          '390490071133',
          '390490071132',
          '390490071123',
          '390490075311',
          '390490075312',
          '390490077221',
          '390490077212',
          '390490069324',
          '390490069331',
          '390490069325',
          '390490069322',
          '390490071154',
          '390490071152',
          '390490071142',
          '390490071143',
          '390490071141',
          '390490069311',
          '390490069453',
          '390490069315',
          '390490069452',
          '390490069451',
          '390490069444',
          '390490069442',
          '390490069904',
          '390490071935',
          '390490071933',
          '390410117101',
          '390490071936',
          '390490070203',
          '390490070202',
          '390490070201',
          '390410117103',
          '390410117102',
          '390410117402',
          '390410117401',
          '390410117303',
          '390410117301',
          '390410117302',
          '390490070102',
          '390490070204',
          '390490070101',
          '390490070103',
          '390490069901',
          '390490069902',
          '390490069903',
          '390410124001',
          '390410117621',
          '390410117622',
          '390410117503',
          '390410115401',
          '390897550003',
          '390897550001',
          '390830077002',
          '390830077004',
          '391179655004',
          '390830077003',
          '391179655001',
          '390410117602',
          '390410123002',
          '390410123001',
          '390410123003',
          '390410117502',
          '390410116042',
          '390410115603',
          '390410116041',
          '390410111022',
          '391179655002',
          '390410111021',
          '391179655003',
          '390830068013',
          '390830068021',
          '390830068022',
          '391390030022',
          '390059711004',
          '390059710004',
          '390059710003',
          '391390030021',
          '391390020003',
          '390830069002',
          '390830069005',
          '390830069004',
          '390830069003',
          '390830069001',
          '391390030023',
          '391390030013',
          '391390030011',
          '391390020005',
          '391390020004',
          '391390030014',
          '391390030012',
          '391390021023',
          '391390022005',
          '391179654001',
          '391179654003',
          '391179654002',
          '391179651003',
          '391179650001',
          '391179651002',
          '391179651001',
          '391179650002',
          '391179653004',
          '391179653002',
          '391179652002',
          '391179652005',
          '391179652001',
          '391179652003',
          '391179653003',
          '391179653005',
          '391179653001',
          '391179650003',
          '391179652004',
          '391290216003',
          '391290216002',
          '391290216001',
          '390479258002',
          '391290212002',
          '391290214022',
          '391290215002',
          '391290214021',
          '391290212005',
          '390490095904',
          '390490097403',
          '390490097404',
          '390490095902',
          '390490095903',
          '390490088251',
          '390490088252',
          '390490088112',
          '390490088111',
          '390490088212',
          '390490088221',
          '390490088222',
          '390490088213',
          '390490097401',
          '390490097402',
          '390490083803',
          '390490083802',
          '390490097204',
          '390490097202',
          '390490097511',
          '390490097121',
          '390490096004',
          '390490097112',
          '390490097111',
          '390490083701',
          '390490097201',
          '390490097123',
          '390490097114',
          '390490096003',
          '390490096002',
          '390490096001',
          '390490097522',
          '390490097521',
          '391290215001',
          '391290214012',
          '391290214011',
          '390970412002',
          '390970412003',
          '390970412001',
          '391290213001',
          '390490098002',
          '390490098003',
          '390490098004',
          '390490081612',
          '390490098001',
          '390970411001',
          '390479264001',
          '390479263001',
          '390479260002',
          '390479262001',
          '390479258003',
          '390479263002',
          '390479263003',
          '390479263004',
          '390479260004',
          '390479262004',
          '390479262003',
          '390479261001',
          '390479261003',
          '390479260001',
          '390479261002',
          '390479260003',
          '390479264003',
          '390279643004',
          '390479259002',
          '390479259001',
          '390279643001',
          '390279643002',
          '390479258001',
          '390970413002',
          '390970411004',
          '390970411003',
          '390970406001',
          '390970407001',
          '390970407002',
          '390970410001',
          '390479259003',
          '390970413001',
          '390970411002',
          '390230033023',
          '390230033022',
          '390230033012',
          '390230033013',
          '390572701001',
          '390572601004',
          '390230033021',
          '390230022004',
          '390490087202',
          '390490061002',
          '390490059003',
          '390490060001',
          '390490058202',
          '390490058101',
          '390490061004',
          '390490061003',
          '390490058103',
          '390490055006',
          '390490056102',
          '390490053004',
          '390490052001',
          '390490040002',
          '390490038001',
          '390490029002',
          '390490030003',
          '390490057003',
          '390490040001',
          '390490042001',
          '390490030001',
          '390490030002',
          '390490083403',
          '390490051002',
          '390490083801',
          '390490083401',
          '390490083301',
          '390490083302',
          '390490049004',
          '390490050004',
          '390490050006',
          '390490050003',
          '390490050005',
          '390490032001',
          '390490085005',
          '390490049001',
          '390490043004',
          '390490049005',
          '390490045002',
          '390490043002',
          '390490023002',
          '390490015001',
          '390490023001',
          '390490014002',
          '390490014001',
          '390490007305',
          '390490007203',
          '390490022001',
          '390490016002',
          '390490020001',
          '390490018104',
          '390490012002',
          '390490013003',
          '390490010004',
          '390490009103',
          '390490009101',
          '390490008103',
          '390490007102',
          '390490003303',
          '390490077104',
          '390490003301',
          '390490003201',
          '390490007104',
          '390490010006',
          '390490005002',
          '390490003102',
          '390490003101',
          '390490004203',
          '390490002102',
          '390490020003',
          '390490018203',
          '390490085001',
          '390490019012',
          '390490011103',
          '390490078301',
          '390490011221',
          '390490085004',
          '390490084001',
          '390490019022',
          '390490078303',
          '390490066001',
          '390490006004',
          '390490004102',
          '390490078201',
          '390490078203',
          '390490004101',
          '390490002204',
          '390490078122',
          '390490078112',
          '390490063233',
          '390490078113',
          '390490063231',
          '390490078111',
          '390490083703',
          '390490083603',
          '390490083222',
          '390490048202',
          '390490083601',
          '390490048103',
          '390490083702',
          '390490083602',
          '390490083212',
          '390490083124',
          '390490083122',
          '390490047005',
          '390490047003',
          '390490046203',
          '390490045004',
          '390490082412',
          '390490046101',
          '390490045005',
          '390490083111',
          '390490082101',
          '390490082424',
          '390490083501',
          '390490083123',
          '390490083112',
          '390490083502',
          '390490082302',
          '390490081613',
          '390490081641',
          '390490081623',
          '390490081631',
          '390490082301',
          '390490081203',
          '390490081202',
          '390490081413',
          '390490081422',
          '390490084003',
          '390490082411',
          '390490065002',
          '390490066003',
          '390490065004',
          '390490082423',
          '390490082422',
          '390490064102',
          '390490079411',
          '390490064302',
          '390490064304',
          '390490063232',
          '390490063305',
          '390490063212',
          '390490063214',
          '390490064103',
          '390490063306',
          '390490063301',
          '390490063102',
          '390490063104',
          '390490082421',
          '390490079541',
          '390490079524',
          '390490079523',
          '390490079525',
          '390490079522',
          '390490079311',
          '390490079332',
          '390490077214',
          '390490077215',
          '390490077301',
          '390490069334',
          '390490069323',
          '390490069241',
          '390490069234',
          '390490077302',
          '390490001101',
          '390490069212',
          '390490107001',
          '390490068221',
          '390490069231',
          '390490069431',
          '390490069232',
          '390490069434',
          '390490069433',
          '390490069905',
          '390490099001',
          '390490069211',
          '390490067102',
          '390490067222',
          '390490001103',
          '390490001203',
          '390490001104',
          '390490068101',
          '390490068211',
          '390490078114',
          '390490078123',
          '390490063522',
          '390490069502',
          '390490063511',
          '390490063513',
          '390490067101',
          '390490069101',
          '390490067223',
          '390490067214',
          '390490067213',
          '390490063922',
          '390490063923',
          '390490070471',
          '390490070482',
          '390490070481',
          '390490070431',
          '390490070432',
          '390490070443',
          '390490070442',
          '390410115612',
          '390410124003',
          '390410115402',
          '390410115403',
          '390410115302',
          '390490070411',
          '390490063912',
          '390490063911',
          '390410115611',
          '390410115202',
          '390410115201',
          '390410114213',
          '390490063213',
          '390490063531',
          '390490063402',
          '390490063533',
          '390490063962',
          '390490063952',
          '390490063532',
          '390490063401',
          '390490063951',
          '390490063722',
          '390490063721',
          '390490063961',
          '390490063872',
          '390490063871',
          '390490063863',
          '390490063834',
          '390490063723',
          '390490063874',
          '390490063861',
          '390490079514',
          '390490079513',
          '390490079512',
          '390490062205',
          '390490079511',
          '390490079221',
          '390490062203',
          '390490062301',
          '390490063712',
          '390490063714',
          '390490062202',
          '390490062201',
          '390490105001',
          '390490063835',
          '390490063833',
          '390490063844',
          '390490063942',
          '390490063933',
          '390490063832',
          '390490063841',
          '390490063932',
          '390410114214',
          '390410114212',
          '390410114231',
          '390410114232',
          '390410114211',
          '390410114301',
          '390490063831',
          '390490062362',
          '390490062363',
          '390490105002',
          '390490105003',
          '390490062361',
          '390410114302',
          '390410119002',
          '390410119001',
          '390410114111',
          '390410114121',
          '390490081624',
          '390490081625',
          '390490081323',
          '390490081321',
          '390490081412',
          '390490081421',
          '390490081423',
          '390490081411',
          '390490081622',
          '390490081621',
          '390490080001',
          '390490079535',
          '390490079543',
          '390490079333',
          '390490079531',
          '390490079534',
          '390490079532',
          '390490106012',
          '390490079334',
          '390490079312',
          '390490106023',
          '390490079211',
          '390490079213',
          '390490106011',
          '390490080002',
          '390490080003',
          '390970405004',
          '390970404002',
          '390970405003',
          '390970405001',
          '390970405002',
          '390970404001',
          '390970401012',
          '390490079212',
          '390490062302',
          '390490062303',
          '390490105004',
          '390490062304',
          '390490104003',
          '390490104001',
          '390490104002',
          '391590506013',
          '390410114112',
          '390410114122',
          '391590506012',
          '391590506011',
          '390970401022',
          '390970401021',
          '391590507004',
          '390410115601',
          '390410115501',
          '390410115502',
          '390410115602',
          '390410115503',
          '390410122002',
          '390410102004',
          '390410102003',
          '390410102002',
          '390410121002',
          '390410121001',
          '390410114131',
          '390410114132',
          '390410114133',
          '390410122001',
          '390410105301',
          '390410105302',
          '390410101002',
          '390410101001',
          '390410105202',
          '390410104222',
          '390410105201',
          '390410105203',
          '390410104211',
          '390410111011',
          '390410102001',
          '390410111012',
          '390410104202',
          '390410104223',
          '390410104221',
          '390410112002',
          '390410112001',
          '390410114134',
          '390410120003',
          '391590506022',
          '391590503042',
          '390410120001',
          '390410120002',
          '391590502004',
          '391590502003',
          '391590501003',
          '390970402022',
          '390970406002',
          '390970402011',
          '390970402021',
          '390970401011',
          '391590507001',
          '391590507002',
          '391590507003',
          '390230023012',
          '390230033011',
          '390230023011',
          '390210115052',
          '390210115053',
          '390210115051',
          '390210115061',
          '390210115044',
          '390210115043',
          '390210101001',
          '391590505004',
          '391590506021',
          '391590505003',
          '391590505001',
          '391590505002',
          '391590504003',
          '391590504002',
          '391590504001',
          '391590504004',
          '391590503031',
          '391590503032',
          '391590503011',
          '391590503012',
          '391590503041',
          '391590502002',
          '391590502001',
          '390210115062',
          '390210101002',
          '390910047003',
          '390910038004',
          '390910047001',
          '390910047002',
          '391010105004',
          '391010101005',
          '391010105003',
          '391010104003',
          '391010010002',
          '391010006004',
          '391010104001',
          '391010101004',
          '391010101001',
          '391010105002',
          '391010105001',
          '391010103005',
          '391010104002',
          '391590501002',
          '391010103003',
          '391590501001',
          '390650007004',
          '390910038002',
          '390230022005',
          '390230026052',
          '390210115042',
          '390210105001',
          '390210115013',
          '390910046002',
          '390910038001',
          '390910038003',
        ],
      },
      {
        name: 'Custom zone B',
        blockGroups: [
          '391370307001',
          '391610201001',
          '391610202003',
          '391610202002',
          '391610202001',
          '180030119001',
          '391259605002',
          '390399585002',
          '390399585003',
          '390510408003',
          '390510408002',
          '391259605004',
          '391259605003',
          '391259605001',
          '391259601003',
          '391259601002',
          '390399589004',
          '391259601001',
          '391259603003',
          '391259604002',
          '391259604003',
          '391259604001',
          '391259603002',
          '391259602003',
          '391259602002',
          '390399587003',
          '390399586003',
          '390399586002',
          '390399584001',
          '390399581003',
          '390399582001',
          '390399584002',
          '390399582002',
          '390399581002',
          '391719505004',
          '391719505002',
          '391719505003',
          '391719505001',
          '391719506001',
          '391719506004',
          '391719506002',
          '391719508002',
          '390399584003',
          '390399584004',
          '390399582003',
          '391719507004',
          '391719507002',
          '391719507001',
          '391719508003',
          '391719508001',
          '391259603004',
          '180030110002',
          '391259603001',
          '391259602001',
          '180030109004',
          '180030109003',
          '180030101002',
          '180030110003',
          '180030110001',
          '180030108122',
          '180030109005',
          '180030109002',
          '180030109001',
          '180030101003',
          '180030101001',
          '180030102022',
          '180030108093',
          '180030108083',
          '180030108151',
          '180030108162',
          '180030108152',
          '180030108133',
          '180030102021',
          '180030102023',
          '180030102014',
          '180030102012',
          '180030102011',
          '390399583003',
          '390399583004',
          '390399583001',
          '390399583002',
          '390399582004',
          '180330208002',
          '391719509003',
          '391719509001',
          '391719509002',
          '180330203003',
          '180330203004',
          '180330203002',
          '180330203001',
          '180330201004',
          '180330208001',
          '180330208003',
          '180330207001',
          '180330204005',
          '180330204001',
          '180330204002',
          '180330205003',
          '180330205004',
          '180330205002',
          '180330204003',
          '180330202003',
          '180330202002',
          '180330202001',
          '180330204004',
          '180330205001',
          '180330201003',
          '391719504004',
          '391719504002',
          '391719504003',
          '391719501001',
          '260590511004',
          '260590511003',
          '391719501004',
          '391719501002',
          '260590511002',
          '391719504001',
          '391719503005',
          '391719503001',
          '391719503002',
          '391719502004',
          '391719502002',
          '391719501003',
          '260590511001',
          '260590510004',
          '260590510003',
          '260590510001',
          '260590510002',
          '260590506003',
          '260590506002',
          '260590509004',
          '260590512003',
          '260590509005',
          '260590509003',
          '260590504005',
          '260590509002',
          '391719502003',
          '181519712003',
          '391719502001',
          '260590512002',
          '260590512001',
          '181519712002',
          '181519708001',
          '181519708002',
          '181519716002',
          '181519716001',
          '181519714001',
          '181519716003',
          '181519712001',
          '181519713001',
          '260590504004',
          '260239508004',
          '180330207002',
          '180330206021',
          '180330206023',
          '180330201001',
          '391690035001',
          '391690025001',
          '391690024002',
          '391690023001',
          '391690022002',
          '391690022001',
          '390059701004',
          '390059702003',
          '391390029002',
          '391390028003',
          '391390028002',
          '390339741001',
          '390339741002',
          '390339742001',
          '391759380003',
          '390630013002',
          '391370304002',
          '391370305002',
          '391370305001',
          '391370306002',
          '391370306001',
          '391370307002',
          '391535021022',
          '391535021023',
          '391535011001',
          '391535022003',
          '391535022001',
          '391535075003',
          '391535067002',
          '391535067001',
          '391535083012',
          '391535074001',
          '391535066001',
          '391535066002',
          '391535075006',
          '391535073003',
          '391535086002',
          '391535065001',
          '391535064002',
          '391535086001',
          '391535062001',
          '391535073004',
          '391535072035',
          '391535061004',
          '391535072023',
          '391535305022',
          '391535305012',
          '391535308002',
          '391535308004',
          '391535201061',
          '391535201041',
          '391535201051',
          '391535201052',
          '391535308003',
          '391535305021',
          '391535305011',
          '391535304012',
          '391535304011',
          '391535307001',
          '391535307002',
          '391535206001',
          '391535306042',
          '391535304021',
          '391535306052',
          '391535332003',
          '391535332002',
          '391535306051',
          '391535306041',
          '391535306032',
          '391535332004',
          '391535340002',
          '391535340004',
          '391535201031',
          '391535202022',
          '391535201043',
          '391535201042',
          '391535202024',
          '391535203021',
          '391535076001',
          '391535202011',
          '391535075002',
          '391535202013',
          '391535203023',
          '391535203012',
          '391535205002',
          '391535205001',
          '391535204005',
          '391535204001',
          '391535204006',
          '391535203011',
          '391535329024',
          '391535075001',
          '391535072033',
          '391535072034',
          '391535072012',
          '391535072011',
          '391535072031',
          '391535329022',
          '391535329021',
          '391535080002',
          '391535306031',
          '391535329023',
          '391535329012',
          '391535326002',
          '391535331021',
          '391535331022',
          '391535331012',
          '391535331013',
          '391535301051',
          '391535340003',
          '391535340001',
          '391535341001',
          '391535327012',
          '391535301052',
          '391535327031',
          '391535326001',
          '391535327033',
          '391535327062',
          '391535327084',
          '391535327083',
          '391535327011',
          '391535327082',
          '391535327081',
          '391535327032',
          '391535327023',
          '391535327022',
          '391535327052',
          '391535327051',
          '391535327053',
          '391535327021',
          '390351941001',
          '391535320041',
          '391535083993',
          '391535083994',
          '391535083991',
          '391535088006',
          '391535088005',
          '391535088004',
          '391535062004',
          '391535062005',
          '391535061003',
          '391535072024',
          '391535334001',
          '391535061002',
          '391535071023',
          '391535320043',
          '391535320044',
          '391535334002',
          '391535334003',
          '391535320031',
          '391535320034',
          '391535320032',
          '391034172001',
          '391034172002',
          '391034172003',
          '391034171004',
          '391034171001',
          '391034173002',
          '391034171002',
          '391034170001',
          '391034170002',
          '391535320033',
          '391535335022',
          '391535335021',
          '391034060001',
          '391034060002',
          '391535071013',
          '391535071014',
          '391535071011',
          '391535080003',
          '391535071022',
          '391535322021',
          '391535322022',
          '391535323023',
          '391535322025',
          '391535322024',
          '391535322026',
          '391535335013',
          '391535323021',
          '391535323022',
          '391535323012',
          '391535326003',
          '391535325021',
          '391535335012',
          '391535335011',
          '391535323011',
          '391034050001',
          '391535323013',
          '391535325022',
          '391034001004',
          '391034173001',
          '391034173003',
          '391034170004',
          '391034170006',
          '391034130004',
          '391034130001',
          '391034170005',
          '391034060003',
          '391034070001',
          '391034130003',
          '391034130002',
          '391034070003',
          '391034070004',
          '391034120001',
          '391034090023',
          '391034082023',
          '391034050002',
          '391034070002',
          '391034083012',
          '391034080021',
          '391034040001',
          '391034001002',
          '391034160001',
          '391034161001',
          '391034161002',
          '391034160003',
          '391034083023',
          '391034083024',
          '391034083011',
          '391034083022',
          '391034083021',
          '391034080013',
          '391034080012',
          '391034080031',
          '391034040002',
          '391034080032',
          '391034081003',
          '391034081004',
          '391034082022',
          '391034081002',
          '391034082012',
          '391034082011',
          '391034081005',
          '391034161003',
          '391034160004',
          '391034153002',
          '391034162001',
          '391034162002',
          '391034154003',
          '391034020001',
          '391535327063',
          '390351351061',
          '391535325011',
          '390351351051',
          '391535327061',
          '391535327054',
          '390351351042',
          '390351351053',
          '390351351031',
          '390351351041',
          '391535325012',
          '390351351052',
          '390351361022',
          '390351361021',
          '391034001003',
          '390351361023',
          '390351752022',
          '390351752021',
          '390351361034',
          '390351361032',
          '390351361031',
          '390351361033',
          '390351361013',
          '390351361014',
          '390351361012',
          '390351751053',
          '390351751051',
          '390351751052',
          '390351361011',
          '391034001001',
          '390351752014',
          '390351752013',
          '390351752012',
          '390351752011',
          '391034151002',
          '391034151001',
          '391034152001',
          '391034152003',
          '391034152002',
          '391034164001',
          '391034163002',
          '390351862031',
          '390351862032',
          '390351862022',
          '390351862013',
          '390351862011',
          '390351751044',
          '390351751042',
          '390351751032',
          '390351751033',
          '390351861075',
          '390351861074',
          '390351861062',
          '390351861071',
          '391034153001',
          '391034154001',
          '391034163001',
          '391034154002',
          '391034158002',
          '391034158001',
          '390351862021',
          '390351862063',
          '390351862012',
          '390351862052',
          '390351862051',
          '390351862053',
          '390351861032',
          '390351861033',
          '390351861031',
          '390930901002',
          '390930901001',
          '391034120004',
          '391034120002',
          '391034120003',
          '391034110021',
          '391034090022',
          '391034110022',
          '391034110011',
          '391034110023',
          '391034090012',
          '391034090021',
          '391034030022',
          '391034030021',
          '391034020003',
          '391034090011',
          '391034030012',
          '391034030011',
          '390930951004',
          '391034100003',
          '391034100001',
          '391034100002',
          '390059701002',
          '390059701001',
          '390930971002',
          '390930941004',
          '390930941003',
          '390930941001',
          '390930961005',
          '390930961004',
          '390930961003',
          '390930961002',
          '390930961001',
          '390930931002',
          '391034020002',
          '390930902002',
          '390930912001',
          '390930902001',
          '390930951001',
          '390930912002',
          '390930951003',
          '390930951002',
          '390930771002',
          '390930911001',
          '390930911002',
          '390930771001',
          '390930713001',
          '390930941002',
          '390930771003',
          '390930601001',
          '390930713002',
          '390930715002',
          '390930715001',
          '390930601002',
          '390930601003',
          '390930602002',
          '390930602003',
          '390930602001',
          '390930571004',
          '390930571003',
          '390059702001',
          '390059702002',
          '390779166003',
          '390779166002',
          '390779166004',
          '390930971001',
          '390930931001',
          '390779166001',
          '390779154002',
          '390779164001',
          '390779164002',
          '390779165003',
          '390779164003',
          '390779161001',
          '390779165001',
          '390779154003',
          '390779165002',
          '390779156001',
          '390779156002',
          '390779156004',
          '390779156003',
          '390930921002',
          '390930921001',
          '390779154001',
          '390430403002',
          '390430403003',
          '390430403001',
          '390779155001',
          '390779155002',
          '390779155003',
          '390779158004',
          '390430418005',
          '390430418004',
          '391390028001',
          '390779164004',
          '390779163001',
          '390779161002',
          '390779163004',
          '390779163003',
          '390779162004',
          '390779162002',
          '390779163002',
          '390779162001',
          '390779161003',
          '390779157004',
          '390779157002',
          '390779157001',
          '390779159001',
          '390779159003',
          '391479638002',
          '391479638001',
          '391479625003',
          '391479625001',
          '391479626001',
          '390779158003',
          '390779158001',
          '390779158002',
          '390430418003',
          '390779159002',
          '390430418002',
          '390779160004',
          '390779160003',
          '390779160001',
          '391439622004',
          '391439622003',
          '391439622002',
          '390779160002',
          '391439622001',
          '391439621003',
          '391439621004',
          '391439620005',
          '391439621002',
          '391230506001',
          '391479638003',
          '391479638004',
          '391479636001',
          '391479625004',
          '391479625002',
          '391479626002',
          '391479634001',
          '391479626003',
          '391479631004',
          '391479637004',
          '391479636005',
          '391479636002',
          '391479637003',
          '391479637002',
          '391479632002',
          '391479632004',
          '391479632001',
          '391479632003',
          '391479631002',
          '391479635005',
          '391479635001',
          '391479635004',
          '391479633003',
          '391479634003',
          '391479633002',
          '391479633001',
          '391479626004',
          '391479627004',
          '391479627002',
          '391479627001',
          '391439620002',
          '391439620004',
          '391439619002',
          '391439620003',
          '391439620001',
          '391439608004',
          '391439608002',
          '391439619001',
          '391439619003',
          '391439618004',
          '391439618003',
          '391439617003',
          '391439617001',
          '391439608003',
          '391439618002',
          '391439614003',
          '391439613004',
          '391439613003',
          '391439614002',
          '391439617004',
          '391439615002',
          '391439615004',
          '391439615001',
          '391439616001',
          '391439616003',
          '391439608001',
          '391230512003',
          '391439609002',
          '391230508003',
          '391230507003',
          '391439612004',
          '391439613002',
          '391439612003',
          '391439612001',
          '391439609004',
          '391439609001',
          '391439609003',
          '391439611004',
          '391439610004',
          '391230509004',
          '391230509005',
          '391479631003',
          '390630002001',
          '391479631001',
          '391479630002',
          '391479630003',
          '391479630001',
          '391479628001',
          '391479627003',
          '391479629005',
          '391479629002',
          '391479629001',
          '391479628004',
          '390630001003',
          '390630001002',
          '391730222001',
          '390630001001',
          '391730222002',
          '390630002003',
          '390630002002',
          '390630011002',
          '390630007003',
          '390630007004',
          '390630007001',
          '390630011004',
          '390630010005',
          '390630011003',
          '390630011001',
          '390630008005',
          '390630008004',
          '390630008001',
          '390630010004',
          '390630009005',
          '390630007002',
          '390630006004',
          '390630006005',
          '390630006003',
          '390630005001',
          '390630005005',
          '390630005002',
          '390630005003',
          '390630009004',
          '390630009002',
          '390630004003',
          '390630004002',
          '390630003003',
          '391730223003',
          '391730223002',
          '391730224003',
          '391439612002',
          '391439611001',
          '391730221004',
          '391730221003',
          '391730221001',
          '391730221002',
          '391439611003',
          '391439611002',
          '391439610003',
          '391439610002',
          '391439610001',
          '391730211003',
          '391730211002',
          '391730210002',
          '391730210003',
          '391730210001',
          '391730225003',
          '391730225002',
          '391730219021',
          '391730219022',
          '391730218001',
          '391730217024',
          '391730217013',
          '391730219012',
          '391730217021',
          '391730219013',
          '391730211001',
          '391730218003',
          '391730218002',
          '391730217011',
          '391730216003',
          '391730216005',
          '391730225001',
          '391730207005',
          '391730212002',
          '391230512004',
          '391230512002',
          '391230508002',
          '391230507002',
          '391230508004',
          '391230508001',
          '391230507001',
          '391230509002',
          '391230509003',
          '391230509001',
          '390950097003',
          '390950097002',
          '261158312002',
          '261158313001',
          '261158312003',
          '391230510004',
          '391230510005',
          '391230510003',
          '391230510002',
          '391230510001',
          '391230511002',
          '391230511003',
          '391230511001',
          '391730209002',
          '391730209004',
          '391730209005',
          '391730209006',
          '391730209001',
          '391730209003',
          '391730201001',
          '391730201002',
          '390950099002',
          '391730208001',
          '391730208003',
          '391730208002',
          '390950101004',
          '390950100022',
          '390950101001',
          '390950101003',
          '390950097001',
          '390950098001',
          '390950098002',
          '390950099003',
          '390950100012',
          '390950100011',
          '390950100023',
          '390950047021',
          '390950046002',
          '390950099001',
          '390950046001',
          '390950012012',
          '390950055032',
          '390950055012',
          '390950055022',
          '390950055011',
          '390950055013',
          '390950055014',
          '390950055033',
          '390950056003',
          '390950056004',
          '261158337002',
          '391730207004',
          '391730207003',
          '391730207002',
          '391730201004',
          '391730207001',
          '390950101002',
          '390950050002',
          '390950049002',
          '390950052003',
          '390950051002',
          '390950051001',
          '390950051004',
          '391730201003',
          '391730203002',
          '391730203001',
          '391730202001',
          '390950103003',
          '390950054002',
          '390950042002',
          '390950040001',
          '391730202003',
          '390950045011',
          '390950040002',
          '390950044002',
          '390950039001',
          '391730204022',
          '391730206011',
          '391730204012',
          '391730204011',
          '391730204021',
          '391730205002',
          '391730204013',
          '390950070021',
          '391730206012',
          '391730206022',
          '391730205001',
          '390950070022',
          '390950071021',
          '391730206021',
          '390950071022',
          '390950071023',
          '391730203004',
          '391730203003',
          '390950045043',
          '390950045042',
          '390950070011',
          '390950045044',
          '390950069001',
          '390950069003',
          '390950045013',
          '390950045041',
          '390950102002',
          '390950044004',
          '390950039002',
          '390950039003',
          '390950068004',
          '390950068002',
          '390950068001',
          '390950066003',
          '390950070012',
          '390950072023',
          '390950072033',
          '390950071012',
          '390950071013',
          '390950072052',
          '390950072053',
          '390950072031',
          '390950073033',
          '390950073023',
          '390950073022',
          '390950072051',
          '390950073032',
          '390950073013',
          '390950073021',
          '390950050001',
          '390950047011',
          '390950047024',
          '390950047012',
          '390950020002',
          '390950020003',
          '390950047013',
          '390950047014',
          '390950030002',
          '390950030001',
          '390950029001',
          '390950018003',
          '390950020001',
          '390950011002',
          '390950012011',
          '390950011001',
          '390950019001',
          '390950011004',
          '390950010003',
          '390950010001',
          '390950037001',
          '390950034001',
          '390950026002',
          '390950023002',
          '390950022003',
          '390950022002',
          '390950036002',
          '390950033003',
          '390950035001',
          '390950032001',
          '390950021002',
          '390950025001',
          '390950014002',
          '390950017001',
          '390950009002',
          '390950016003',
          '390950008001',
          '390950009001',
          '390950057032',
          '390950004002',
          '390950057031',
          '390950008002',
          '390950006002',
          '390950006001',
          '390950004003',
          '390950003001',
          '390950002003',
          '390950002004',
          '390950056002',
          '390950057022',
          '261158336003',
          '390950057021',
          '390950057011',
          '390950057023',
          '390950057012',
          '261158335002',
          '261158335003',
          '261158339001',
          '261158338004',
          '261158338001',
          '390950031002',
          '390950067001',
          '390950024011',
          '390950024013',
          '390950013033',
          '390950066002',
          '390950066001',
          '390950104001',
          '390950013013',
          '390950013011',
          '390950013031',
          '390950007002',
          '390950007007',
          '390950007001',
          '390950062001',
          '390950063001',
          '390950062002',
          '390950013021',
          '390950065001',
          '390950013012',
          '390950077004',
          '390950061004',
          '390950061001',
          '390950060001',
          '390950074002',
          '390950074004',
          '390950075001',
          '390950076002',
          '390950075004',
          '390950074003',
          '390950075002',
          '390950077002',
          '390950077001',
          '390950078002',
          '390950078004',
          '390950083021',
          '390950083022',
          '390950083012',
          '390950083011',
          '390950083013',
          '390950058024',
          '390950058021',
          '390950058022',
          '390950058011',
          '390950058012',
          '390950059021',
          '390950059023',
          '390950059024',
          '390950059012',
          '390950079011',
          '261158333001',
          '261158339002',
          '261158338002',
          '261158333002',
          '261158331003',
          '390950079021',
          '390950079023',
          '390950079025',
          '390950079012',
          '390950080002',
          '390950080003',
          '261158333003',
          '261158333004',
          '261158325003',
          '261158318001',
          '261158325001',
          '261158337004',
          '261158337001',
          '261158337003',
          '261158326001',
          '261158325002',
          '261158324002',
          '261158324003',
          '261158326002',
          '261158327001',
          '261158313003',
          '261158313004',
          '261158317002',
          '261158313005',
          '261158317003',
          '261158317001',
          '261158316002',
          '261158320001',
          '261158319002',
          '261158320002',
          '261158316003',
          '261158315003',
          '261158316001',
          '261158315002',
          '261158314001',
          '261158312004',
          '261158311001',
          '261158322001',
          '261158323004',
          '261158314003',
          '261158323002',
          '261158323003',
          '261158314005',
          '261158311002',
          '261158314004',
          '261158324001',
          '261158309001',
          '261158336002',
          '261158336001',
          '261158335001',
          '261158332003',
          '261158332001',
          '261158327002',
          '261158328004',
          '261158338003',
          '261158332002',
          '261158331002',
          '261158328003',
          '261158331004',
          '261158330003',
          '261158331001',
          '261158328002',
          '261158310002',
          '261158310001',
          '261158309002',
          '261158305001',
          '261158306001',
          '261158308002',
          '261158308003',
          '261158306003',
          '261158308001',
          '390630010002',
          '390630010001',
          '390630003006',
          '390630004004',
          '390630004001',
          '390630003005',
          '390630003004',
          '391730224002',
          '390630003002',
          '391730224001',
          '390630003001',
          '391730223001',
          '391370304001',
          '391370303003',
          '391370301002',
          '391370303002',
          '391370301003',
          '390690006004',
          '390690006003',
          '391370301001',
          '390690006001',
          '391730215002',
          '391730215001',
          '391730219011',
          '391730214003',
          '391730214002',
          '391730214001',
          '391730216002',
          '391730216001',
          '391730213003',
          '391730212001',
          '390950089022',
          '390950089024',
          '391730213002',
          '390950089013',
          '390950096001',
          '390950096002',
          '390690005004',
          '390690005001',
          '390690005002',
          '391730213001',
          '390690001003',
          '390690001004',
          '390690001001',
          '390690001002',
          '391370303005',
          '391370303004',
          '391370303006',
          '391370303001',
          '391370302004',
          '391370301004',
          '390690006002',
          '390690007001',
          '390690007004',
          '391370302003',
          '391370302002',
          '391370302001',
          '390399589001',
          '390399589003',
          '390690005003',
          '390690007002',
          '390690007003',
          '390690003005',
          '390690002002',
          '390690004002',
          '390690004001',
          '390690004003',
          '390690003003',
          '390690003004',
          '390690003002',
          '390690003001',
          '390690002001',
          '390399581004',
          '390399589002',
          '390399588004',
          '390399587004',
          '390399588003',
          '390399587002',
          '390399588001',
          '390399588002',
          '390399585001',
          '390399586001',
          '390399581001',
          '390690002003',
          '390510409003',
          '391730206023',
          '390950089021',
          '390950090001',
          '390950089023',
          '390950090002',
          '390950090004',
          '390950071011',
          '390950072042',
          '390950072041',
          '390950088004',
          '390950088003',
          '390950073012',
          '390950073011',
          '390950086003',
          '390950088002',
          '390950088001',
          '390950087001',
          '390950087002',
          '390950091021',
          '390950091022',
          '390950091011',
          '390950089012',
          '390950090003',
          '390950089011',
          '390950095003',
          '390950094001',
          '390950095002',
          '390950094002',
          '390950086002',
          '390950086001',
          '390950085002',
          '390950085001',
          '390950085004',
          '390950084003',
          '390950087004',
          '390950092021',
          '390950092024',
          '390950092023',
          '390950084002',
          '390950084001',
          '390950083014',
          '390950092022',
          '390950082031',
          '390950082032',
          '390950091012',
          '390950092011',
          '390950082033',
          '390950092013',
          '390950081002',
          '390950081001',
          '390950081003',
          '261158330004',
          '390950082023',
          '390950082022',
          '390950082013',
          '390950082021',
          '390950082012',
          '261158330002',
          '390950092012',
          '390950093001',
          '260910621003',
          '390950095001',
          '390510403003',
          '390510402003',
          '390510402002',
          '390510402001',
          '390510403002',
          '390510403001',
          '390510404003',
          '390510404002',
          '390510401003',
          '390510404001',
          '390510401002',
          '390510401001',
          '390510405002',
          '261158330001',
          '261158329004',
          '261158329001',
          '261158329003',
          '260910621002',
          '260910621005',
          '260910621004',
          '260910621001',
          '261158329002',
          '261158308004',
          '261158307003',
          '260910601003',
          '260910601002',
          '260910622001',
          '260910622002',
          '260910620002',
          '260910612001',
          '260910612002',
          '260910617004',
          '260910617005',
          '260910613012',
          '260910607003',
          '260910623004',
          '260910623005',
          '260910607002',
          '260910613011',
          '260910607001',
          '260910608003',
          '260910608002',
          '260910607004',
          '260910608001',
          '260910623003',
          '260910623001',
          '260910623002',
          '260910624004',
          '390510405004',
          '390510405003',
          '390510407004',
          '390510407002',
          '390510407003',
          '390510406003',
          '390510407005',
          '390510407001',
          '390510406002',
          '390510405001',
          '260910620001',
          '390510406001',
          '260910619004',
          '260910619005',
          '390510408004',
          '390510409002',
          '390510409001',
          '260910619003',
          '390510408001',
          '260910619001',
          '260910620003',
          '260910616003',
          '260910616002',
          '260910613024',
          '260910613021',
          '260910613023',
          '260910614003',
          '260910616001',
          '260910617002',
          '260910615005',
          '260910615004',
          '260910615003',
          '260910617003',
          '260910617001',
          '260910619002',
          '260910605003',
          '260910605002',
          '260910614001',
          '260910606001',
          '260910615002',
          '260910615001',
          '260910606002',
          '260910603013',
          '260910605001',
          '260910603011',
          '260910603021',
          '260910618002',
          '260910618003',
          '260910618004',
          '260910618001',
          '260910604021',
          '260910603022',
          '260910604023',
          '260910604022',
          '260910604013',
          '261158305002',
          '260910601001',
          '260910603012',
          '391610201004',
          '391610201003',
          '391610201002',
          '391690024001',
          '391690023002',
          '391690022003',
          '390059701003',
          '390059702004',
          '391390029003',
          '391370307004',
          '391370307003',
        ],
      },
    ],
  },
  '903b96bb-8394-4299-9a72-2bfca77e2af1': {
    name: 'California',
    viewName: 'View name',
    type: 'Passenger Flows',
    backend: 'database',
    table: 'hive_metastore.passengercaltrans.flow',
    mapBounds: [-125.332031, 32.175612, -113.378906, 42.309815],
    mapCenter: [-121.322021, 37.840157],
    mapZoom: 6,
  },
  'mock-transit-dashboard': {
    name: 'Mock transit dashboard',
    dashboardId: 'mock-transit-dashboard',
    userId: 'user1',
    viewName: 'DC transit',
    type: 'Transit',
    table: '/data/temp-transit',
    clientName: 'camsys',
    backend: 'files',
    mapBounds: [
      -77.63799550921401, 38.37657723366854, -76.42813189388741,
      39.343160786224075,
    ],
    mapCenter: [-77.03208007259511, 38.908217016389045],
    studyareaBlockgroups: [
      'AC: Ballston - Courthouse - Rosslyn',
      'AC: Bluemont - Barcroft - Douglas Park',
      'AC: Crystal/Pentagon Cities - Shirlington',
      'AC: East Falls Church - Garden City',
      'AL: Brookville - Landmark/Van Dorn',
      'AL: Old Town - Rosemont',
      'DC: Anacostia - Good Hope',
      'DC: Benning Heights - Deanwood',
      'DC: Brookland - Fort Totten',
      'DC: Chinatown - Dupont Circle',
      'DC: Cleveland Park - Woodley Park',
      'DC: Columbia Heights - Mt. Pleasant',
      'DC: Congress Heights - Anacostia Naval Station',
      'DC: Foggy Bottom - GWU',
      'DC: Friendship Heights - Palisades',
      'DC: Howard University - Cardozo/Shaw',
      'DC: Ivy City - Fort Lincoln',
      "DC: L'Enfant Plaza - Waterfront",
      'DC: National Mall',
      'DC: Navy Yard - Potomac Avenue',
      'DC: Petworth - Walter Reed',
      'DC: Shaw/Howard University - Mt Vernon Sq.',
      'DC: Union Station - NOMA',
      'DC: Van Ness - Barnaby Wood',
      'FFX: Annandale - Falls Church',
      'FFX: Baileys Crossroads - Lincolnia',
      'FFX: Fairfax City - Vienna',
      'FFX: Lorton - Fort Belvoir',
      'FFX: Mclean - Tysons Corner',
      'FFX: Mount Vernon - Springfield/Franconia',
      'FFX: Reston - Herndon',
      'LC: Dulles International Airport',
      'MC: Aspen Hill - Olney',
      'MC: Bethesda - Potomac - White Flint',
      'MC: Clarksburg - Gaithersburg',
      'MC: Glenmont -Wheaton - White Oak',
      'MC: Rockville - Twinbrook',
      'MC: Shady Grove',
      'PG: Beltsville - Laurel',
      'PG: Bladensburg - New Carrollton',
      'PG: Bowie - Mitchellville',
      'PG: Branch Avenue - Fort Washington',
      'PG: Brandywine - Clinton',
      'PG: College Park - Hyattsville',
      'PG: Greenbelt',
      'PG: Joint Base Andrews - Upper Marlboro',
      'PG: Largo - Glenarden',
      'PG: Suitland - Capitol Heights',
    ],
    filterOptions: {
      day_of_week: [
        { day_of_week: 'Friday', label: 2 },
        { day_of_week: 'Sunday', label: 4 },
        { day_of_week: 'Mon-Thur', label: 1 },
        { day_of_week: 'Saturday', label: 3 },
      ],
      time_of_day: [
        { time_of_day: 'PM Peak: 3:00 pm – 7:00 pm', label: 4 },
        { time_of_day: 'Midday: 9:00 am - 3:00 pm', label: 3 },
        { time_of_day: 'AM Peak: 6:00 - 9:00 am', label: 2 },
        { time_of_day: 'Late Night: 11:00 pm – 4:00 am', label: 5 },
        { time_of_day: 'Early AM: 4:00 - 6:00 am', label: 1 },
      ],
      equity: [
        { equity: 'Equity Communities' },
        { equity: 'Non-equity Communities' },
      ],
      purpose: [{ purpose: 'Commute' }, { purpose: 'Non-commute' }],
      itinerary_type: [
        { itinerary_type: 'No Viable Itinerary' },
        { itinerary_type: 'Rail Only' },
        { itinerary_type: 'Bus & Rail' },
        { itinerary_type: 'Bus Only' },
      ],
      travel_time_ratio_bin: [
        { travel_time_ratio_bin: '2.5 - 3.0', label: 4 },
        { travel_time_ratio_bin: '1.5 - 2.0', label: 2 },
        { travel_time_ratio_bin: '2.0 - 2.5', label: 3 },
        { travel_time_ratio_bin: '4.5 - 5.0', label: 7 },
        { travel_time_ratio_bin: '1.0 - 1.5', label: 1 },
        { travel_time_ratio_bin: '4.0 - 4.5', label: 6 },
        { travel_time_ratio_bin: '5.0+', label: 8 },
        { travel_time_ratio_bin: '3.0 - 3.5', label: 5 },
      ],
    },
    zoneSystems: [],
  },
};

export default dashboards;
