import React, { useMemo, useRef, useCallback, useState } from 'react';
import './Histogram.scss';
import { FILTERS_LABELS, FILTERS_LABELS_TRUCK } from '../../constants';
import { ReactComponent as TriangleArrowOpen } from '../../images/triangle_arrow_open.svg';
import { ReactComponent as TriangleArrowClosed } from '../../images/triangle_arrow_closed.svg';
import Popover from '../Popover';
import { getHumanReadableNumber } from '../../constants';

function Histogram({
  property,
  data,
  legend,
  hoveredLegend,
  filterValues,
  dataSegmentation,
  displayDataType,
  dataKey,
  isTruckDashboard,
}) {
  const ref = useRef(null);
  const [open, setOpen] = useState(true);

  const [hoveredSegment, setHoveredSegment] = useState(null);

  const max = useMemo(() => {
    return Math?.max(...data?.map(d => d.count));
  }, [data]);

  const total = useMemo(() => {
    return data.reduce((acc, d) => acc + d.count, 0);
  }, [data]);

  const getHeader = useCallback(() => {
    let header;
    if (isTruckDashboard) {
      header = FILTERS_LABELS_TRUCK.find(v => v.value === property)?.label;
    } else {
      header = FILTERS_LABELS.find(v => v.value === property)?.label;
    }
    if (!header) {
      header = property?.chartAt(0).toUpperCase() + property.slice(1);
    }
    return header;
  }, [property]);

  const getExpandIcon = isOpen => {
    return !isOpen ? <TriangleArrowClosed /> : <TriangleArrowOpen />;
  };

  const easySort = useCallback(
    (key, arr) => {
      const isDefault = arr[0] === 'DEFAULT';
      if (isDefault || !key) return arr;
      return filterValues[key] || [];
    },
    [filterValues]
  );

  const segmentedData = useMemo(() => {
    const formatted = data.reduce((acc, d) => {
      const { value, segmentation = 'DEFAULT', count } = d;
      if (acc[value]) {
        acc[value] = { ...acc[value], [segmentation]: count };
      } else {
        acc[value] = { [segmentation]: count };
      }
      return acc;
    }, {});

    const sorted = easySort(property, Object?.keys(formatted))
      ?.map(v => [v, formatted[v]])
      // TODO this is a failsafe but we shouldn't need it
      // CA data currently has mismatch between definitions ("Low Income and Minority Only")
      // and data ("Low Income and Minority") for equity
      .filter(([k, v]) => !!v);

    return sorted;
  }, [property, data, easySort]);

  const getOpacity = useCallback(
    value => {
      if (!hoveredLegend) return 1;
      if (hoveredLegend === value) return 1;
      return 0.3;
    },
    [hoveredLegend]
  );

  const getPercentage = useCallback(
    obj => {
      let displayedTrips;
      if (hoveredLegend) {
        displayedTrips = obj?.[hoveredLegend] ?? 0;
      } else {
        displayedTrips = Object.values(obj).reduce((acc, n) => acc + n, 0);
      }
      return Math.round((displayedTrips / total) * 100);
    },
    [hoveredLegend, total]
  );

  const getTooltipContent = useCallback(() => {
    const filtered = data.reduce((acc, d) => {
      if (d.value === hoveredSegment) {
        acc[d.segmentation] = d.count;
      }
      return acc;
    }, {});

    const total = Math.round(
      Object.values(filtered).reduce((acc, v) => acc + v, 0)
    );

    const totalLabel = getHumanReadableNumber(total);

    if (!dataSegmentation && hoveredSegment) {
      return (
        <div className="Tooltip">
          <div className="Tooltip-title">
            {totalLabel} {hoveredSegment?.toLowerCase()}{' '}
            {dataKey?.includes('pmt') ? 'pmt' : 'trips'}
          </div>
        </div>
      );
    }

    if (!dataSegmentation || !hoveredSegment) return null;
    const sorted = filterValues[dataSegmentation]
      .map(v =>
        filtered?.[v] !== undefined
          ? [v, `${Math.round((filtered[v] / total) * 100)}%`]
          : null
      )
      .filter(Boolean);

    return (
      <div className="Tooltip">
        <div className="Tooltip-title">
          {totalLabel} {hoveredSegment?.toLowerCase()}{' '}
          {dataKey?.includes('pmt') ? 'pmt' : 'trips'}
        </div>
        <div className="Tooltip-underline" />
        {sorted.map(([key, percent]) => (
          <div className="Tooltip-row" key={key}>
            <div
              className="Tooltip-swatch"
              style={{ backgroundColor: legend[key] }}
            />
            <div className="Tooltip-percent">
              {percent} {key}
            </div>
          </div>
        ))}
      </div>
    );
  }, [data, legend, filterValues, dataSegmentation, hoveredSegment]);

  return (
    <>
      <div
        id={`Histogram-${property}-${displayDataType}`}
        className="Histogram"
        ref={ref}
      >
        <div className="Histogram-header">
          <div className="Histogram-open-button" onClick={() => setOpen(!open)}>
            {getExpandIcon(open)}
          </div>
          <div className="Histogram-header-label menu-primary-label">
            {getHeader()}
          </div>
        </div>
        {open ? (
          <div className="chart">
            {segmentedData.map(([k, v]) => (
              <div className="Histogram-section" key={k}>
                <div className="Histogram-label" title={k}>
                  {k}
                </div>
                <div
                  className="Histogram-graph"
                  id={k}
                  onMouseEnter={() => setHoveredSegment(k)}
                  onMouseLeave={() => setHoveredSegment(null)}
                >
                  {easySort(dataSegmentation, Object.keys(v)).map(segment => (
                    <div
                      key={`${k}-${segment}`}
                      className="Histogram-bar"
                      style={{
                        width: `${(v[segment] / max) * 100 || 0}%`,
                        backgroundColor: legend[segment],
                        opacity: getOpacity(segment),
                      }}
                    />
                  ))}
                  <div
                    className="Histogram-percent"
                    style={{
                      ...(hoveredLegend && { color: legend[hoveredLegend] }),
                    }}
                  >
                    {getPercentage(v)}%
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
      {hoveredSegment ? (
        <Popover
          parentRef={document.getElementById(hoveredSegment)}
          isOpen={true}
          position={'top'}
        >
          {getTooltipContent()}
        </Popover>
      ) : null}
    </>
  );
}

export default Histogram;
