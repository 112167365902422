import React, { useCallback, useMemo, useState, useEffect } from 'react';
import classnames from 'classnames';
import './Layout.scss';
import Map from './Map';
import Loader from './Loader';
import { useLoadingStore } from '../store/loaders';
import { loadBlockgroupShapesFromApi, loadTripEndpoints } from '../connectors';
import {
  MAPBOX_ACCESS_TOKEN,
  CLASSIFICATION_METHODS,
  MAPBOX_TILESETS,
  ZONE_SYSTEM_TYPES,
} from '../constants';
import MapZoom from './MapZoom';
import * as d3 from 'd3';

import Legend from './Legend';
import {
  loadCensustractShapesFromApi,
  loadCountyShapesFromApi,
} from '../connectors/blockgroups';
import { useMenuDrawStore } from '../store/menuDraw';

const ref = React.createRef();

function Layout({
  direction,
  activeDashboardInstance,
  data,
  boundingShape,
  // Filters are shared
  filters,
  styleUrl,
  highlightedGeoids,
  setHighlightedGeoidsForDirection,
  mapStore,
  mapDrawStore,
  //
  palette,
  classificationMethod,
  binSize,
  scaleType,
  dataKey,
  zoneOptions,
  zoneType,
  allowMapSync = false,
  uiHidden,
  dashboardType,
  setActiveMenu,
  enableODFlows,
  isTruckDashboard,
}) {
  // DATA
  // Data to pass down into map to join with vector tiles
  const [visualizedData, setVisualizedData] = useState(null);
  const menuDraw = useMenuDrawStore(state => state.menuDraw);
  const setMenuDraw = useMenuDrawStore(state => state.setMenuDraw);
  const [menuOpen, setMenuOpen] = useState(null);

  const [scale, setScale] = useState({});
  const [scaleInputs, setScaleInputs] = useState(null);
  const [odFlowFlag, setOdFlowFlag] = useState(false);

  // Panels
  const [highlightedLegendExtent, setHighlightedLegendExtent] = useState(null);

  const isLoading = useLoadingStore(state => state.isLoading.map.length);

  // TODO come back to resolve with other map state we'll need
  const mapState = mapStore(state => ({
    lng: state.center?.[0]?.toFixed(2),
    lat: state.center?.[1]?.toFixed(2),
    zoom: state.zoom?.toFixed(1),
  }));
  const setMapState = mapStore(state => state.setMapState);

  // TODO test on origin, then resolve destination
  const selectedGeoid = mapStore(state => state.selectedGeoid);
  const setTripEndpointsData = mapStore(state => state.setTripEndpointsData);
  const setSelectedGeoid = mapStore(state => state.setSelectedGeoid);
  const setMinValue = mapStore(state => state.setMinValue);
  const setMaxValue = mapStore(state => state.setMaxValue);
  const minValue = mapStore(state => state.minValue);
  const maxValue = mapStore(state => state.maxValue);
  const setFilterRange = mapStore(state => state.setFilterRange);
  const filterRange = mapStore(state => state.filterRange);
  const setFilteredDashboardData = mapStore(
    state => state.setFilteredDashboardData
  );
  const filteredDashboardData = mapStore(state => state.filteredDashboardData);

  useEffect(() => {
    if (
      filterRange &&
      (filterRange[0] !== minValue || filterRange[1] !== maxValue)
    ) {
      const filteredData = { ...visualizedData?.data };
      if (filteredData) {
        for (const key in filteredData) {
          const value = filteredData?.[key];
          if (value <= filterRange[0] || value >= filterRange[1]) {
            delete filteredData?.[key];
          }
        }
      }
      setFilteredDashboardData(filteredData);
    } else {
      setFilteredDashboardData(null);
    }
  }, [filterRange]);

  useEffect(() => {
    let maxValue = 0;
    let minValue = 9999999999;
    for (const key in visualizedData?.data) {
      if (key !== '-1') {
        const value = visualizedData?.data?.[key];
        if (value > maxValue) {
          maxValue = value;
        }
        if (value < minValue) {
          minValue = value;
        }
      }
    }
    minValue = Math.floor(minValue);
    maxValue = Math.round(maxValue);
    setMaxValue(maxValue);
    setMinValue(minValue);
    setFilterRange([minValue, maxValue]);
  }, [visualizedData]);

  // ----------------------------------------------------------------------------------------
  useEffect(() => {
    if (!selectedGeoid) {
      setTripEndpointsData({});
      setSelectedGeoid(null);
      return;
    }

    const loadEndpoints = async () => {
      // if (selectedGeoid === null)

      const modifiedFilters = JSON.parse(JSON.stringify(filters));

      delete modifiedFilters.zoneSystem;
      modifiedFilters.zone_system = [zoneType];
      let tripEndpointsData = await loadTripEndpoints(
        activeDashboardInstance,
        direction,
        // TODO we need to come back and standardize how we handle
        // prepending 0's vs not
        selectedGeoid,
        modifiedFilters,
        15
      );

      tripEndpointsData.forEach(item => {
        item.daily_trips = item?.trip_result;
        delete item?.trip_result;
        item.daily_pmt = item?.pmt_result;
        delete item?.pmt_result;
      });
      const updateGeoId = geoid => {
        const geoIdLength = geoid.toString().length;
        if (
          (geoIdLength === 10 && zoneType === ZONE_SYSTEM_TYPES.censusTract) ||
          (geoIdLength === 11 && zoneType === ZONE_SYSTEM_TYPES.blockGroup) ||
          (geoIdLength === 4 && zoneType === ZONE_SYSTEM_TYPES.county)
        ) {
          return `0${geoid}`;
        }

        return geoid;
      };

      tripEndpointsData.forEach(item => {
        item.geoid = updateGeoId(item.geoid);
      });

      tripEndpointsData = tripEndpointsData.map(v => ({
        ...v,
        // TODO see above TODO
        geoid: `${v.geoid}`,
      }));

      const geoids = [
        '' + selectedGeoid,
        ...tripEndpointsData.map(({ geoid }) => '' + geoid),
      ];
      let geoDetails = [];
      if (modifiedFilters.zone_system.includes(ZONE_SYSTEM_TYPES.county)) {
        geoDetails = await loadCountyShapesFromApi(geoids);
      } else if (
        modifiedFilters.zone_system.includes(ZONE_SYSTEM_TYPES.censusTract)
      ) {
        geoDetails = await loadCensustractShapesFromApi(geoids);
        geoDetails.forEach(item => {
          item.populationCentroid = item?.geometricCentroid;
        });
      } else if (
        modifiedFilters.zone_system.includes(ZONE_SYSTEM_TYPES.blockGroup)
      ) {
        geoDetails = await loadBlockgroupShapesFromApi(geoids);
      } else {
        const zoneSystem = activeDashboardInstance?.zoneSystems?.find(item =>
          modifiedFilters.zone_system.includes(item?.name)
        );
        geoDetails = zoneSystem?.zones;
        geoDetails.forEach(item => {
          item.populationCentroid = item?.centroid;
          item.geoId = JSON.stringify(item.label);
        });
      }
      tripEndpointsData = tripEndpointsData.map(d => ({
        ...d,
        point: geoDetails.find(({ geoId }) => geoId === '' + d.geoid)
          ?.populationCentroid,
      }));

      setTripEndpointsData({
        selected: {
          geoid: selectedGeoid,
          point: geoDetails.find(({ geoId }) => geoId === '' + selectedGeoid)
            ?.populationCentroid,
        },
        endpoints: tripEndpointsData,
      });
    };
    loadEndpoints();
  }, [
    activeDashboardInstance,
    direction,
    filters,
    selectedGeoid,
    setTripEndpointsData,
  ]);
  // ----------------------------------------------------------------------------------------

  useEffect(() => {
    if (!activeDashboardInstance) return;
    if (!mapState.lng && !mapState.lat && !mapState.zoom) {
      setMapState({
        center: activeDashboardInstance.mapCenter,
        zoom: activeDashboardInstance.mapZoom,
      });
    }
  }, [
    activeDashboardInstance,
    setMapState,
    mapState.lat,
    mapState.lng,
    mapState.zoom,
  ]);

  const aggregateData = useCallback(
    prevData => {
      // Incoming data will be by block group
      let next = { ...prevData };
      const customZoneSystem =
        activeDashboardInstance?.zoneSystems &&
        activeDashboardInstance?.zoneSystems.find(v => v.name === zoneType);

      if (customZoneSystem) {
        const zones = customZoneSystem?.zones;

        return Object.fromEntries(
          zones.map(({ blockgroups = [], label }) => {
            const count = next?.[label] ?? 0;
            return [label, count];
          })
        );
      }

      return next;
    },
    [zoneType, activeDashboardInstance]
  );

  useEffect(() => {
    if (!data[zoneType] || !dataKey) return;
    let next = Object.fromEntries(
      Object.entries(data[zoneType]).map(([k, v]) => [k, v[dataKey]])
    );
    // Handle visualized data aggregation
    next = aggregateData(next);
    setVisualizedData({ zoneType, data: next });
  }, [data, dataKey, aggregateData, zoneType]);

  // Adding filters or other properties to scaleKey and the api call will let us
  // request a new scale for different set ups as needed and maintain ones we already have
  const scaleKey = useMemo(() => {
    if (!visualizedData?.data) return;
    const filterValues = Object.values(filters).sort();
    const filterKey = filterValues.length ? `-${filterValues.join('-')}` : '';
    // TODO this is another bad solution and needs to be redone
    // Currently runs twice each data change
    const dataSignal = `${Math.min(
      ...Object.values(visualizedData?.data)
    )}-${Math.max(...Object.values(visualizedData?.data))}`;
    return `${dataSignal}-${direction}-${classificationMethod}-${binSize}-${dataKey}-${visualizedData?.zoneType}${filterKey}`;
  }, [
    visualizedData,
    direction,
    classificationMethod,
    binSize,
    filters,
    dataKey,
  ]);

  const setScaleForData = useCallback(async () => {
    if (scale?.[scaleKey]) return;

    let scaleValues;

    const dataVals = Object.values(visualizedData?.data).filter(
      item => item !== 0
    );

    if (dataVals?.[0] === undefined) return;

    scaleValues = CLASSIFICATION_METHODS?.[classificationMethod]
      ?.fn(binSize, dataVals)
      .sort((a, b) => a - b);

    setScale(s => ({ ...s, [scaleKey]: scaleValues }));
  }, [visualizedData, binSize, classificationMethod, scale, scaleKey]);

  useEffect(() => {
    if (!visualizedData?.data) return;
    setScaleForData();
  }, [visualizedData, setScaleForData]);

  // Make sure we only update the prop we pass down to another value and not undefined
  useEffect(() => {
    let scaleInputsForRender = scale?.[scaleKey];
    scaleInputsForRender = [...new Set(scaleInputsForRender)].sort(
      (a, b) => a - b
    );
    if (scaleInputsForRender && scaleInputsForRender.length) {
      setScaleInputs(scaleInputsForRender);
    }
  }, [scaleKey, scale, dataKey]);

  const isCustomZone = useMemo(
    () => !Object.keys(MAPBOX_TILESETS).includes(zoneType),
    [zoneType]
  );

  const mapDataSource = useMemo(() => {
    const promotedId =
      activeDashboardInstance?.type?.toLowerCase() === 'transit'
        ? 'geomarket'
        : 'GEOID';

    if (isCustomZone) {
      const customZoneSystem =
        activeDashboardInstance?.zoneSystems &&
        activeDashboardInstance?.zoneSystems.find(v => v.name === zoneType);

      const geojson = {
        type: 'FeatureCollection',
        features: customZoneSystem.zones.map(z => ({
          type: 'Feature',
          geometry: z.shape,
          properties: {
            GEOID: z?.label?.toString(),
            zoneName:
              z.name === `${customZoneSystem.name} zone`
                ? `${z.name.replaceAll(' ', '_')}_${z.label}`
                : z.name,
            [promotedId]: z?.label?.toString(),
          },
        })),
      };

      return {
        source: { type: 'geojson', data: geojson, promoteId: promotedId },
        promotedId: promotedId,
        sourceLayer: null,
      };
    }

    return {
      source: {
        type: 'vector',
        url: MAPBOX_TILESETS[zoneType].url,
        // promoteId uses this property value as feature id since we have none
        promoteId: { [MAPBOX_TILESETS[zoneType].layerName]: promotedId },
      },
      sourceLayer: MAPBOX_TILESETS[zoneType].layerName,
      promotedId: promotedId,
    };
  }, [zoneType, isCustomZone, activeDashboardInstance]);

  useEffect(() => {
    menuDraw && setActiveMenu && setMenuOpen(menuDraw);
  }, [menuDraw]);

  return (
    <div className="Layout" ref={ref}>
      <div className="Layout-layout">
        <div className="Layout-layout-map-container">
          {isLoading ? (
            <div className="Layout-map-loader-container">
              <Loader />
            </div>
          ) : null}

          {visualizedData?.data ? (
            <Map
              mapId={direction}
              direction={direction}
              boundingShape={boundingShape}
              key="mapbox-gl"
              url={styleUrl}
              renderLib="mapbox-gl"
              accessToken={MAPBOX_ACCESS_TOKEN}
              data={filteredDashboardData || visualizedData?.data}
              dataKey={dataKey}
              choroplethPalette={palette}
              choroplethType={scaleType}
              scaleInputs={scaleInputs}
              highlightedLegendExtent={highlightedLegendExtent}
              highlightedGeoids={highlightedGeoids}
              onHighlightedGeoidsChange={setHighlightedGeoidsForDirection}
              mapStore={mapStore}
              zoneType={visualizedData.zoneType}
              customZones={
                isCustomZone && activeDashboardInstance?.zoneSystems.length
                  ? activeDashboardInstance?.zoneSystems
                  : null
              }
              initialCenter={activeDashboardInstance?.mapCenter}
              initialZoom={activeDashboardInstance?.mapZoom}
              //
              promotedId={mapDataSource?.promotedId}
              dataSource={mapDataSource?.source}
              dataSourceLayer={mapDataSource?.sourceLayer}
              mapDrawStore={mapDrawStore}
              activeDashboardInstance={activeDashboardInstance}
              odFlowsFeatureFlag={odFlowFlag}
              isTruckDashboard={isTruckDashboard}
            />
          ) : null}

          <div className="Layout-layout-upper-left-fixed">
            <div className="Layout-layout-map-zoom-container">
              <MapZoom
                activeDashboardInstance={activeDashboardInstance}
                highlightedGeoids={highlightedGeoids}
                mapStore={mapStore}
                direction={direction}
                mapDrawStore={mapDrawStore}
                allowMapSync={allowMapSync}
                zoneType={zoneType}
                boundingShape={boundingShape}
                setMenuOpen={ev => {
                  setMenuOpen(ev);
                  setActiveMenu && setActiveMenu(ev);
                  setMenuDraw(null);
                }}
                menuOpen={menuOpen}
                setOdFlowFlag={setOdFlowFlag}
                odFlowFlag={odFlowFlag}
                enableODFlows={enableODFlows}
                dataKey={dataKey}
              />
            </div>
          </div>
          <div
            className={classnames('Layout-layout-lower-left-fixed', {
              transit: dashboardType === 'transit',
            })}
          >
            <div
              className={classnames('Layout-legend-container', {
                hidden: uiHidden,
              })}
            >
              <Legend
                data={filteredDashboardData || visualizedData?.data}
                dataKey={dataKey}
                direction={direction}
                setHighlightedExtent={setHighlightedLegendExtent}
                isLoading={isLoading}
                mapStore={mapStore}
                zoneOptions={zoneOptions}
                zoneType={zoneType}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
