import { useCallback, useEffect, useRef, useState } from 'react';
import { TIMEOUT_TIME, ZONE_SYSTEM_TYPES } from '../constants';
import { loadJson } from '../connectors/file';

const waitForStyleLoad = (map, res) => {
  if (!map || !map.isStyleLoaded()) {
    setTimeout(() => waitForStyleLoad(map, res), TIMEOUT_TIME);
  } else {
    res();
  }
};

const loadDataForLayers = async (layers, cache) => {
  const newCache = { ...cache };
  for (const layer of layers) {
    if (!newCache[layer.id]) {
      newCache[layer.id] = await loadJson(layer?.s3FileId?.split('/').pop());
    }
  }
  return newCache;
};

const addMapLayers = (
  map,
  toAdd,
  layers,
  layerData,
  beforeId,
  setPopupContent,
  clearPopup
) => {
  toAdd.forEach(id => {
    const layer = layers.find(l => l.id === id);

    if (!map.getSource(layer?.layerId)) {
      map.addSource(layer?.layerId, {
        type: 'geojson',
        data: layerData[layer?.layerId],
      });
    }

    map.addLayer(
      {
        id: layer?.id,
        source: layer?.layerId,
        type: layer?.type,
        paint: layer?.paint,
      },
      beforeId
    );

    if (layer?.tooltipField) {
      map.on('mousemove', id, e => {
        setPopupContent(
          e.lngLat,
          `${layer.tooltipField} : ${
            e.features[0].properties[layer.tooltipField]
          }`
        );
      });

      map.on('mouseleave', id, clearPopup);
    }
  });
};

const removeMapLayers = (map, toRemove) => {
  toRemove.forEach(id => {
    if (map.getLayer(id)) map.removeLayer(id);
    if (map.getSource(id)) map.removeSource(id);
  });
};

const updateMapLayers = (map, toUpdate, layers) => {
  toUpdate.forEach(id => {
    if (!map.getLayer(id)) return;
    const layer = layers.find(l => l.id === id);
    map.setLayoutProperty(id, 'visibility', layer.visibility);

    Object.entries(layer.paint).map(([name, value]) =>
      map.setPaintProperty(id, name, value)
    );
  });
};

const synchronizeMapLayers = (
  mappedLayers,
  layers,
  layerData,
  map,
  beforeId,
  setPopupContent,
  clearPopup,
  isZoneUpdated
) => {
  if (!map) return;
  const newLayerIds = layers.map(({ id }) => id);

  let layersToAdd = newLayerIds.filter(l => !mappedLayers.includes(l));

  if (isZoneUpdated) {
    layersToAdd = mappedLayers;
  }

  addMapLayers(
    map,
    layersToAdd,
    layers,
    layerData,
    beforeId,
    setPopupContent,
    clearPopup
  );

  const layersToRemove = mappedLayers.filter(l => !newLayerIds.includes(l));
  removeMapLayers(map, layersToRemove);

  const layersToUpdate = mappedLayers.filter(l => newLayerIds.includes(l));
  updateMapLayers(map, layersToUpdate, layers);

  return newLayerIds;
};

/*
 * Get a version of this layer with just `type` properties.
 */
const getTypeLayer = (layer, type) => {
  return {
    ...layer,
    id: `${layer.id}-${type}`,
    layerId: layer.id,
    type,
    paint: Object.fromEntries(
      Object.entries(layer.paint).filter(([k]) => k.startsWith(type))
    ),
  };
};

/*
 * Expand map layers, mostly involves splitting fill layers into fill + line
 * layers
 */
const expandMapLayers = layers => {
  return layers
    .map(layer => {
      if (layer.type === 'fill') {
        return [getTypeLayer(layer, 'fill'), getTypeLayer(layer, 'line')];
      } else {
        return {
          ...layer,
          layerId: layer.id,
        };
      }
    })
    .flat();
};

function CustomLayers({
  before,
  layers,
  map,
  setPopupContent,
  clearPopup,
  zoneType,
}) {
  const mappedLayers = useRef([]);
  const layerData = useRef({});
  const [initialZoneType, setInitialZoneType] = useState(
    ZONE_SYSTEM_TYPES.censusTract
  );

  const synchronizeCustomLayers = useCallback(
    async layers => {
      if (!map) return;
      // TODO I do not know why this is causing issues
      await new Promise(resolve => waitForStyleLoad(map, resolve));

      layerData.current = await loadDataForLayers(layers, layerData.current);
      const isZoneUpdated = zoneType !== initialZoneType;
      mappedLayers.current = synchronizeMapLayers(
        mappedLayers.current,
        expandMapLayers(layers),
        layerData.current,
        map,
        before,
        setPopupContent,
        clearPopup,
        isZoneUpdated
      );

      isZoneUpdated && setInitialZoneType(zoneType);
    },
    [
      before,
      map,
      setPopupContent,
      clearPopup,
      setInitialZoneType,
      zoneType,
      initialZoneType,
    ]
  );

  useEffect(() => {
    if (!map) return;
    map.on('style.load', () => synchronizeCustomLayers(layers));
  }, [map, layers, synchronizeCustomLayers]);

  useEffect(() => {
    synchronizeCustomLayers(layers);
  }, [layers, synchronizeCustomLayers]);

  return null;
}

export default CustomLayers;
