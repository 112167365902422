import React, { useEffect } from 'react';
import Slider from 'rc-slider';
import _debounce from 'lodash/debounce';
import 'rc-slider/assets/index.css';
import './MetricRangeFilter.scss';
import { useTranslation } from 'react-i18next';
import { getHumanReadableNumber, dataKeys } from '../constants';

const MetricRangeFilter = ({ mapStore, dataKey }) => {
  const { t } = useTranslation();
  const dataMinValue = mapStore(state => state.minValue);
  const dataMaxValue = mapStore(state => state.maxValue);
  const filterRange = mapStore(state => state.filterRange);
  const setFilterRange = mapStore(state => state.setFilterRange);
  const setFilteredDashboardData = mapStore(
    state => state.setFilteredDashboardData
  );

  useEffect(() => {
    setFilteredDashboardData(null);
  }, [dataKey]);

  const handleChange = (event, key) => {
    const value = parseInt(event?.target?.value || 0);
    const range = [...filterRange];
    if (key === 'minValue') {
      range[0] = value;
    } else if (key === 'maxValue') {
      range[1] = value;
    }
    setFilterRange(range);
  };
  return (
    <div className="TripsCount">
      <hr className="TripsCount-horizontal-line" />
      <div className="TripsCount-header">
        <div className="TripsCount-header-label">
          {`${t('tripsCountFilter.filterMapBy')} ${
            dataKey === 'daily_trips'
              ? 'Total Vehicle Trips'
              : 'Vehicle Miles Traveled'
          }`}
        </div>
        <button
          onClick={() => {
            setFilteredDashboardData(null);
            setFilterRange([dataMinValue, dataMaxValue]);
          }}
          className="TripsCount-header-button"
        >
          {t('tripsCountFilter.reset')}
        </button>
      </div>
      <div className="TripsCount-slider-container">
        {/* <div id="slider-label">
          <span id="label-left">{dataMinValue}</span>
          <span id="label-right">{getHumanReadableNumber(dataMaxValue)}</span>
        </div> */}
        <Slider
          className="Tripscount-slider"
          range
          min={dataMinValue}
          max={dataMaxValue}
          defaultValue={[dataMinValue, dataMaxValue]}
          value={filterRange}
          onChange={event => {
            setFilterRange(event);
          }}
        />
        <div className="TripsCount-slider-container-buttons">
          <div className="TripsCount-slider-container-buttons-block">
            <input
              className="TripsCount-slider-container-buttons-input"
              value={filterRange?.[0]}
              onChange={ev => handleChange(ev, 'minValue')}
            />
            <div>{t('tripsCountFilter.minCount')}</div>
          </div>
          <div className="TripsCount-slider-container-buttons-block">
            <input
              className="TripsCount-slider-container-buttons-input"
              value={filterRange?.[1]}
              onChange={ev => handleChange(ev, 'maxValue')}
            />
            <div>{t('tripsCountFilter.maxCount')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetricRangeFilter;
