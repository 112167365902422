import * as d3 from 'd3';
import _ from 'lodash';
import {
  DATA_API_URL,
  DASHBOARD_API_URL,
  VIEWS_API_URL,
  API_KEY,
  WDAY_TYPE,
  MAP_LAYERS_API_URL,
  ZONE_SYSTEM_TYPES,
  ODFLOWS_API_URL,
  calculateAverageByDaysForTotalData,
  calculateAverageByDays,
  calculateAverageByDaysForFilterData,
} from '../constants';
import {
  getAreaForCensusTractsApi,
  getAreaForCountiesApi,
  getAreaApi as loadBlockgroupsFromApi,
} from './blockgroups';

// Example queries
// const byEquity = "SELECT equity, count(*) as count from hive_metastore.passengerohio.flow GROUP BY equity";
// const blockGroups = "SELECT origin_geo, SUM(daily_trips) FROM hive_metastore.passengerohio.flow GROUP BY origin_geo";

export const getMapLayers = async (dashboardId, accessToken, clientId) => {
  const response = await fetch(
    `${MAP_LAYERS_API_URL}/${dashboardId}/maplayers`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: accessToken,
        'x-lcs-clientid': clientId,
      },
    }
  );

  return (await response.json()).data;
};

export const getStateAndCountyData = async (dashboardId, accessToken) => {
  const response = await fetch(
    `${DASHBOARD_API_URL}/${dashboardId}/studyareadetails`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: accessToken,
      },
    }
  );

  return (await response.json()).data;
};

export const totalDataQuery = async (
  userId,
  dashboardId,
  clientName,
  updatedFilters
) => {
  const response = await fetch(`${DATA_API_URL}/totaldata`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId,
      dashboardId,
      clientName,
      filterValues: updatedFilters,
    }),
  });

  return (await response.json()).data;
};

export const getGeoIdsDataIntersectedByShape = async (
  shape,
  zoneSystemIds,
  selection,
  zoneType,
  geoId
) => {
  const response = await fetch(`${DATA_API_URL}/selectiongeoids`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      shape,
      zoneSystemIds,
      selection,
      zoneType,
      geoId,
    }),
  });

  return (await response.json()).data;
};

export const odFlowsQuery = async (
  userId,
  dashboardId,
  clientName,
  updatedFilters,
  direction,
  limit
) => {
  const response = await fetch(`${DATA_API_URL}/odflows`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId,
      dashboardId,
      clientName,
      filterValues: updatedFilters,
      direction,
      flowsLimit: limit,
    }),
  });

  return (await response.json()).data;
};

export const queryApi = async query => {
  const response = await fetch(DATA_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      queryString: query,
    }),
  });

  return (await response.json()).data;
};

export const dashboardQuery = async (
  userId,
  dashboardId,
  clientName,
  updatedFilters,
  direction
) => {
  const response = await fetch(`${DATA_API_URL}/dashboardquery`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId,
      dashboardId,
      direction,
      clientName,
      filterValues: updatedFilters,
    }),
  });

  return (await response.json()).data;
};

export const filtersQuery = async (
  userId,
  dashboardId,
  clientName,
  updatedFilters,
  // TODO remove direction
  direction,
  dataKey,
  segmentation,
  dimensions
) => {
  const response = await fetch(`${DATA_API_URL}/filtersquery`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId,
      dashboardId,
      clientName,
      dataKey,
      dimensions,
      ...(direction && { direction }),
      filterValues: updatedFilters,
      segmentation,
    }),
  });

  const data = (await response.json()).data;

  return data;
};

export const downloadDataQuery = async (
  userId,
  dashboardName,
  clientName,
  dataKey,
  dimensions,
  filters
) => {
  const response = await fetch(`${DATA_API_URL}/downloaddata`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId,
      dashboardName,
      clientName,
      dataKey,
      dimensions,
      filterValues: filters,
    }),
  });

  return (await response.json()).data;
};

export const getScaleValues = async (
  table,
  userId,
  dashboardName,
  direction
) => {
  const directionField =
    direction === 'destination' ? 'destination_geo' : 'origin_geo';

  const query = `SELECT
  min(daily_trips),
  percentile(daily_trips, 0.2),
  percentile(daily_trips, 0.4),
  percentile(daily_trips, 0.6),
  percentile(daily_trips, 0.8),
  min(daily_pmt),
  percentile(daily_pmt, 0.2),
  percentile(daily_pmt, 0.4),
  percentile(daily_pmt, 0.6),
  percentile(daily_pmt, 0.8)
  FROM (
    SELECT sum(daily_trips) as daily_trips, sum(daily_pmt) as daily_pmt
    FROM ${table} WHERE user_id='${userId}' AND dashboard_name='${dashboardName}'
    GROUP BY ${directionField}
  )`;
  const result = await queryApi(query);
  let sorted = Object.entries(result[0]).reduce(
    (acc, [k, v]) => {
      if (k.includes('daily_trips')) {
        acc['daily_trips'].push(v);
      }
      if (k.includes('daily_pmt')) {
        acc['daily_pmt'].push(v);
      }
      return acc;
    },
    { daily_pmt: [], daily_trips: [] }
  );
  sorted = Object.fromEntries(
    Object.entries(sorted).map(([k, v]) => [k, v.sort(d3.ascending)])
  );

  return sorted;
};

const getWhere = filters => {
  let where;
  if (filters && Object.keys(filters).length) {
    where = `AND ${Object.entries(filters)
      .filter(([k, v]) => v !== 'all')
      .map(([k, v]) => {
        if (Array.isArray(v)) {
          return `${k} IN (${v.map(val => `'${val}'`).join(',')})`;
        }
        return `${k} = '${v}'`;
      })
      .join(' AND ')}`;
  }
  return where;
};

export const loadData = async (
  table,
  clientName,
  userId,
  dashboardId,
  dashboardName,
  direction,
  filters,
  zoneSystems
) => {
  const directionField =
    direction === 'destination' ? 'destination_geo' : 'origin_geo';

  const { period, wday_type, mode } = filters;
  const clonedFilters = JSON.parse(JSON.stringify(filters));

  let weightedAvg = 'none';
  const weightPeriod = false;
  const weightWeekday = wday_type && wday_type.length > 1;
  if (weightPeriod && weightWeekday) {
    weightedAvg = 'period-wday_type';
    delete clonedFilters.period;
    delete clonedFilters.wday_type;
  } else if (weightPeriod) {
    weightedAvg = 'period';
    delete clonedFilters.period;
  } else if (weightWeekday) {
    weightedAvg = 'wday_type';
    delete clonedFilters.wday_type;
  }

  let result;

  switch (weightedAvg) {
    case 'period-wday_type': {
      const combos = period.reduce((acc, p) => {
        for (const w of wday_type) {
          acc.push({ period: p, wday_type: w });
        }
        return acc;
      }, []);

      let avgs = [];
      let returnedGeoIds = new Set();

      for (const combo of combos) {
        const { period: p, wday_type: w } = combo;
        const updatedFilters = {
          ...clonedFilters,
          wday_type: [w],
          period: [p],
        };

        let response = await dashboardQuery(
          userId,
          dashboardId,
          clientName,
          updatedFilters,
          directionField
        );

        if (!response || !response.length) continue;

        if (w === WDAY_TYPE.weekday) {
          response = response.map(r => ({
            ...r,
            daily_trips: r.daily_trips * 5,
            daily_pmt: r.daily_pmt * 5,
          }));
        }

        let avg = d3.group(
          response,
          d => d.geoid,
          d => d.mode
        );

        returnedGeoIds = new Set([...avg.keys(), ...returnedGeoIds]);
        avgs.push(avg);
      }

      result = [...returnedGeoIds].reduce((acc, geoid) => {
        mode.forEach(m => {
          const found = avgs
            .map(a => a && a.get(geoid)?.get(m)?.[0])
            .filter(Boolean);
          if (!found.length) return acc;
          const dailyTripsVals = found.map(f => f.daily_trips);
          const dailyPmtVals = found.map(f => f.daily_pmt);
          acc.push({
            geoid,
            mode: m,
            daily_trips: d3.mean(dailyTripsVals),
            daily_pmt: d3.mean(dailyPmtVals),
          });
        });
        return acc;
      }, []);
      break;
    }
    case 'period': {
      let avgs = [];
      let returnedGeoIds = new Set();
      for (const p of period) {
        const updatedFilters = {
          ...clonedFilters,
          period: [p],
        };

        let avg = await dashboardQuery(
          userId,
          dashboardId,
          clientName,
          updatedFilters,
          directionField
        );

        if (!avg || !avg.length) continue;

        avg = d3.group(
          avg,
          d => d.geoid,
          d => d.mode
        );

        returnedGeoIds = new Set([...avg.keys(), ...returnedGeoIds]);

        avgs.push(avg);
      }

      result = [...returnedGeoIds].reduce((acc, geoid) => {
        mode.forEach(m => {
          const found = avgs
            .map(a => a && a.get(geoid)?.get(m)?.[0])
            .filter(Boolean);
          if (!found.length) return acc;
          const dailyTripsVals = found.map(f => f.daily_trips);
          const dailyPmtVals = found.map(f => f.daily_pmt);
          acc.push({
            geoid,
            mode: m,
            daily_trips: d3.mean(dailyTripsVals),
            daily_pmt: d3.mean(dailyPmtVals),
          });
        });
        return acc;
      }, []);
      break;
    }
    case 'wday_type': {
      let avgs = [];
      let returnedGeoIds = new Set();
      for (const w of wday_type) {
        const updatedFilters = {
          ...clonedFilters,
          wday_type: [w],
        };

        let response = await dashboardQuery(
          userId,
          dashboardId,
          clientName,
          updatedFilters,
          directionField
        );

        if (!response || !response.length) continue;

        if (w === WDAY_TYPE.weekday) {
          response = response.map(r => ({
            ...r,
            daily_trips: r.daily_trips * 5,
            daily_pmt: r.daily_pmt * 5,
          }));
        }

        let avg = d3.group(
          response,
          d => d.geoid,
          d => d.mode
        );

        returnedGeoIds = new Set([...avg.keys(), ...returnedGeoIds]);
        avgs.push(avg);
      }

      result = [...returnedGeoIds].reduce((acc, geoid) => {
        mode.forEach(m => {
          const found = avgs
            .map(a => a && a.get(geoid)?.get(m)?.[0])
            .filter(Boolean);
          if (!found.length) return acc;
          const dailyTripsVals = found.map(f => f.daily_trips);
          const dailyPmtVals = found.map(f => f.daily_pmt);
          acc.push({
            geoid,
            mode: m,
            daily_trips: calculateAverageByDays(wday_type, dailyTripsVals),
            daily_pmt: calculateAverageByDays(wday_type, dailyPmtVals),
          });
        });
        return acc;
      }, []);
      break;
    }
    case 'none':
    default: {
      result = await dashboardQuery(
        userId,
        dashboardId,
        clientName,
        clonedFilters,
        directionField
      );
      break;
    }
  }

  if (!result) return;

  let totalTrips = Object.values(result).reduce((acc, v) => {
    if (!acc[v.mode]) {
      acc[v.mode] = 0;
    }
    acc[v.mode] = acc[v.mode] + v.daily_trips;
    return acc;
  }, {});

  totalTrips.total = Object.values(totalTrips).reduce((acc, v) => acc + v, 0);

  let resultHash = result.reduce((acc, datum) => {
    let { geoid, daily_trips, daily_pmt, mode } = datum;

    geoid = `${geoid}`;

    if (
      (geoid.length === 10 &&
        clonedFilters.zone_system.includes(ZONE_SYSTEM_TYPES.censusTract)) ||
      (geoid.length === 11 &&
        clonedFilters.zone_system.includes(ZONE_SYSTEM_TYPES.blockGroup)) ||
      (geoid.length === 4 &&
        clonedFilters.zone_system.includes(ZONE_SYSTEM_TYPES.county)) ||
      (geoid.length === 11 &&
        !Object.values(ZONE_SYSTEM_TYPES)?.includes(
          clonedFilters?.zone_system[0]
        ))
    ) {
      geoid = `0${geoid}`;
    }

    const isBike = mode === 'Bike';
    const isWalk = mode === 'Walk';

    if (!acc[geoid]) {
      acc[geoid] = {
        daily_trips,
        daily_pmt,
        bike_trips: isBike ? daily_trips : 0,
        walk_trips: isWalk ? daily_trips : 0,
      };
      return acc;
    }
    acc[geoid].daily_trips = acc[geoid].daily_trips + daily_trips;
    acc[geoid].daily_pmt = acc[geoid].daily_pmt + daily_pmt;
    if (isBike) {
      acc[geoid].bike_trips = acc[geoid].bike_trips + daily_trips;
    }
    if (isWalk) {
      acc[geoid].walk_trips = acc[geoid].walk_trips + daily_trips;
    }

    return acc;
  }, {});

  const geoIds = Object.keys(resultHash);
  let zoneSystemAreas = [];
  if (clonedFilters.zone_system.includes(ZONE_SYSTEM_TYPES.censusTract)) {
    zoneSystemAreas = await getAreaForCensusTractsApi(geoIds);
  } else if (clonedFilters.zone_system.includes(ZONE_SYSTEM_TYPES.county)) {
    zoneSystemAreas = await getAreaForCountiesApi(geoIds);
  } else if (clonedFilters.zone_system.includes(ZONE_SYSTEM_TYPES.blockGroup)) {
    zoneSystemAreas = await loadBlockgroupsFromApi(geoIds);
  } else {
    zoneSystemAreas = zoneSystems
      .find(item => item.name === clonedFilters.zone_system[0])
      .zones.map(item => {
        item.geoId = item.label.toString();
        return item;
      });
  }

  resultHash = Object.entries(resultHash).reduce((acc, [geoid, data]) => {
    // TODO this is broken for CA. We should decide if we want to hardcode again or
    // just wait for a better solution from the API
    const zoneSystemAreaDetails = _.find(zoneSystemAreas, { geoId: geoid });
    const area = zoneSystemAreaDetails?.area ?? 0;

    acc[geoid] = {
      daily_trips: data.daily_trips,
      daily_pmt: data.daily_pmt,
      share_of_total_trips: (data.daily_trips / totalTrips.total) * 100,
      share_of_bike_trips: (data.bike_trips / data.daily_trips) * 100 || 0,
      share_of_walk_trips: (data.walk_trips / data.daily_trips) * 100 || 0,
      share_of_active_transportation_trips:
        ((data.bike_trips + data.walk_trips) / data.daily_trips) * 100,
      trip_density: area
        ? Math.ceil(data.daily_trips / (area * 3.86102e-7))
        : 0,
    };
    return acc;
  }, {});

  return resultHash;
};

const loadSummaryDataForField = async (
  table,
  userId,
  dashboardName,
  dataKey,
  filters,
  field,
  options
) => {
  const { segmentation } = options;
  let query = `SELECT ${field} as value`;
  if (segmentation) {
    query = `${query}, ${segmentation} as segmentation`;
  }
  query = `${query}, sum(${dataKey}) as count FROM ${table} WHERE user_id='${userId}' AND dashboard_name='${dashboardName}'`;
  const where = getWhere(filters);
  if (where) query = `${query} ${where}`;
  query = `${query} GROUP BY ${field}`;
  if (segmentation) {
    query = `${query}, ${segmentation}`;
  }
  const result = await queryApi(query);
  return result;
};

export const loadSummaryData = async (
  direction,
  dashboardId,
  table,
  userId,
  clientName,
  filters,
  dataKey,
  options
) => {
  const { segmentation } = options;
  const directionField =
    direction === 'destination' ? 'destination_geo' : 'origin_geo';

  const getSummary = results => {
    const groupEntities = {};
    results.map(item => {
      Object.keys(item).forEach(key => {
        if (item[key] === null) {
          delete item[key];
        }
      });
    });
    results.forEach(item => {
      if (segmentation && Object.keys(item).length > 3) {
        delete item[segmentation];
      }
      for (const key in item) {
        if (
          item.hasOwnProperty(key) &&
          key !== 'count' &&
          key !== 'segmentation'
        ) {
          if (!groupEntities[key]) {
            groupEntities[key] = [];
          }

          const newItem = { value: item[key] };
          if (item.count !== undefined) {
            newItem.count = item.count;
          }

          if (item.segmentation !== undefined) {
            newItem.segmentation = item.segmentation;
          }

          groupEntities[key].push(newItem);
        }
      }
    });

    return groupEntities;
  };

  const { wday_type, period } = filters;
  const clonedFilters = JSON.parse(JSON.stringify(filters));

  let weightedAvg = 'none';
  const weightPeriod = false;
  const weightWeekday = wday_type && wday_type.length > 1;
  if (weightPeriod && weightWeekday) {
    weightedAvg = 'period-wday_type';
    delete clonedFilters.period;
    delete clonedFilters.wday_type;
  } else if (weightPeriod) {
    weightedAvg = 'period';
    delete clonedFilters.period;
  } else if (weightWeekday) {
    weightedAvg = 'wday_type';
    delete clonedFilters.wday_type;
  }

  let avgs = [];

  switch (weightedAvg) {
    case 'period-wday_type': {
      const combos = period.reduce((acc, p) => {
        for (const w of wday_type) {
          acc.push({ period: p, wday_type: w });
        }
        return acc;
      }, []);

      for (const combo of combos) {
        const { period: p, wday_type: w } = combo;
        const updatedFilters = {
          ...clonedFilters,
          wday_type: [w],
          period: [p],
        };

        let avg = await filtersQuery(
          userId,
          dashboardId,
          clientName,
          updatedFilters,
          directionField,
          dataKey,
          segmentation
        );

        if (!avg || !avg.length) continue;

        avg = getSummary(avg);

        if (w === WDAY_TYPE.weekday) {
          // Weight the weekday average for 5 days
          const updatedAvg = { ...avg };
          updatedAvg['wday_type'] = updatedAvg['wday_type'].map(item => ({
            ...item,
            count: item.count * 5,
          }));
          avgs.push(updatedAvg);
        } else {
          avgs.push(avg);
        }
      }

      break;
    }
    case 'period': {
      for (const p of period) {
        const updatedFilters = {
          ...clonedFilters,
          period: [p],
        };

        let avg = await filtersQuery(
          userId,
          dashboardId,
          clientName,
          updatedFilters,
          directionField,
          dataKey,
          segmentation
        );

        if (!avg || !avg.length) continue;

        avg = getSummary(avg);

        avgs.push(avg);
      }

      break;
    }
    case 'wday_type': {
      for (const w of wday_type) {
        const updatedFilters = {
          ...clonedFilters,
          wday_type: [w],
        };

        let avg = await filtersQuery(
          userId,
          dashboardId,
          clientName,
          updatedFilters,
          directionField,
          dataKey,
          segmentation
        );

        if (!avg || !avg.length) continue;

        avg = getSummary(avg);

        if (w === WDAY_TYPE.weekday) {
          // Weight the weekday average for 5 days
          const updatedAvg = { ...avg };
          Object.entries(updatedAvg).forEach(([key, value]) => {
            if (key !== 'wday_type') {
              updatedAvg[key] = updatedAvg[key].map(item => ({
                ...item,
                count: item.count * 5,
              }));
            }
          });

          avgs.push(updatedAvg);
        } else {
          avgs.push(avg);
        }
      }

      break;
    }
    case 'none':
    default: {
      let avg = await filtersQuery(
        userId,
        dashboardId,
        clientName,
        clonedFilters,
        directionField,
        dataKey,
        segmentation
      );
      if (avg && avg.length) {
        avg = getSummary(avg);
        avgs.push(avg);
      }
      break;
    }
  }

  const results = Object.keys(avgs[0] || []).reduce((acc, key) => {
    const convenienceObj = avgs.reduce((accum, avg) => {
      for (const item of avg[key]) {
        const { value, segmentation = 'none', count } = item;
        if (!accum[value]) {
          accum[value] = {};
        }
        if (!accum[value][segmentation]) {
          accum[value][segmentation] = [];
        }
        accum[value][segmentation].push(count);
      }

      return accum;
    }, {});

    const nextObjs = Object.entries(convenienceObj)
      .map(([val, obj]) => {
        return Object.entries(obj).map(([segment, counts]) => ({
          value: val,
          segmentation: segment,
          count:
            key === 'wday_type'
              ? d3.mean(counts)
              : calculateAverageByDaysForFilterData(wday_type, counts),
        }));
      })
      .flat()
      .map(val => {
        const { segmentation } = val;
        if (segmentation === 'none') {
          delete val.segmentation;
        }
        return val;
      });

    acc[key] = nextObjs;
    return acc;
  }, {});

  return results;
};

export const loadCrossTabulationData = async (
  dataKey,
  table,
  clientName,
  userId,
  dashboardName,
  geoIds,
  dimensions,
  filters
) => {
  const results = await downloadDataQuery(
    userId,
    dashboardName,
    clientName,
    dataKey,
    dimensions,
    filters
  );
  return results;
};

export const getDashboard = async dashboardId => {
  const url = `${DASHBOARD_API_URL}/dashboarddetails/${dashboardId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
    },
  });

  return (await response.json()).data;
};

export const getStateCountiesFromDashboard = async (
  dashboardId,
  accessToken
) => {
  const url = `${MAP_LAYERS_API_URL}/${dashboardId}/states`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
  });

  return (await response.json()).data;
};

export const getFilters = async (viewId, accessToken, clientId) => {
  const url = `${VIEWS_API_URL}/${viewId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
      'x-lcs-clientid': clientId,
    },
  });

  return (await response.json()).data;
};

export const updateFilters = async (viewId, data, accessToken) => {
  const url = `${VIEWS_API_URL}/${viewId}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  });

  // TODO This might not be the right thing to return
  return (await response.json()).data;
};

export const createNewView = async (data, dashboardDetails, accessToken) => {
  const url = `${VIEWS_API_URL}`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
    body: JSON.stringify({
      filterValues: data.filterValues,
      viewsOptions: data.viewsOptions,
      name: dashboardDetails.name,
      description: dashboardDetails.description,
      dashboardId: dashboardDetails.dashboardId,
    }),
  });

  // TODO This might not be the right thing to return
  return (await response.json()).data;
};

export const loadTripEndpoints = async (
  table,
  userId,
  dashboardId,
  clientName,
  direction,
  geoid,
  filters,
  limit = 15
) => {
  let geoidDirectionField = '';
  let outputDirectionField = '';
  if (direction) {
    geoidDirectionField =
      direction === 'destination' ? 'destination_geo' : 'origin_geo';
    outputDirectionField =
      direction === 'origin' ? 'destination_geo' : 'origin_geo';
  }

  const clonedFilters = JSON.parse(JSON.stringify(filters));
  if (geoid) {
    clonedFilters[geoidDirectionField] = [geoid];
  }

  const result = await odFlowsQuery(
    userId,
    dashboardId,
    clientName,
    clonedFilters,
    outputDirectionField,
    limit
  );

  return result;
};

// ------------------------------------------------------------------------------------------------------

// TODO we need changes to the dashboard api to write a new functional query here
export const loadTotalTrips = async (
  table,
  userId,
  dashboardId,
  clientName,
  dashboardName,
  filters,
  originGeoIds,
  destinationGeoIds,
  dataKey
) => {
  let avgs = [];
  const { period, wday_type } = filters;
  let clonedFilters = JSON.parse(JSON.stringify(filters));

  if (originGeoIds && originGeoIds.length) {
    clonedFilters['origin_geo'] = originGeoIds;
  }

  if (destinationGeoIds && destinationGeoIds.length) {
    clonedFilters['destination_geo'] = destinationGeoIds;
  }

  let result = [];

  let weightedAvg = 'none';
  const weightPeriod = false;
  const weightWeekday = wday_type && wday_type.length > 1;
  if (weightPeriod && weightWeekday) {
    weightedAvg = 'period-wday_type';
    delete clonedFilters.period;
    delete clonedFilters.wday_type;
  } else if (weightPeriod) {
    weightedAvg = 'period';
    delete clonedFilters.period;
  } else if (weightWeekday) {
    weightedAvg = 'wday_type';
    delete clonedFilters.wday_type;
  }

  switch (weightedAvg) {
    case 'period-wday_type': {
      const combos = period.reduce((acc, p) => {
        for (const w of wday_type) {
          acc.push({ period: p, wday_type: w });
        }
        return acc;
      }, []);

      for (const combo of combos) {
        const { period: p, wday_type: w } = combo;
        const nextFilters = { ...clonedFilters, period: [p], wday_type: [w] };
        let subResult = await totalDataQuery(
          userId,
          dashboardId,
          clientName,
          nextFilters
        );
        if (subResult && subResult.length) {
          avgs.push(subResult[0]);
        }
      }
      result = [
        {
          daily_trips: d3.mean(avgs.map(avg => avg.daily_trips)),
          daily_pmt: d3.mean(avgs.map(avg => avg.daily_pmt)),
        },
      ];
      break;
    }
    case 'period': {
      for (const p of period) {
        const nextFilters = { ...clonedFilters, period: [p] };

        let subResult = await totalDataQuery(
          userId,
          dashboardId,
          clientName,
          nextFilters
        );
        if (subResult && subResult.length) {
          avgs.push(subResult[0]);
        }
      }
      result = [
        {
          daily_trips: d3.mean(avgs.map(avg => avg.daily_trips)),
          daily_pmt: d3.mean(avgs.map(avg => avg.daily_pmt)),
        },
      ];
      break;
    }
    case 'wday_type': {
      for (const w of wday_type) {
        const nextFilters = { ...clonedFilters, wday_type: [w] };
        let subResult = await totalDataQuery(
          userId,
          dashboardId,
          clientName,
          nextFilters
        );

        if (w === WDAY_TYPE.weekday) {
          subResult[0] = {
            daily_trips: subResult[0].daily_trips * 5,
            daily_pmt: subResult[0].daily_pmt * 5,
          };
        }

        if (subResult && subResult.length) {
          avgs.push(subResult[0]);
        }
      }
      result = [
        {
          daily_trips: calculateAverageByDaysForTotalData(
            wday_type,
            avgs,
            'daily_trips'
          ),
          daily_pmt: calculateAverageByDaysForTotalData(
            wday_type,
            avgs,
            'daily_pmt'
          ),
        },
      ];
      break;
    }
    case 'none':
    default: {
      result = await totalDataQuery(
        userId,
        dashboardId,
        clientName,
        clonedFilters
      );
      break;
    }
  }

  return result[0];
};
