import * as d3 from 'd3';
import _ from 'lodash';
import {
  DATA_API_URL,
  DASHBOARD_API_URL,
  VIEWS_API_URL,
  API_KEY,
  WDAY_TYPE,
  MAP_LAYERS_API_URL,
  ZONE_SYSTEM_TYPES,
  TRUCK_GEOGRAPHIC_DETAILS_API_URL,
} from '../constants';
import {
  getAreaForCensusTractsApi,
  getAreaForCountiesApi,
  getAreaApi as loadBlockgroupsFromApi,
} from './blockgroups';

export const getStateCountyDataForTruck = async (dashboard, accessToken) => {
  const url = TRUCK_GEOGRAPHIC_DETAILS_API_URL;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
    body: JSON.stringify({
      dashboardName: dashboard.name,
    }),
  });

  return (await response.json()).data;
};

export const totalDataQuery = async (dashboard, updatedFilters) => {
  const response = await fetch(`${DATA_API_URL}/truck/totaldata`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      dashboardName: dashboard?.name,
      filterValues: updatedFilters,
    }),
  });

  return (await response.json()).data;
};

export const queryApi = async query => {
  const response = await fetch(DATA_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      queryString: query,
    }),
  });

  return (await response.json()).data;
};

export const dashboardQuery = async (dashboard, updatedFilters, direction) => {
  const response = await fetch(`${DATA_API_URL}/truck/mapdata`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      filterValues: updatedFilters,
      direction,
      dashboardName: dashboard?.name,
    }),
  });

  return (await response.json()).data;
};

export const filtersQuery = async (
  dashboard,
  updatedFilters,
  // TODO remove direction
  direction,
  dataKey,
  segmentation,
  dimensions
) => {
  const response = await fetch(`${DATA_API_URL}/truck/filtersquery`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      dashboardName: dashboard?.name,
      dataKey,
      dimensions,
      filterValues: updatedFilters,
      segmentation,
    }),
  });

  const data = (await response.json()).data;

  return data;
};

export const downloadDataQuery = async (
  dashboard,
  dataKey,
  dimensions,
  filters
) => {
  const response = await fetch(`${DATA_API_URL}/truck/downloaddata`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      dashboardName: dashboard?.name,
      dataKey,
      dimensions,
      filterValues: filters,
    }),
  });

  return (await response.json()).data;
};

export const getScaleValues = async (
  table,
  userId,
  dashboardName,
  direction
) => {
  const directionField =
    direction === 'destination' ? 'destination_geo' : 'origin_geo';

  const query = `SELECT
  min(daily_trips),
  percentile(daily_trips, 0.2),
  percentile(daily_trips, 0.4),
  percentile(daily_trips, 0.6),
  percentile(daily_trips, 0.8),
  min(daily_pmt),
  percentile(daily_pmt, 0.2),
  percentile(daily_pmt, 0.4),
  percentile(daily_pmt, 0.6),
  percentile(daily_pmt, 0.8)
  FROM (
    SELECT sum(daily_trips) as daily_trips, sum(daily_pmt) as daily_pmt
    FROM ${table} WHERE user_id='${userId}' AND dashboard_name='${dashboardName}'
    GROUP BY ${directionField}
  )`;
  const result = await queryApi(query);
  let sorted = Object.entries(result[0]).reduce(
    (acc, [k, v]) => {
      if (k.includes('daily_trips')) {
        acc['daily_trips'].push(v);
      }
      if (k.includes('daily_pmt')) {
        acc['daily_pmt'].push(v);
      }
      return acc;
    },
    { daily_pmt: [], daily_trips: [] }
  );
  sorted = Object.fromEntries(
    Object.entries(sorted).map(([k, v]) => [k, v.sort(d3.ascending)])
  );

  return sorted;
};

const getWhere = filters => {
  let where;
  if (filters && Object.keys(filters).length) {
    where = `AND ${Object.entries(filters)
      .filter(([k, v]) => v !== 'all')
      .map(([k, v]) => {
        if (Array.isArray(v)) {
          return `${k} IN (${v.map(val => `'${val}'`).join(',')})`;
        }
        return `${k} = '${v}'`;
      })
      .join(' AND ')}`;
  }
  return where;
};

export const loadDataForTruck = async (dashboard, direction, filters) => {
  const directionField =
    direction === 'destination' ? 'destination_tract' : 'origin_tract';

  const clonedFilters = JSON.parse(JSON.stringify(filters));

  let result;

  result = await dashboardQuery(dashboard, clonedFilters, directionField);

  if (!result) return;

  let totalTrips = Object.values(result).reduce((acc, v) => {
    if (!acc[v.vehicle_class]) {
      acc[v.vehicle_class] = 0;
    }
    acc[v.vehicle_class] = acc[v.vehicle_class] + v.trips;
    return acc;
  }, {});

  totalTrips.total = Object.values(totalTrips).reduce((acc, v) => acc + v, 0);

  let resultHash = result.reduce((acc, datum) => {
    let { geoid, trips, vmt, vehicle_class } = datum;

    geoid = `${geoid}`;
    if (
      (geoid.length === 10 &&
        clonedFilters.zone_system.includes(ZONE_SYSTEM_TYPES.censusTract)) ||
      (geoid.length === 11 &&
        clonedFilters.zone_system.includes(ZONE_SYSTEM_TYPES.blockGroup)) ||
      (geoid.length === 4 &&
        clonedFilters.zone_system.includes(ZONE_SYSTEM_TYPES.county)) ||
      (geoid.length === 11 &&
        !Object.values(ZONE_SYSTEM_TYPES).includes(
          clonedFilters.zone_system[0]
        ))
    ) {
      geoid = `0${geoid}`;
    }

    const isLightDutyTrucks = vehicle_class === 1;
    const isHeavyDutyTrucks = vehicle_class === 3;
    const isMediumDutyTrucks = vehicle_class === 2;

    if (!acc[geoid]) {
      acc[geoid] = {
        trips,
        vmt,
        light_truck_trips: isLightDutyTrucks ? trips : 0,
        medium_truck_trips: isMediumDutyTrucks ? trips : 0,
        heavy_truck_trips: isHeavyDutyTrucks ? trips : 0,
      };
      return acc;
    }
    acc[geoid].trips = acc[geoid].trips + trips;
    acc[geoid].vmt = acc[geoid].vmt + vmt;
    if (isLightDutyTrucks) {
      acc[geoid].light_truck_trips = acc[geoid].light_truck_trips + trips;
    }
    if (isMediumDutyTrucks) {
      acc[geoid].medium_truck_trips = acc[geoid].medium_truck_trips + trips;
    }

    if (isHeavyDutyTrucks) {
      acc[geoid].heavy_truck_trips = acc[geoid].heavy_truck_trips + trips;
    }
    return acc;
  }, {});

  const geoIds = Object.keys(resultHash);
  let zoneSystemAreas = [];
  if (clonedFilters.zone_system.includes(ZONE_SYSTEM_TYPES.censusTract)) {
    zoneSystemAreas = await getAreaForCensusTractsApi(geoIds);
  } else if (clonedFilters.zone_system.includes(ZONE_SYSTEM_TYPES.county)) {
    zoneSystemAreas = await getAreaForCountiesApi(geoIds);
  } else {
    zoneSystemAreas = await loadBlockgroupsFromApi(geoIds);
  }

  resultHash = Object.entries(resultHash).reduce((acc, [geoid, data]) => {
    // TODO this is broken for CA. We should decide if we want to hardcode again or
    // just wait for a better solution from the API
    const zoneSystemAreaDetails = _.find(zoneSystemAreas, { geoId: geoid });
    const area = zoneSystemAreaDetails?.area ?? 0;

    acc[geoid] = {
      daily_trips: data.trips,
      vmt: data.vmt,
      share_of_total_trips: (data.trips / totalTrips.total) * 100,
      share_of_light_truck_trips:
        (data.light_truck_trips / data.trips) * 100 || 0,
      share_of_medium_truck_trips:
        (data.medium_truck_trips / data.trips) * 100 || 0,
      share_of_heavy_truck_trips:
        (data.heavy_truck_trips / data.trips) * 100 || 0,
      trip_density: area ? Math.ceil(data.trips / (area * 3.86102e-7)) : 0,
    };
    return acc;
  }, {});

  return resultHash;
};

export const loadSummaryDataForTruck = async (
  dashboard,
  direction,
  filters,
  dataKey,
  options
) => {
  const { segmentation } = options;
  const directionField =
    direction === 'destination' ? 'destination_truck' : 'origin_truck';

  if (dataKey === 'daily_trips') {
    dataKey = 'trips';
  } else {
    dataKey = 'vmt';
  }

  const getSummary = results => {
    const groupEntities = {};
    results.map(item => {
      Object.keys(item).forEach(key => {
        if (item[key] === null) {
          delete item[key];
        }
      });
    });
    results.forEach(item => {
      if (segmentation && Object.keys(item).length > 3) {
        delete item[segmentation];
      }
      for (const key in item) {
        if (
          item.hasOwnProperty(key) &&
          key !== 'count' &&
          key !== 'segmentation'
        ) {
          if (!groupEntities[key]) {
            groupEntities[key] = [];
          }

          const newItem = { value: item[key] };
          if (item.count !== undefined) {
            newItem.count = item.count;
          }

          if (item.segmentation !== undefined) {
            newItem.segmentation = item.segmentation;
          }

          groupEntities[key].push(newItem);
        }
      }
    });

    return groupEntities;
  };

  const { wday_type, period } = filters;
  const clonedFilters = JSON.parse(JSON.stringify(filters));

  let avgs = [];

  let avg = await filtersQuery(
    dashboard,
    clonedFilters,
    directionField,
    dataKey,
    segmentation
  );
  if (avg && avg.length) {
    avg = getSummary(avg);
    avgs.push(avg);
  }

  const results = Object.keys(avgs[0] || []).reduce((acc, key) => {
    const convenienceObj = avgs.reduce((accum, avg) => {
      for (const item of avg[key]) {
        const { value, segmentation = 'none', count } = item;
        if (!accum[value]) {
          accum[value] = {};
        }
        if (!accum[value][segmentation]) {
          accum[value][segmentation] = [];
        }
        accum[value][segmentation].push(count);
      }

      return accum;
    }, {});

    const nextObjs = Object.entries(convenienceObj)
      .map(([val, obj]) => {
        return Object.entries(obj).map(([segment, counts]) => ({
          value: val,
          segmentation: segment,
          count: d3.mean(counts),
        }));
      })
      .flat()
      .map(val => {
        const { segmentation } = val;
        if (segmentation === 'none') {
          delete val.segmentation;
        }
        return val;
      });

    acc[key] = nextObjs;
    return acc;
  }, {});

  return results;
};

export const loadCrossTabulationDataForTruck = async (
  dashboard,
  dataKey,
  geoIds,
  dimensions,
  filters
) => {
  const results = await downloadDataQuery(
    dashboard,
    dataKey,
    dimensions,
    filters
  );
  return results;
};

export const getFilters = async (viewId, accessToken) => {
  const url = `${VIEWS_API_URL}/${viewId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
  });

  return (await response.json()).data;
};

export const updateFilters = async (viewId, data, accessToken) => {
  const url = `${VIEWS_API_URL}/${viewId}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  });

  // TODO This might not be the right thing to return
  return (await response.json()).data;
};

export const createNewView = async (data, dashboardDetails, accessToken) => {
  const url = `${VIEWS_API_URL}`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
    body: JSON.stringify({
      filterValues: data.filterValues,
      viewsOptions: data.viewsOptions,
      name: dashboardDetails.name,
      description: dashboardDetails.description,
      dashboardId: dashboardDetails.dashboardId,
    }),
  });

  // TODO This might not be the right thing to return
  return (await response.json()).data;
};

export const loadTripEndpoints = async (
  table,
  userId,
  dashboardName,
  direction,
  geoid,
  filters,
  limit = 15
) => {
  const geoidDirectionField =
    direction === 'destination' ? 'destination_geo' : 'origin_geo';
  const outputDirectionField =
    direction === 'origin' ? 'destination_geo' : 'origin_geo';

  // TODO deal with repetition here
  const { period } = filters;
  const clonedFilters = JSON.parse(JSON.stringify(filters));

  delete clonedFilters.zoneSystem;
  clonedFilters.zone_system = ['Censustract'];

  if (period && period.length > 1) {
    delete clonedFilters.period;
  }
  clonedFilters[geoidDirectionField] = geoid;

  let where = getWhere(clonedFilters);

  let group = `GROUP BY ${outputDirectionField}`;

  let query = `SELECT ${outputDirectionField} as geoid, SUM(daily_trips) AS daily_trips, SUM(daily_pmt) AS daily_pmt FROM ${table} WHERE user_id='${userId}' AND dashboard_name='${dashboardName}'`;

  let order = 'ORDER BY daily_trips DESC';

  let result;

  if (period && period.length > 1) {
    let avgs = [];
    for (const p of period) {
      const updatedFilters = { ...clonedFilters, period: [p] };
      where = getWhere(updatedFilters);
      let subQuery = query;
      if (where) {
        subQuery = `${subQuery} ${where}`;
      }

      if (group) {
        subQuery = `${subQuery} ${group}`;
      }

      const avg = await queryApi(subQuery);
      avgs.push(avg);
    }

    result = avgs[0].map(v => {
      const found = avgs[1].find(
        val => val.mode === v.mode && val.geoid === v.geoid
      );
      if (!found) return false;
      return {
        ...v,
        daily_trips: d3.mean([found.daily_trips, v.daily_trips]),
        daily_pmt: d3.mean([found.daily_pmt, v.daily_pmt]),
      };
    });
  } else {
    if (where) {
      query = `${query} ${where}`;
    }

    if (group) {
      query = `${query} ${group}`;
    }

    if (order) {
      query = `${query} ${order}`;
    }

    if (limit) {
      query = `${query} LIMIT ${limit}`;
    }

    result = await queryApi(query);
  }

  return result;
};

// ------------------------------------------------------------------------------------------------------

// TODO we need changes to the dashboard api to write a new functional query here
export const loadTotalTripsForTruck = async (
  dashboard,
  filters,
  originGeoIds,
  destinationGeoIds
) => {
  let clonedFilters = JSON.parse(JSON.stringify(filters));

  if (originGeoIds && originGeoIds.length) {
    clonedFilters['origin_tract'] = originGeoIds;
  }

  if (destinationGeoIds && destinationGeoIds.length) {
    clonedFilters['destination_tract'] = destinationGeoIds;
  }

  let result = await totalDataQuery(dashboard, clonedFilters);
  const tempData = result[0];

  result[0] = {
    daily_trips: tempData.trips,
    daily_pmt: tempData.vmt,
  };

  return result[0];
};
