import React from 'react';
import classnames from 'classnames';
import './TabSet.scss';

function TabSet({ options, onToggle, selected, oppositeEl = null }) {
  return (
    <div className="TabSet">
      {options.map((option, i) => (
        <div key={i} className="TabSet-container">
          <div
            className={classnames('TabSet-button-container', {
              disabled: option.disabled,
            })}
            onClick={() => onToggle(option.value)}
          >
            <div
              className={classnames('TabSet-label', {
                selected: selected && selected.includes(option.value),
                disabled: option.disabled,
              })}
            >
              {option.label}
            </div>
            <div
              className={classnames('TabSet-underline', {
                selected: selected && selected.includes(option.value),
                disabled: option.disabled,
              })}
            />
          </div>
          {i !== options.length - 1 ? (
            <div className="TabSet-filler-underline" />
          ) : null}
        </div>
      ))}

      <div className="TabSet-filler-underline-grow">{oppositeEl}</div>
    </div>
  );
}

export default TabSet;
